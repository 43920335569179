import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../src/assets/style/ProfileUpdation.css'
import Main from './main/Main.jsx';
import { Route, Router, Routes, useLocation } from 'react-router-dom';
import BuyNow from './components/packages/BuyNow.jsx';
import Nfts from './components/nfts/Nfts.jsx';
import ProfileUpdation from './components/forms/ProfileUpdation.jsx';
import NftDetails from './components/nfts/NftDetails.jsx';
import AboutUs from './components/landing_page/about_us/AboutUs.jsx';
import ExploreOurFeatures from './components/our_features/ExploreOurFeatures.jsx';
import OurPackages from './components/landing_page/our_packages/OurPackages.jsx';
import { useEffect, useMemo, useState } from 'react';
import Dashboard from './components/dashboard/Dashboard.jsx';
import TotalNfts from './components/nfts/TotalNfts.jsx';
import OurClub from './components/landing_page/our_packages/OurClub.jsx';
import Home from './components/dashboard/Home.jsx';
import MyReferral from './components/dashboard/MyReferral.jsx';
import MyClub from './components/dashboard/MyClub.jsx';
import Header from './components/layout/header/Header.jsx';
import DashBoardNfts from './components/nfts/DashboardNfts.jsx';
import PackageNft from './components/dashboard/PackageNft.jsx';
import NftComingSoon from '../src/components/nfts/NftComingSoon.jsx'

import PrivacyPolicy from './components/Footer_Pages/PrivacyPolicy.jsx';
import CookiePolicy from './components/Footer_Pages/CookiePolicy.jsx';
import Contact from './components/Footer_Pages/Contact.jsx';
import toast, { Toaster } from 'react-hot-toast';
import ToastPopup from './components/Toast/ToastPopup.jsx';
import Leaderboard from './components/landing_page/leaderboard/Leaderboard.jsx';
import Wallet from './components/dashboard/Wallet.jsx';

function App() {

  const [collapsed, setCollapsed] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (width < 600) {
      setCollapsed(true)
    } else {
      setCollapsed(false)
    }
  }, [window.innerWidth])

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  // const body = document.getElementsByTagName('body')
  // const devendra = body.pageYOffset || body.scrollTop
  // if(devendra > 0){
  //   console.log('devendra', 'up');
  // }else{
  //   console.log('devendra', 'down');
  // }

  

  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/:referal_id" element={<Main />} />
        <Route path='buyNow/:id' element={<BuyNow />} />
        <Route path='nfts/:id' element={<Nfts collapsed={collapsed} setCollapsed={setCollapsed} />} />
        <Route path='profileUpdation' element={<ProfileUpdation collapsed={collapsed} setCollapsed={setCollapsed} />} />
        <Route path='nftDetails' element={<NftDetails />} />
        <Route path='aboutUs' element={<AboutUs />} />
        <Route path='leaderboard' element={<Leaderboard />} />
        <Route path='ourPackages' element={<div className='container'><OurPackages /></div>} />
        <Route path='exploreOurFeatures' element={<ExploreOurFeatures />} />
        
        <Route path='dashboard' element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} />} />

        <Route path='allplayersnftlist' element={
          <div>
            <Header />
            <TotalNfts />
          </div>
        } />
        <Route path='ourClub' element={<OurClub />} />
        <Route path="/home" element={<Home />} />
        <Route path="/myReferral" element={<MyReferral collapsed={collapsed} setCollapsed={setCollapsed} />} />
        <Route path='wallet' element={<Wallet collapsed={collapsed} setCollapsed={setCollapsed} />} />
        <Route path="/allplayersnftlist" element={<TotalNfts collapsed={collapsed} setCollapsed={setCollapsed} />} />
        {/* <Route path="/totalNfts" element={<NftComingSoon />} /> */}
        <Route path="/dashboardNft" element={<DashBoardNfts collapsed={collapsed} setCollapsed={setCollapsed} />} />
        <Route path="/myClub" element={<MyClub collapsed={collapsed} setCollapsed={setCollapsed} />} />
        <Route path="/packagenft" element={<PackageNft collapsed={collapsed} setCollapsed={setCollapsed} />} />

        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/cookiePolicy' element={<CookiePolicy />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
