import DataTable from "react-data-table-component";
import '../../src/assets/style/DataTable.css'
import React from "react";
import toast from "react-hot-toast";
import { FaRegCopy } from "react-icons/fa";

export default function ListTable({ DataList, type }) {
  const tableCustomStyles = {
    headCells: {
      style: {
        color: "rgba(239, 239, 239, 1)",
        backgroundColor: "rgba(34, 38, 48, 1)",
        fontSize: "18px",
        justifyContent: 'center',
      },
    }
    ,
    headRow: {
      style: {
        color: "rgba(239, 239, 239, 1)",
        backgroundColor: "rgba(34, 38, 48, 1)",
        justifyContent: 'center',
        fontSize: "18px",
      },
    },
    rows: {
      style: {
        color: "rgba(239, 239, 239, 1)",
        backgroundColor: "rgba(23, 25, 28, 1)",
        justifyContent: 'center',
        fontSize: "16px",
      },
    },
    pagination: {
      color: "rgba(239, 239, 239, 1)",
      justifyContent: 'center',
      background: "rgba(23, 25, 28, 1)",
    },
  };

  const columns = [
    {
      name: 'Date',
      selector: row => row.Date,
    },
    {
      name: 'Transactions ID',
      selector: row => row.Transactions,
    },
    {
      name: 'Amount',
      selector: row => row.Amount,
    },
    {
      name: 'Status',
      selector: row => row.Status,
    },
  ];


  const referralColumns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      width: "100px",
      center: true
    },

    {
      name: "Wallet Address",
      selector: (row) => row.earned_from,
      format: (row) => {
        return (
          <>
            {row?.earned_from?.slice(0, 5) + "..." + row?.earned_from?.slice(-5)} &nbsp;{" "}
            <span
              title="Click to Copy"
              className="mr-copylink fa farow && row.wallet_address && -copy"
              id="token-buy-button"
              onClick={() => {
                navigator.clipboard.writeText(row?.earned_from).then(() => {
                  toast.success("Wallet Address Copied");
                });
              }}
              style={{ cursor: "pointer", color: "rgb(157 81 255)" }}
            ><FaRegCopy/></span>

          </>
        );
      },
      center: true
    },
    {
      name: "Amount",
      selector: (row) => `${parseFloat(row.amount).toFixed(2)} MNT`,
      // sortable: true,
      // format: (row) => {
      //   return (
      //     <>
      //       {row.email} &nbsp;{" "}
      //       {row.email=="N/a"?"": <span
      //         class="fa fa-copy "
      //         title="Click to Copy"
      //         className="mr-copylink"
      //         id="token-buy-button"
      //         onClick={() => {
      //           navigator.clipboard.writeText(row.email).then(() => {
      //             toast.success("Email Copied");
      //           });
      //         }}
      //         style={{ cursor: "pointer", color: "rgb(157 81 255)" }}
      //       >Copy</span>}

      //     </>
      //   );
      // },
      center: true
    },
    {
      name: "Date",
      selector: (row) => row.timestamp,
      center: true
    },
  ];

  const referralTransferColumns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      width: "100px",
      center: true,
      
    },

    {
      name: "Wallet Address",
      selector: (row) => row.wallet_address,
      format: (row) => {
        return (
          <>
            {row?.wallet_address?.slice(0, 5) + "..." + row?.wallet_address?.slice(-5)} &nbsp;{" "}
            <span
              title="Click to Copy"
              className="mr-copylink fa farow && row.wallet_address && -copy"
              id="token-buy-button"
              onClick={() => {
                navigator.clipboard.writeText(row?.wallet_address).then(() => {
                  toast.success("Wallet Address Copied");
                });
              }}
              style={{ cursor: "pointer", color: "rgb(157 81 255)" }}
            ><FaRegCopy/></span>

          </>
        );
      },
      center: true
    },
    {
      name: "Amount",
      selector: (row) => `${parseFloat(row.amount).toFixed(2)} MNT`,
      center: true

    },
    {
      name: "Type",
      selector: (row) => row.status,
      center: true
    },
    {
      name: "Date",
      selector: (row) => row.timestamp,
      center: true
    }
    
  ];

  return type == 1 ? (
    DataList
      ?
      <DataTable
        //title="Referral History"
        columns={referralColumns}
        data={DataList}
        customStyles={tableCustomStyles}
        pagination
      />
      :
      <div className="h-100 d-flex justify-content-center aligh-items-center mt-5 pb-5">
        No Records
      </div>
  ) : (
    DataList
      ?
      <DataTable
        //title="Transactions History"
        columns={referralTransferColumns}
        data={DataList}
        pagination
        customStyles={tableCustomStyles}
      />
      :
      <div className="h-100 d-flex justify-content-center aligh-items-center mt-5 pb-5">
        No Records
      </div>
  );
}
