import React, { useEffect, useRef, useState } from "react";
import { IoSettings } from "react-icons/io5";
import { TbColorPicker } from "react-icons/tb";
import { TbCricket } from "react-icons/tb";
import { IoBowlingBallOutline } from "react-icons/io5";
import { MdSportsCricket } from "react-icons/md";
import { CiFlag1 } from "react-icons/ci";
import { GiVibratingShield } from "react-icons/gi";
import Header from "../layout/header/Header";
import { IoSearchOutline } from "react-icons/io5";
import { AiOutlineBars } from "react-icons/ai";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { GiCricketBat } from "react-icons/gi";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {
  displayfilterparameterAction,
  displayNftsAction,
  displayNftsFilterAction,
  displayNftsFilterCountryAction,
  displayNftsFilterSkillAction,
  getuserbonusandpromonft,
} from "../../Action/user.action";
import CustomLoader from "../dashboard/CustomLoader";

export default function BonusNfts() {
  const [displayNfts, setdisplayNfts] = useState([]);
  const [totaldisplayNfts, settotaldisplayNfts] = useState(0);
  const [FiltertotaldisplayNfts, setFiltertotaldisplayNfts] = useState([]);
  const [limit, setlimit] = useState(30);
  const [emptyloader, setemptyloader] = useState(true);
  const [displaycountries, setdisplaycountries] = useState([]);
  const [displayskills, setdisplayskills] = useState([]);

  const searchref = useRef("");
  const [selectedskillfilter, setselectedskillfilter] = useState("");
  const [selectedcountryfilter, setselectedcountryfilter] = useState("");
  const [selectedsort, setselectedsort] = useState("");
  const [postfilterdata, setpostfilterdata] = useState([]);
  const [loader, setloader] = useState(false);
  const [filterdata, setfilterdata] = useState([]);

  const loginData = useSelector((state) => state.auth.LoginDetails);

  useEffect(() => {
    displaynftsfunc();
    displaycountriesfunc();

    return () => { };
  }, []);

  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.scrollY + 400 >=
      document.body.scrollHeight
    ) {
      setTimeout(() => {
        loadmore();
      }, 1000);
    }
  };

  const loadmore = async (e) => {
    if (totaldisplayNfts <= limit) {
      return;
    } else {
      setemptyloader(false);
      setTimeout(() => {
        setlimit(limit + 30);
        setemptyloader(true);
      }, 1000);
    }
  };

  const inputhandler = (e) => {
    const { value, name } = e.target;
    let sortedNfts = [];

    if (selectedskillfilter == "" && selectedcountryfilter == "") {
      sortedNfts = FiltertotaldisplayNfts;
    } else {
      sortedNfts = postfilterdata;
    }
    let filtereditems = sortedNfts.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.country.toLowerCase().includes(value.toLowerCase()) ||
        item.skill.toLowerCase().includes(value.toLowerCase())
    );
    setdisplayNfts(filtereditems);
    settotaldisplayNfts(filtereditems.length);
  };

  const sortinginputhandler = (item) => {
    let sortedNfts = [];
    const sortingOptions = {
      1: "Price : Low to High",
      2: "Price : High to Low",
      3: "Age : Low to High",
      4: "Age : High to Low",
      5: "Rating : Low to High",
      6: "Rating : High to Low",
    };

    const selectedSorts = sortingOptions[item];
    setselectedsort(selectedSorts);
    if (selectedskillfilter == "" && selectedcountryfilter == "") {
      sortedNfts = [...FiltertotaldisplayNfts];
    } else {
      sortedNfts = [...postfilterdata];
    }
    if (item == 1) {
      sortedNfts.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    }
    if (item == 2) {
      sortedNfts.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    }
    if (item == 3) {
      sortedNfts.sort((a, b) => parseFloat(a.age) - parseFloat(b.age));
    }
    if (item == 4) {
      sortedNfts.sort((a, b) => parseFloat(b.age) - parseFloat(a.age));
    }

    if (item == 5) {
      sortedNfts.sort(
        (a, b) => parseFloat(a.static_rating) - parseFloat(b.static_rating)
      );
    }
    if (item == 6) {
      sortedNfts.sort(
        (a, b) => parseFloat(b.static_rating) - parseFloat(a.static_rating)
      );
    }
    setdisplayNfts(sortedNfts);
  };

  const filterSkillAndCountryFunc = async (
    filterSkillValue,
    filterCountryValue
  ) => {
    // alert(filterSkillValue)
    // alert(filterCountryValue)
    setloader(true);
    document.getElementById("searchrefss").value = "";
    setselectedsort("");
    if (filterSkillValue == 0) {
      setselectedskillfilter("");
    }
    if (filterCountryValue == 0) {
      setselectedcountryfilter("");
    }
    if (filterSkillValue != "" && filterCountryValue != "") {
      setselectedskillfilter(filterSkillValue);
      setselectedcountryfilter(filterCountryValue);
      let tempfilterdata = FiltertotaldisplayNfts.filter(
        (item) =>
          item.skill.replace(/\s/g, "").toLowerCase() ==
          filterSkillValue.replace(/\s/g, "").toLowerCase() &&
          item.country.replace(/\s/g, "").toLowerCase() ==
          filterCountryValue.replace(/\s/g, "").toLowerCase()
      );
      setdisplayNfts(tempfilterdata);
      settotaldisplayNfts(tempfilterdata.length);
      setpostfilterdata(tempfilterdata);
    } else if (filterSkillValue == "" && filterCountryValue == "") {
      setselectedskillfilter(filterSkillValue);
      setselectedcountryfilter(filterCountryValue);
      setdisplayNfts(FiltertotaldisplayNfts);
      settotaldisplayNfts(FiltertotaldisplayNfts.length);
      setpostfilterdata(FiltertotaldisplayNfts);
    } else if (filterSkillValue != "") {
      setselectedskillfilter(filterSkillValue);
      let tempfilterdata = FiltertotaldisplayNfts.filter(
        (item) =>
          item.skill.replace(/\s/g, "").toLowerCase() ==
          filterSkillValue.replace(/\s/g, "").toLowerCase()
      );
      setdisplayNfts(tempfilterdata);
      settotaldisplayNfts(tempfilterdata.length);
      // console.log(tempfilterdata, "tempfilterdata");

      setpostfilterdata(tempfilterdata);
    } else if (filterCountryValue != "") {
      setselectedcountryfilter(filterCountryValue);
      let tempfilterdata = FiltertotaldisplayNfts.filter(
        (item) =>
          item.country.replace(/\s/g, "").toLowerCase() ==
          filterCountryValue.replace(/\s/g, "").toLowerCase()
      );
      setdisplayNfts(tempfilterdata);
      settotaldisplayNfts(tempfilterdata.length);
      setpostfilterdata(tempfilterdata);
    }
    setTimeout(() => {
      setloader(false);
    }, 500);
  };

  const displaynftsfunc = async () => {
    try {
      setloader(true);

      let res = await getuserbonusandpromonft(loginData.authToken);

      document.getElementById("searchrefss").value = "";
      if (res.data.success) {

        setselectedcountryfilter("");
        setselectedskillfilter("");
        setselectedsort("");
        setdisplayNfts(res?.data?.data);
        settotaldisplayNfts(res.data.data.length);
        setFiltertotaldisplayNfts(res.data.data);
        setpostfilterdata(res.data.data);
        setloader(false);
      } else {
        setloader(false);

        // toast.error(res.msg);
        //setdisplayNfts([]);
      }
    } catch (err) {
      setloader(false);

      console.log(err);
    }
  };

  const displaycountriesfunc = async () => {
    try {
      let res = await displayfilterparameterAction();
      if (res.success) {
        setdisplaycountries(res.data.country);
        setdisplayskills(res.data.skills);
      } else {
        // toast.error(res.msg);
        setdisplaycountries([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <div className="container">
        <div className="total_nfts nfts my-5"> */}
      {/* <div className="tab-pane fade" id="BonusNfts" role="tabpanel" aria-labelledby="BonusNfts-tab"> */}
      {/* ====================Tab second start=================== */}
      <div>
        <div className="t_nftsHeader">
          <div className="row">
            <div className="col-lg-4 col-xl-3 mb-3">
              <div className="fltrnft">
                <input
                  type="text"
                  className="form-control nft-search"
                  placeholder="Search"
                  onChange={(e) => inputhandler(e)}
                  id="searchrefss"
                />
                <span className="icnsrch">
                  <IoSearchOutline />
                </span>
              </div>
            </div>
            <div className="col-lg-12 col-xl-9 mt-2 mt-md-3 mt-lg-0">
              <div className="row justify-content-end">
                <div className="col-6 col-lg-3 text-end mb-md-3">
                  <div className="d-flex justify-content-md-end">
                    <div className="dropdown  w-100">
                      <button
                        className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="d-flex justify-content-end align-items-center">
                          <AiOutlineBars />
                          <span className="ms-1">
                            {selectedskillfilter == ""
                              ? "Select Skill"
                              : selectedskillfilter}
                          </span>
                        </div>
                      </button>
                      <ul
                        className="dropdown-menu bg-dark"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            className="dropdown-item btn_uni m-2"
                            onClick={() =>
                              filterSkillAndCountryFunc(
                                0,
                                selectedcountryfilter
                              )
                            }
                          >
                            <span>
                              <GiCricketBat />
                            </span>
                            <span className="ms-2">All</span>
                          </a>
                        </li>
                        {loader == true ? (
                          <div className="d-flex justify-content-center align-items-center h-100 mt-5 pt-5">
                            <div className="my-5">
                              <CustomLoader />
                            </div>
                          </div>
                        ) : (
                          displayskills.map((item, index) => {
                            return (
                              <li>
                                <a
                                  className="dropdown-item btn_uni m-2"
                                  onClick={() =>
                                    filterSkillAndCountryFunc(
                                      item.name,
                                      selectedcountryfilter
                                    )
                                  }
                                >
                                  <span>
                                  <img src={item.logo} alt={item.logo} />
                                  </span>
                                  <span className="ms-2">{item.name}</span>
                                </a>
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-lg-4 text-end mb-lg-3 mb-md-3">
                  <div className="d-flex justify-content-md-end">
                    <div className="dropdown  w-100">
                      <button
                        className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="d-flex justify-content-end align-items-center">
                          <AiOutlineBars />
                          <span className="ms-1">
                            {selectedcountryfilter == ""
                              ? "Select Country"
                              : selectedcountryfilter}
                          </span>
                        </div>
                      </button>
                      <ul
                        className="dropdown-menu bg-dark"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            className="dropdown-item btn_uni m-2"
                            onClick={() =>
                              filterSkillAndCountryFunc(
                                selectedskillfilter,
                                0
                              )
                            }
                          >
                            <span>
                              {/* <img src="" className="img-fluid fltr_imgs" alt="" /> */}
                            </span>
                            <span className="ms-2">All</span>
                          </a>
                        </li>
                        {displaycountries.map((item, index) => {
                          return (
                            <li>
                              <a
                                className="dropdown-item btn_uni m-2"
                                onClick={() =>
                                  filterSkillAndCountryFunc(
                                    selectedskillfilter,
                                    item.name
                                  )
                                }
                              >
                                <span>
                                  <img src={item?.flag_logo} className="img-fluid fltr_imgs" alt="" />
                                </span>
                                <span className="ms-2">{item.name}</span>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-lg-3 text-end mt-sm-3 mt-md-0 mb-lg-0">
                  <div className="d-flex justify-content-md-end">
                    <div className="dropdown w-100">
                      <button
                        className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark w-100"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="d-flex justify-content-end align-items-center">
                          <AiOutlineBars />
                          <span className="ms-1">
                            {selectedsort == "" ? "Sort By" : selectedsort}
                          </span>
                        </div>
                      </button>
                      <ul
                        className="dropdown-menu bg-dark"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            className="dropdown-item btn_uni m-2"
                            onClick={() => sortinginputhandler(1)}
                          >
                            <span className="ms-2 text-light">
                              Price : Low to High
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item btn_uni m-2"
                            onClick={() => sortinginputhandler(2)}
                          >
                            <span className="ms-2">Price : High to Low</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item btn_uni m-2"
                            onClick={() => sortinginputhandler(3)}
                          >
                            {/* <span><IoBowlingBallOutline /></span> */}
                            <span className="ms-2">Age : Low to High</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item btn_uni m-2"
                            onClick={() => sortinginputhandler(4)}
                          >
                            {/* <span><MdSportsCricket /></span> */}
                            <span className="ms-2">Age : High to Low</span>
                          </a>
                        </li>

                        <li>
                          <a
                            className="dropdown-item btn_uni m-2"
                            onClick={() => sortinginputhandler(5)}
                          >
                            {/* <span><IoBowlingBallOutline /></span> */}
                            <span className="ms-2">Rating : Low to High</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item btn_uni m-2"
                            onClick={() => sortinginputhandler(6)}
                          >
                            {/* <span><MdSportsCricket /></span> */}
                            <span className="ms-2">Rating : High to Low</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-lg-2 text-end text-lg-end mt-xl-0 mt-lg-0 mt-md-0 d-flex align-items-md-start align-items-end">
                  <button
                    className="btn_uni text-center w-100"
                    onClick={() => displaynftsfunc()}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 mt-lg-0">
            <p className="m-0">Total Bonus NFTs {totaldisplayNfts}</p>
          </div>
        </div>

        <div
          className="tab-pane fade show active text-white"
          id="autopick"
          role="tabpanel"
          aria-labelledby="autopick-tab"
        >
          <div className="row">
            {loader == true ? (
              <div className="d-flex justify-content-center align-items-center h-100 mt-5 pt-5">
                <div className="my-5">
                  <CustomLoader />
                </div>
              </div>
            ) : displayNfts.length > 0 ? (
              displayNfts?.map((item, index) => {
                return (
                  <div key={index} className="col-lg-3 col-md-4 mt-3">
                    <div className="position-relative">
                      <div className="text-center">
                        <div className="fs-6 t-price">
                          <small className="position-absolute bonus_tag">
                            {item?.category}
                          </small>
                          <span>${item.price}</span>
                          <div
                                  className=" bonus_tag  mt-2 small"
                                  style={{ width: "fit-content" }}
                                >
                                  NFT IDs {item?.user_nft_id}
                                </div>
                        </div>
                        {/* <div className="fs-6 t-price">${item.price}</div> */}
                        <img
                          src={item.image}
                          alt=""
                          className="cricketcard"
                          onError={(e) => {
                            e.target.src =
                              "https://web3sports.s3.ap-south-1.amazonaws.com/Players/dummy.png";
                          }}
                        />
                        <div className="cardDetail">
                          <div className="cardInnerDetail">
                            <small className="text-primary fw-medium">
                              {/* {item.static_rating.split(".")[0]}/100 */}
                              {Math?.trunc(parseInt(item?.dynamic_rating) + parseInt(item?.static_rating))}/100 
                            </small>
                            <h5 className="mb-0" data-bs-toggle="tooltip" data-bs-placement="top"
                              title={item.name?.length < '15' ? "" : item.name}>
                              {item.name?.length > '15' ? item.name?.slice(0, 14) + ".." : item.name}
                            </h5>
                            <small className="text-primary fw-medium">
                              {item.skill}
                            </small>
                          </div>
                          <div className="d-flex justify-content-between">
                            <small className="text-uppercase">
                              Age {item.age}
                            </small>
                            <small className="text-uppercase">
                              <span className="nft_flag_img">
                                <img src={item?.flag_logo} className="img-fluid" />
                                <span>{item.country}</span>
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="container h-100 mt-5">
                <div className="h-100 mt-5">
                  <div className="h-75">
                    <div className="text-center">
                      <img src="ds/nodatapreview.png" alt="" className="img-fluid" style={{ width: '200px' }} />
                    </div>
                    <h4 className="mt-3 text-center">Sorry, no results found!</h4>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {emptyloader == true ? (
          ""
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100 mt-5 pt-5">
            <div className="my-5">
              <CustomLoader />
            </div>
          </div>
        )}
      </div>
      {/* ===================Tab second start==================== */}
      {/* </div> */}

      {/* </div>
      </div> */}
    </>
  );
}
