import React, { useState, useEffect } from "react";
import { MdDone } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { IoMdArrowDropdown } from "react-icons/io";
import { Form } from "react-router-dom";
import {
  getSelectedPackage,
  getReferalBalance,
  initiateplanpurchasetrxMNT,
  initiateplanpurchasetrxUSDT,
  getplanpurchaseconfirmationstatusAction,
  getbonusnftdetailsAction,
} from "../../Action/user.action";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import config from "../../config/config";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import Footer from "../layout/footer/Footer";
import DataTable from "react-data-table-component";
import "../../assets/style/DataTable.css";
import Header from "../layout/header/Header";
import Web3 from "web3";
import { useSelector } from "react-redux";
import {
  purchasePlanMNT,
  purchasePlanUSDT,
  getChainId,
  getTokenBalanceBlockchain,
  getBNBBalanceBlockchain
} from "../../components/Web3/Connector";
import Web3ModalProvider from "../ConnectWallet/Web3ModalProvider";
import { FallingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import { bscTestnet } from "wagmi/chains";

import {
  Web3Modal,
  Web3Button,
  useWeb3Modal,
  Web3ModalProps,
} from "@web3modal/react";
import {
  configureChains,
  Connector,
  useWalletClient,
  WagmiConfig,
  useConnect,
  useChainId,
  useNetwork,
  useConfig,
  useAccount,
  useSwitchChain
} from "wagmi";
import CustomLoader from "../dashboard/CustomLoader";
import { BonusNftModal } from "./BonusNftModal";
import { TermsconditionModal } from "./TermsconditionModal";
const web3 = new Web3(window.ethereum);

const MNTContractAddress = config.MNTContractAddress;
const USDTContractAddress = config.USDTContractAddress;
const MNTABI = config.MNTABI;
const USDTABI = config.USDTABI;

export default function BuyNow() {
  const _useSwitchChain = useSwitchChain()
  const [listPackage, setListPackage] = useState([]);
  const [listTransactions, setTransactions] = useState([]);

  const AfterConnectAddress = useSelector(
    (state) => state.auth.walletConnnected
  );
  const loginData = useSelector((state) => state.auth.LoginDetails); // (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));
  const [icon, setIcon] = useState("MNT");
  const [show, setShow] = useState(false);
  const [mntBalance, setMNTBalance] = useState("");
  const [itemPrice, setItemPrice] = useState(""); // State to hold the item price
  const [referalBalance, setReferalBalance] = useState("");
  const [usableBalance, setUsableBalance] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [mntPaymentObject, setMntPaymentObject] = useState([]);
  const [usdtPaymentObject, setUsdtPaymentObject] = useState([]);
  const [isProviderLoaded, setisProviderLoaded] = useState(false);
  const [transactionObject, setTransactionObject] = useState({});
  const [transactionObjectUsdt, setTransactionObjectUsdt] = useState({});
  const [transactionLoaderMNT, settransactionLoaderMNT] = useState(false);
  const [transactionLoaderUSDT, settransactionLoaderUSDT] = useState(false);
  const [loadertimer, setloadertimer] = useState(0);
  const [packagePurchasedId, setpackagePurchasedId] = useState(0);
  const _useChainId = useChainId();

  const [smShow, setSmShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [bonusNFTlistModal, setBonusNFTlistModal] = useState(false);
  const [bonusNFTlist, setBonusNFTlist] = useState([]);
  const [termsConditionModal, setTermsConditionModal] = useState(false);
  const [termsConditionChecked, setTermsConditionChecked] = useState(false);

  //const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const _useWeb3ModalProvider = (!window.ethereum) ? [] : window.ethereum; //Web3ModalProvider();

  useEffect(() => {
    if (showLoader) {
      let timer = 130;
      const interval = setInterval(async () => {
        timer -= 1;
        setloadertimer(timer);
        if (timer < 0) {
          clearInterval(interval);
          setShowLoader(false);
        } else if (timer % 20 == 0) {
          try {
            let res = await getplanpurchaseconfirmationstatusAction({
              token: loginData.authToken,
              data: packagePurchasedId,
            });
            if (res.data.data.status == 1) {
              clearInterval(interval);
              toast.success("Your Transaction was successful!");
              setTimeout(() => {
                window.location.href = `${config.baseUrl}nfts/${packagePurchasedId}`

                // navigate(`${config.baseUrl}nfts`, {
                //   state: {
                //     packagePurchasedId: packagePurchasedId,
                //   },
                // });
              }, 500);
            }
          } catch (error) {
            console.log(error);
          }
        } else if (timer == 0) {
          toast.success(
            "Your Transaction was successful, Please wait for sometime..."
          );
          setTimeout(() => {
            navigate(`${config.baseUrl}myClub`);
          }, 1200);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [showLoader]);


  // useEffect(() => {
  //   if (!isProviderLoaded) {

  //     _useWeb3ModalProvider.then((data) => {
  //       if (data) {
  //         setisProviderLoaded(true);
  //       }
  //     });
  //   }
  // }, [_useWeb3ModalProvider]);

  const handleCloseLoader = async () => {
    setShowLoader(false);
  };

  const handleOpenLoader = async () => {
    setShowLoader(true);
  };

  const getplanpurchaseconfirmationstatusfunc = async () => {
    try {
      let res = await getplanpurchaseconfirmationstatusAction({
        token: loginData.authToken,
        data: packagePurchasedId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = async () => {
    setShow(false);
    setIsCheckboxChecked(false);
    settransactionLoaderMNT(false);
    settransactionLoaderUSDT(false);
  };

  const hadleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    detailedPackage();
    getReferalBalanceUser();
    setWalletAddress(AfterConnectAddress);
  }, [AfterConnectAddress]);

  useEffect(() => {
    const interval = setInterval(() => {
      detailedPackage();
    }, 25000);

    return () => clearInterval(interval);
  }, []);

  const params = useParams();

  const detailedPackage = async () => {
    try {
      let res = await getSelectedPackage({ package_id: parseInt(params.id) });
      setListPackage(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUSDTBalanceUser = async () => {
    try {
      const res = await getTokenBalanceBlockchain(walletAddress, USDTContractAddress, _useWeb3ModalProvider || window.ethereum);
      return res;
    } catch (error) {
      console.error("Error fetching MNT balance:", error);
    }
  };

  const fetchMNTBalanceUser = async () => {
    try {
      const res = await getTokenBalanceBlockchain(walletAddress, MNTContractAddress, _useWeb3ModalProvider || window.ethereum);
      return res;
    } catch (error) {
      console.error("Error fetching MNT balance:", error);
    }
  };

  const fetchBNBBalanceUser = async () => {
    try {
      const res = await getBNBBalanceBlockchain(walletAddress, _useWeb3ModalProvider || window.ethereum);
      return res.balance || 0;
    } catch (error) {
      console.error("Error fetching BNB balance:", error);
    }
  };
  const getReferalBalanceUser = async () => {
    try {
      let res = await getReferalBalance(loginData.authToken, {
        plan_id: parseInt(params.id),
      });
      setReferalBalance(res?.data?.data?.refferl_balance);
      setUsableBalance(res?.data?.data?.max_usable_MNT);
    } catch (error) {
      console.log(error);
    }
  };
  const CheckwalletConnect = (item) => {
    if (walletAddress) {
      if (item.bonus_nft > 0 && !termsConditionChecked) {
        setTermsConditionModal(true);
        setTermsConditionChecked(false);
      } else {
        getReferalBalanceUser();
        setShow(true);
      }
    } else {
      toast.error("Please connect wallet first");
    }
  };
  useEffect(() => {
    // Update the MNT amount when mntBalance or item price changes
    if (mntBalance !== "" && itemPrice !== "") {
      setItemPrice(parseFloat(itemPrice)); // Convert item price to float if necessary
    }
  }, [mntBalance, itemPrice]);
  // ========================== data tabele =====================

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const initiateMNTtxfunc = async () => {
    Swal.fire({
      title: `<h1 class="text-dark">Ready to Purchase Club with MNT!</h1>!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await initiateMNTtx();
      }
    });

  };

  const initiateUSDTTrxfunc = async () => {

    Swal.fire({
      title: `<h3 class="text-dark">Ready to Purchase Package with USDT!</h3>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await initiateUSDTTrx();
      }
    });

  };

  const initiateMNTtx = async () => {
    try {
      settransactionLoaderMNT(true);
      let res = await initiateplanpurchasetrxMNT({
        token: loginData.authToken,
        plan_id: parseInt(params.id),
        use_refferal: isCheckboxChecked,
      });
      setpackagePurchasedId(res.data.data.package_purchased_id);

      let useBlanaceRes = await fetchMNTBalanceUser();

      console.log({ useBlanaceRes })
      if (!useBlanaceRes.status) {
        // await _useSwitchChain.switchChain({ chainId: config.chainId })
        settransactionLoaderMNT(false);
        settransactionLoaderUSDT(false);
        toast.error(useBlanaceRes.message)
        return;
      }
      let useBlanace = useBlanaceRes.balance || 0;

      let bnbbalance = await fetchBNBBalanceUser();
      if (bnbbalance > 0) {
        if (useBlanace >= parseInt(res?.data?.data.amount_pending)) {

          let purchaseResponse = await purchasePlanMNT(
            AfterConnectAddress,
            _useWeb3ModalProvider,
            config.MNTContractAddress,
            res?.data?.data
          );
          if (purchaseResponse.status) {
            settransactionLoaderUSDT(false);
            settransactionLoaderMNT(false);
            toast.success(purchaseResponse.message);
            setTransactionObjectUsdt({});
            setUsdtPaymentObject([]);
            handleClose();
            setTimeout(() => {
              handleOpenLoader();
            }, 1000);
          } else {
            settransactionLoaderUSDT(false);
            settransactionLoaderMNT(false);
            if (purchaseResponse.code == 100) {
              toast.error("User rejected the transaction");
            } else {
              toast.error(purchaseResponse.message); // Make sure yy.message is a string
            }
            handleClose();
          }

        } else {
          toast.error("Insufficient MNT");
          settransactionLoaderUSDT(false);
          settransactionLoaderMNT(false);
        }
      } else {
        toast.error("You do not have sufficient BNB to execute transaction!");
        settransactionLoaderMNT(false);
        settransactionLoaderUSDT(false);
      }

    } catch (error) {
      handleClose();
      toast.error(
        // "Denied Transaction. Please try again!"
        !error.message ? error.toString() : error.message
      );
      settransactionLoaderUSDT(false);
      settransactionLoaderMNT(false);
    }
  };


  const initiateUSDTTrx = async () => {
    try {
      settransactionLoaderUSDT(true);
      let res = await initiateplanpurchasetrxUSDT({
        token: loginData.authToken,
        plan_id: parseInt(params.id),
        use_refferal: isCheckboxChecked,
      });
      setpackagePurchasedId(res.data.data.package_purchased_id);

      let useBlanaceRes = await fetchUSDTBalanceUser();
      if (!useBlanaceRes.status) {
        // await _useSwitchChain.switchChain({ chainId: config.chainId })
        settransactionLoaderMNT(false);
        settransactionLoaderUSDT(false);
        toast.error(useBlanaceRes.message)
        return;
      }
      let useBlanace = useBlanaceRes.balance || 0;


      let bnbbalance = await fetchBNBBalanceUser();
      if (bnbbalance > 0) {
        if (useBlanace >= parseInt(res?.data?.data.amount_pending)) {
          setTransactionObjectUsdt(res?.data?.data);
          setUsdtPaymentObject(res?.data?.data);
          let purchaseResponse = await purchasePlanUSDT(
            AfterConnectAddress,
            _useWeb3ModalProvider,
            config.USDTContractAddress,
            res?.data?.data
          );

          if (purchaseResponse.status) {
            settransactionLoaderUSDT(false);
            settransactionLoaderMNT(false);
            toast.success(purchaseResponse.message);
            setTransactionObjectUsdt({});
            setUsdtPaymentObject([]);
            handleClose();
            setTimeout(() => {
              handleOpenLoader();
            }, 1000);
          } else {
            settransactionLoaderUSDT(false);
            settransactionLoaderMNT(false);
            if (purchaseResponse.code == 100) {
              toast.error("User rejected the transaction");
            } else {
              toast.error(purchaseResponse.message); // Make sure yy.message is a string
            }
            handleClose();
          }
        } else {
          toast.error("Insufficient USDT");
          settransactionLoaderUSDT(false);
          settransactionLoaderMNT(false);
        }
      } else {
        toast.error("You do not have sufficient BNB to execute transaction!");
        settransactionLoaderMNT(false);
        settransactionLoaderUSDT(false);
      }

    } catch (error) {
      handleClose();
      toast.error(
        // "Denied Transaction. Please try again!"
        !error.message ? error.toString() : error.message
      );
      settransactionLoaderUSDT(false);
      settransactionLoaderMNT(false);
    }
  };

  const viewBonusNFTs = async () => {
    let res = await getbonusnftdetailsAction({
      package_id: parseInt(params.id),
    });
    if (res.data.success) {
      setBonusNFTlist(res.data.data);
      setBonusNFTlistModal(true);
    } else {
      toast.error(res.data.msg)
    }
  }
  const termsConditionAccept = () => {
    setTermsConditionModal(false);
    setTermsConditionChecked(true)
    getReferalBalanceUser();
    setShow(true);
  }
  console.log(bonusNFTlist)
  return (
    <>
      <Header />
      <div className="container">
        <Toaster />
        <BonusNftModal isModal={bonusNFTlistModal} handleCloseModal={() => setBonusNFTlistModal(false)} nftList={bonusNFTlist} />
        <TermsconditionModal isModal={termsConditionModal} handleCloseModal={() => setTermsConditionModal(false)} termsConditionAccept={() => termsConditionAccept()} />
        <div className="buy_now mt-5 our_packages">
          <div className="row">
            {listPackage.length == 0 && (
              <div className="card_box">
                <div className="col-md-12">
                  <h5 className="fs-3 mt-5 mb-5 text-center">
                    No Package Data Found
                  </h5>
                </div>
              </div>
            )}

            <div className="col-md-5">
              {listPackage.length > 0 &&
                listPackage?.map((item) => (
                  <div className="card_box">
                    <h5 className="fs-3 our_pack_head d-flex justify-content-between  mb-2 ">
                      <span className="lear">{item.name}</span>
                      <span>
                        <img
                          src={item.logo}
                          alt=""
                          className="img-fluid"
                          width={30}
                        />
                      </span>
                    </h5>
                    {/* <div className="text-white my-3">Includes:</div> */}
                    <p className="d-flex">
                      <span className="icon me-1">
                        <MdDone />
                      </span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.short_description,
                        }}
                      ></div>
                    </p>
                    <p className="d-flex">
                      <span className="icon me-1">
                        <MdDone />
                      </span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.full_description,
                        }}
                      ></div>
                    </p>

                    <div className='d-flex justify-content-end mt-2 mb-2'>
                      <div className="bonus_nft_cards d-flex align-items-center w-100">

                        <div className="bonus_nft_flag w-100">
                          <div
                            className="d-flex align-items-center justify-content-between w-100"
                          >
                            <div>Remaining Clubs :</div>
                            <b className={true ? "" : "bg-danger"}>{item.remaining}</b>
                          </div>
                          {item.bonus_nft > 0 && (
                            <span>{item.bonus_nft} BONUS NFTs</span>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {listPackage.length > 0 &&
              listPackage?.map((item) => (
                <div className="col-md-7">
                  <div className="heading_packages my-3">
                    <span>Total NFTs</span>
                    <span>{item.total_nft}</span>
                  </div>

                  <div className="row my-3">
                    <div className="col-auto">
                      <div className="btn_packages">
                        <div>
                          <span className="text-info">Master</span> :{" "}
                          {item.master} NFTs
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="btn_packages">
                        <div>
                          <span className="text-info">Elite</span> :{" "}
                          {item.elite} NFTs
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="btn_packages">
                        <div>
                          <span className="text-info">Prime</span> :{" "}
                          {item.prime} NFTs
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="btn_packages">
                        <div>
                          <span className="text-info">Professional</span> :{" "}
                          {item.professional} NFTs
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="btn_packages">
                        <div>
                          <span className="text-info">Emerging</span> :{" "}
                          {item.emerging} NFTs
                        </div>
                      </div>
                    </div>
                    {/* {
                      item.bonus_nft>0?<div className="col-auto">
                      <div className="btn_packages">
                        <div><span className="text-info">Bonus</span> : {item.bonus_nft} NFTs</div>
                      </div>
                    </div>
                    
                    :""
                    } */}
                  </div>
                  <form onSubmit={hadleSubmit}>
                    <div className="d-flex flex-row flex-wrap justify-content-between mt-4">
                      <div className="">
                        <p className="fs-5">Price</p>
                        <h4 className="text-start">
                          ${item.price.split(".")[0]} ~ {item.priceinmnt} MNT
                        </h4>
                      </div>

                      {/* ==============Modal popup============== */}
                      <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        className="modal"
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <h4 className="text-center my-3">
                          Select the below token to pay
                        </h4>
                        <div className="mt-3">
                          <div className="row">
                            <div className="col-6">
                              <button
                                onClick={() => initiateMNTtxfunc()}
                                className="mnt_usdt btn_uni w-100"
                                disabled={transactionLoaderUSDT || transactionLoaderMNT}
                              >
                                <img
                                  src="/coin.png"
                                  className="img-fluid me-3"
                                  alt=""
                                />{" "}
                                {transactionLoaderMNT ? "Processing..." : "MNT"}
                              </button>
                            </div>

                            <div className="col-6">
                              <button
                                onClick={() => initiateUSDTTrxfunc()}
                                className="mnt_usdt btn_uni w-100"
                                disabled={transactionLoaderUSDT || transactionLoaderMNT}                              >
                                <img
                                  src="/USDT.png"
                                  className="img-fluid me-3"
                                  alt=""
                                />{" "}
                                {transactionLoaderUSDT
                                  ? "Processing..."
                                  : "USDT"}
                              </button>
                            </div>
                          </div>

                          <div className="d-flex mt-4">
                            <div>
                              <input
                                type="checkbox"
                                className="me-2"
                                checked={isCheckboxChecked}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <p className="m-0">
                              Do you want to use Referral Balance ?
                            </p>
                          </div>
                          <div className="mt-3">
                            <p className="m-0">
                              ● Usable Referral Balance : {usableBalance} MNT{" "}
                            </p>
                          </div>
                          <div className="mt-3">
                            <p className="m-0">
                              ● Available Referral Balance :{" "}
                              {referalBalance ? referalBalance : "0"} MNT
                            </p>
                          </div>
                        </div>
                      </Modal>
                      {/* ==============Modal popup============== */}

                      <div className=" ">
                        <Link
                          onClick={(e) => CheckwalletConnect(item)}
                          // onClick={() => setSmShow(true)}
                          className="btn_uni px-3 m-0 w-100"
                        >
                          BUY NOW
                        </Link>
                        {item.bonus_nft > 0 && (
                          <Link
                            onClick={(e) => viewBonusNFTs(e)}
                            className="btn_uni px-3 m-0 mt-2 w-100 "
                          >
                            VIEW BONUS NFTs
                          </Link>
                        )}

                        {/* <p className="fs-6 mt-2 text-center">
                          <div className="d-flex justify-content-center mt-2">
                            <div className="bonus_nft_cards d-flex align-items-center">
                              {item.bonus_nft > 0 && (
                                <div className="bonus_nft_flag">
                                  <span className="badge">{item.bonus_nft} BONUS NFTs</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </p> */}
                      </div>
                    </div>
                  </form>
                </div>
              ))}
            {listPackage.length > 0 && (
              <div className="py-4">
                <div className="py-2">
                  <p>
                    <h5 className="d-inline">Note :</h5> Please note that the
                    package you have purchased is{" "}
                    <b style={{ fontWeight: "500", fontSize: "16px" }}>
                      {" "}
                      valid for one year from the date of launch.{" "}
                    </b>{" "}
                    After the one-year period has elapsed, the package will
                    expire, and you will need to renew or purchase a new package
                    to continue enjoying the services.
                  </p>
                </div>
                <h5>Disclaimer :</h5>
                <p>
                  We cannot be held responsible for any loss of funds due to
                  providing an incorrect wallet address. Please double-check the
                  wallet address before confirming any transactions. It is your
                  responsibility to ensure the accuracy of the wallet address.
                </p>
              </div>
            )}
            <div></div>

            <Modal
              show={showLoader}
              onHide={handleCloseLoader}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body className="p-0">
                <div className="">
                  <p className="text-center">
                    Please wait transaction is in process..
                  </p>
                  <p className="text-center">
                    {" "}
                    Please wait for..... {loadertimer} seconds
                  </p>
                </div>
                <p className="text-center">
                  <div className="d-flex justify-content-center">
                    <CustomLoader />
                  </div>{" "}
                </p>
              </Modal.Body>
            </Modal>

            {/* {listTransactions.length === 0 ? (
                        <div className="card_box">
                            <div className="col-md-12">
                                <h5 className="fs-3  text-center">No transactions found</h5>
                            </div>
                        </div>
                    ) : (
                        <DataTable
                            title="Transactions History"
                            columns={columns}
                            data={listTransactions}
                            pagination
                            customStyles={tableCustomStyles}
                        />
                    )} */}
          </div>
        </div>
      </div>

      {/* ======================== */}
      <Modal
        size="lg"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {/* Small Modal */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text_pop nft_coming">
            <h1 style={{ fontSize: "50px" }} className="text-center">
              Presale will be Live On 28th April
            </h1>
          </div>
        </Modal.Body>
      </Modal>
      {/* ======================== */}
    </>
  );
}
