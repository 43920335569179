import React, { useEffect } from "react";
import './BottomNavigation.css'
import { NavLink, useLocation } from 'react-router-dom';
import config from "../../config/config";
import { FaHome } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { TbPackages } from "react-icons/tb";
import { MdDashboard } from "react-icons/md";
import { RiNftLine } from "react-icons/ri";

export default function BottomNavigation() {

    return (
        <>
            <nav className="bottom-navbar" >
                <NavLink to='/dashboard' exact activeClassName="active" className={({ isActive }) =>
                    isActive ? "activeNew" : "inactive"
                } >
                    <div className='menuItem text-center'>
                        <div>
                            <MdDashboard />
                        </div>
                        <span>Dashboard</span>
                    </div>
                </NavLink>
                <NavLink to="/myClub" className={({ isActive }) =>
                    isActive ? "activeNew" : "inactive"
                }>
                    <div className='menuItem text-center'>
                        <div>
                            <TbPackages />
                        </div>
                        <span>My Clubs</span>
                    </div>
                </NavLink>
                <NavLink to="/dashboardNft" className={({ isActive }) =>
                    isActive ? "activeNew" : "inactive"
                }>
                    <div className='menuItem text-center'>
                        <div>
                            <RiNftLine />
                        </div>
                        <span>My NFTs</span>
                    </div>
                </NavLink>
                <NavLink to="/myReferral" className={({ isActive }) =>
                    isActive ? "activeNew" : "inactive"
                }>
                    <div className='menuItem text-center'>
                        <div>
                            <GiReceiveMoney />
                        </div>
                        <span> Referral</span>
                    </div>
                </NavLink>
                <NavLink to='/' className={({ isActive }) =>
                    isActive ? "activeNew" : "inactive"
                }>
                    <div className='menuItem text-center'>
                        <div>
                            <FaHome />
                        </div>
                        <span>Home</span>
                    </div>
                </NavLink>

            </nav>
        </>
    )
}