import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { claimpromocodeAction } from "../../../Action/user.action";

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export default function Claim() {

  const [promocode, setpromocode] = useState("");
  const loginData = useSelector((state) => state.auth.LoginDetails); // (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));

  const inputhandler = (e) => {
    setpromocode(e.target.value);
  };

  const submitpromo = async (e) => {
    e.preventDefault();
    if (!loginData.id){
      toast.error("Please login first");
      return;
    }
    if (promocode.trim() == "") {
      toast.error("Please enter promo code");
    } else if (promocode.trim().length > 15 || promocode.trim().length < 8) {
      toast.error("Please enter valid promo code");
    } else {
      try {
        let res = await claimpromocodeAction({
          authToken: loginData.authToken,
          promo_code: promocode,
        });
        if (res.data.success) {
          setpromocode("");
          toast.success(res.data.msg);
        } else {
          setpromocode("");
          toast.error(res.data.msg);
        }
      } catch (error) {
        setpromocode("");
        toast.error("Something went wrong please try again");
        console.log(error);
      }
    }
  };
  return (
    <>
      <section className="py-5">
        <div className="container mt-3 ">
          <h2 className="text-center mb-3">Claim Your Bonus NFTs</h2>
          <div className="d-flex justify-content-center mt-4">
            <div className="claim_section" style={{ width: '45%' }}>
              <div className="claim">
                {/* <form> */}
                <div className="row">

                  <div className="col-md-12 text-center">
                    {/* <input type="text" className="w-75" placeholder="Enter Your Promo code" /> */}
                    <div className="h-100" style={{ padding: '0% 3%' }}>
                      <InputGroup className="">
                        <Form.Control
                          placeholder="Please enter your Promo code here"
                          aria-label="Recipient's username"
                          onChange={inputhandler}
                          value={promocode}
                          aria-describedby="basic-addon2"
                        />
                        <Button variant="outline-secondary" onClick={submitpromo} className="text-white h-100" id="button-addon2">
                          Claim
                        </Button>
                      </InputGroup>
                    </div>
                  </div>
                  {/* <div className="col-md-4 text-center mt-3 mt-md-0">
                                <button className="btn btn_uni text-white w-50">
                                    claim
                                </button>
                            </div> */}
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
