import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DashboardHeader from "./header/Header";
import SidebarPro from "./sidebar/Sidebar";
import '../../assets/style/Wallet.css'
import DataTable from "react-data-table-component";

export default function Wallet(props) {

    const { collapsed, setCollapsed } = props;

    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            center: true
        },
        {
            name: 'Year',
            selector: row => row.year,
            sortable: true,
            center: true
        },
    ];

    const data = [
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px',
                borderColor: '#262626 !important',
            },
        },
        headRow: {
            style: {
                border: 'none'
            }
        },
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                background: '#222630',
                color: 'white',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                background: '#17191c',
                color: 'white',
            },
        },
        pagination: {
            style: {
                borderTop: '1px solid #262626'
            }
        }
    };

    return (
        <>
            <div className="dashboard">
                <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
                <div className={collapsed ? "marginL25" : "marginL250"} id="outlet">
                    <DashboardHeader
                        className={collapsed ? "marginL25" : "marginL250"}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                    />

                    <section className="wallet_section">
                        <Container>
                            <div className="my_referral mt-5 p-0">
                                <Row>
                                    <Col lg={6} className="mb-4 mb-lg-0">
                                        <div className="wallet_box">
                                            <h4 className="text-white">
                                                Referral Wallet Balance : <span style={{ color: '#0bd1d9' }}>xxxxx</span>
                                            </h4>

                                            <Row className="mt-4">
                                                <Col lg={6} className="mb-3 mb-lg-0">
                                                    <button className="btn_uni w-100">Transfer MNT</button>
                                                </Col>

                                                <Col lg={6}>
                                                    <button className="btn_uni w-100">Transfer to Sportsmint</button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="wallet_box">
                                            <h4 className="text-white">
                                                Royalty and Winning : <span style={{ color: '#0bd1d9' }}>xxxxx</span>
                                            </h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mt-5">
                                <ul class="nav nav-tabs my-3" id="myTab" role="tablist">

                                    <li class="nav-item me-md-3 mb-2" role="presentation">
                                        <button
                                            class="nav-link active"
                                            id="home-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#home-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="home-tab-pane"
                                            aria-selected="true"
                                        >
                                            Transfer History
                                        </button>
                                    </li>
                                    <li class="nav-item me-md-3 mb-2" role="presentation">
                                        <button
                                            class="nav-link"
                                            id="profile-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#profile-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="profile-tab-pane"
                                            aria-selected="false"
                                        >
                                            Transfer to Sportsmint
                                        </button>
                                    </li>
                                    <li class="nav-item mb-2" role="presentation">
                                        <button
                                            class="nav-link"
                                            id="royalty-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#royalty-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="royalty-tab-pane"
                                            aria-selected="false"
                                        >
                                            Royalty & Earning
                                        </button>
                                    </li>

                                </ul>

                                <div class="tab-content" id="myTabContent">
                                    <div
                                        class="tab-pane fade show active"
                                        id="home-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                        tabindex="0"
                                    >
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            customStyles={customStyles}
                                            pagination
                                        />
                                    </div>

                                    <div
                                        class="tab-pane fade"
                                        id="profile-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                        tabindex="0"
                                    >
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            customStyles={customStyles}
                                            pagination
                                        />
                                    </div>

                                    <div
                                        class="tab-pane fade"
                                        id="royalty-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="royalty-tab"
                                        tabindex="0"
                                    >
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            customStyles={customStyles}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                </div>
            </div>
        </>
    )
}