import React from "react";
import { MdArrowOutward } from "react-icons/md";
import { JackInTheBox, Bounce } from 'react-awesome-reveal'

export default function HowItWorks() {
    return (
        <>
            <section className="how_it_works_banner">
                <div className="container">
                    <div className="how_it_works">
                       
                            <h2 className="hd">How It Works</h2>
                            <p className="text-center">Immerse yourself in the exhilarating world of fantasy sports at SportsMint Fantasy. </p>
                     
                        <div className="row">
                            <div className="col-lg-3 mt-4 mt-md-0 d-flex align-items-center">
                                <div>
                                    <div className="choose_game">
                                        <h3>1. Connect Wallet</h3>
                                        <p>Choose your preferred wallet at your convenience</p>
                                        {/* <div className="btn_hiw">
                                    <a href="">Explore More</a>
                                    <span className="icon"><MdArrowOutward /></span>
                                </div> */}
                                    </div>

                                    <hr style={{ background: 'white', height: '2px' }} className="my-3" />

                                    <div className="choose_game mt-3">
                                        <h3>2. Choose Your Club</h3>
                                        <p>Choose your preferred NFT Club.</p>
                                        {/* <div className="btn_hiw">
                                    <a href="">Explore More</a>
                                    <span className="icon"><MdArrowOutward /></span>
                                </div> */}
                                    </div>

                                    <hr style={{ background: 'white', height: '2px' }} className="my-3" />

                                    <div className="choose_game mt-3">
                                        <h3>3. Make The Payment</h3>
                                        <p>Make the payment using either MNT tokens or USDT.</p>
                                    </div>




                                </div>
                            </div>
                            <div className="col-lg-6 mt-4 mt-md-0">

                                <div className="v_box h-auto">
                                    
                                        <div>
                                            {/* <iframe width="560" height="415" src="https://www.youtube.com/embed/YYgQD2WT3vg?si=uP_sNj6pfRP4b99o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}

                                            <iframe width="630" height="415" src="https://www.youtube.com/embed/AQYYjKOfAjE?si=a4KNDBVLW8FcKucg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                            {/* <div>
                                        <video style={{ width: "100%", borderRadius: '25px' }} controls>
                                            <source src="video/video.mp4" type="video/mp4" />
                                        </video>
                                    </div> */}

                                        </div>
                                        {/* <span className="ply_btn">
                                <FaPlay />
                            </span> */}
                                   
                                </div>

                            </div>

                            <div className="col-lg-3 mt-4 mt-md-0 d-flex align-items-center mt-3 mt-lg-0">
                                <div>

                                    <div className="choose_game">
                                        <h3>4. Customize Your Club</h3>
                                        <p>Customize your club by choosing preferred NFTs.</p>
                                        {/* <div className="btn_hiw">
                                    <a href="">Explore More</a>
                                    <span className="icon"><MdArrowOutward /></span>
                                </div> */}
                                    </div>



                                    <hr style={{ background: 'white', height: '2px' }} className="my-3" />

                                    <div className="choose_game mt-3">
                                        <h3>5. Refer and Earn</h3>
                                        <p>Refer new users and incentivize yourself.</p>
                                        {/* <div className="btn_hiw">
                                    <a href="">Explore More</a>
                                    <span className="icon"><MdArrowOutward /></span>
                                </div> */}
                                    </div>

                                    <hr style={{ background: 'white', height: '2px' }} className="my-3" />

                                    <div className="choose_game mt-3">
                                        <h3>6. Explore The Collections</h3>
                                        <p>Explore your dashboard for every actions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}