import { FaFilter } from "react-icons/fa";
import { TbCricket } from "react-icons/tb";
import { IoBowlingBallOutline } from "react-icons/io5";
import { MdSportsCricket } from "react-icons/md";
import { CiFlag1 } from "react-icons/ci";
import { GiVibratingShield } from "react-icons/gi";
import { AiOutlineBars } from "react-icons/ai";
import { IoSearchOutline } from "react-icons/io5";
import {
  createpackagefromselectednftAction,
  displayCountriesAction,
  displayNftsFilterAction,
  displayNftsFilterCountryAction,
  displayfilterparameterAction,
  getplayerbypackageplanidAction,
  getpurchasedpurchasedstatisticsAction,
} from "../../Action/user.action";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import config from "../../config/config";
import Swal from "sweetalert2";
import CustomLoader from "../dashboard/CustomLoader";
import { GiCricketBat } from "react-icons/gi";

const CustomizeNft = ({ AutoPickPlayers }) => {
  const location = useLocation();

  const loginData = useSelector((state) => state.auth.LoginDetails);
  // let packageId = location.state?.packagePurchasedId;
  const [planData, setPlanData] = useState([]);
  const [nftListData, setnftListData] = useState([]);
  const [selectedskillfiltername, setselectedskillfiltername] = useState("");

  const [activePlanType, setActivePlanType] = useState(0);
  const [skillId, setSkillId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [selectedPlayer, setSelectedPlayer] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [postfilterdata, setpostfilterdata] = useState([]);
  const [displaycountries, setdisplaycountries] = useState([]);
  const [displayskills, setdisplayskills] = useState([]);
  const [displaytournament, setdisplaytournament] = useState([]);
  const [FiltertotaldisplayNfts, setFiltertotaldisplayNfts] = useState(0);

  const navigate = useNavigate();
  const [totaldisplayNfts, settotaldisplayNfts] = useState(0);

  const [selectedskillfilter, setselectedskillfilter] = useState("");
  const [selectedcountryfilter, setselectedcountryfilter] = useState("");
  const [selectedsort, setselectedsort] = useState("");
  const [loader, setloader] = useState(false);
  const [selectedcountryfiltername, setselectedcountryfiltername] = useState("");
  const [selectedtournamentfilter, setselectedtournamentfilter] = useState("");
  const [selectedtournamentfiltername, setselectedtournamentfiltername] = useState("");

  const { id } = useParams();
    console.log(id,"id");
    let packageId = id;

  useEffect(() => {
    planList();
    displayskillandcountriesfunc();
    // if (
    //   !location.state?.packagePurchasedId ||
    //   location.state?.packagePurchasedId == "" ||
    //   !location.state ||
    //   location.state == ""
    // ) {
    //   // window.location.href = `${config.baseUrl}nfts`
    //   navigate(`${config.baseUrl}myclub`);
    // }
  }, []);

  useEffect(() => {
    // if (nftListData.length > 0) {
      nftList();
    // }
  }, [activePlanType]);

  useEffect(() => {
    if (
      AutoPickPlayers.length > 0 &&
      planData.length > 0 &&
      selectedPlayer.length == 0
    ) {
      let arr = {};
      let planDataArr = planData || [];
      // let nftListDataArr = nftListData;

      AutoPickPlayers.filter((item) => {
        arr[item.player_id] = {
          planId: item.nft_type,
          qty: parseInt(item.player_count || 0),
        };

        let index = planData.findIndex((child) => child.id == item.nft_type);
        let PLAN = planData[index] || [];
        PLAN.selectQty = !PLAN?.selectQty ? 0 : PLAN?.selectQty;
        PLAN.selectQty += parseInt(item.player_count || 0);
        let planDataArr = planData;
        planDataArr[index] = PLAN;
      });
      setPlanData([...planDataArr]);
      setSelectedPlayer(arr);
      nftList(arr);
    }
  }, [AutoPickPlayers, nftListData, planData]);

  const planList = async () => {
    try {
      let res = await getpurchasedpurchasedstatisticsAction(
        loginData.authToken,
        {
          packageId: packageId,
        }
      );
      if (res.data.success) {
        setPlanData(res.data.data);
        setActivePlanType(res.data.data[0].id);
      } else {
        setPlanData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlerreset = ()=>{
    if (selectedcountryfiltername=="" && selectedskillfiltername== "" && selectedsort=="" && document.getElementById("searchrefsform").value == ""&& selectedtournamentfiltername==""){
      return;
    }
    else {
      nftList();
    }
  }

  const nftList = async (selectedPlayerArr = selectedPlayer) => {
    try {
      setloader(true);
      setselectedskillfiltername("");
      setselectedcountryfiltername("");
      setselectedskillfilter("");
      setselectedcountryfilter("");
      setselectedsort("");
      setselectedskillfilter("");
      setselectedcountryfilter("");
      setselectedtournamentfilter("");
      setselectedtournamentfiltername("");
      let res = await getplayerbypackageplanidAction(loginData.authToken, {
        nft_type_id: activePlanType || 0,
        skill_id:  0,
        country_id:  0,
        tournament_id:  "",
      });
      if (res.data.success) {
        let nftListDataArr = res.data.data.filter((item) => {
          item.selected = !selectedPlayerArr[item.id]?.qty ? 0 : 1;
          return item;
        });
        nftListDataArr = nftListDataArr.sort(
          (a, b) => b.selected || 0 - a.selected || 0
        );
        setselectedcountryfilter("");
        setselectedskillfilter("");
        setselectedsort("");
        document.getElementById("searchrefsform").value = "";
        setnftListData(nftListDataArr);
        setfilterdata(res.data.data);
        setpostfilterdata(res.data.data);
        settotaldisplayNfts(res.data.data.length);
        setFiltertotaldisplayNfts(res.data.data);
        setloader(false);
      } else {
        setnftListData([]);
        setloader(false);
      }
    } catch (err) {
      setloader(false);
      console.log(err);
    }
  };

  const filterSkillAndCountryFuncs = async (
    filterSkillValue,
    filterCountryValue,
    filterSkillName,
    filterCountryName,
    filterTournamentValue,
    filterTournamentName
  ) => {
    console.log(
      filterSkillValue,
      "filterSkillValue",
      filterCountryValue,
      "filterCountryValue"
      // filterSkillName,"filterSkillName",
      // filterCountryName,"filterCountryName",
      // filterTournamentValue,"filterTournamentValue",
      // filterTournamentName,"filterTournamentName"
    );
    try {
      // setloader(true);

      setselectedskillfilter(filterSkillValue);
      setselectedcountryfilter(filterCountryValue);
      setselectedcountryfiltername(filterCountryName);
      setselectedtournamentfilter(filterTournamentValue);
      setselectedtournamentfiltername(filterTournamentName);
      setselectedskillfiltername(filterSkillName);
      document.getElementById("searchrefsform").value = "";
      setselectedsort("");
      let res = await getplayerbypackageplanidAction(loginData.authToken, {
        nft_type_id: activePlanType || 0,
        skill_id: filterSkillValue || 0,
        country_id: filterCountryValue || 0,
        tournament_id: filterTournamentValue || "",
      });
      if (res.data.success) {
        console.log(res.data);
        setnftListData(res.data.data);
        setFiltertotaldisplayNfts(res.data.data);
        settotaldisplayNfts(res.data.data.length);
        // settotaldisplayNfts(res.data);
        setloader(false);
      } else {
        setloader(false);
        settotaldisplayNfts(0)
        toast.error(res.data.msg);
        setnftListData([]);
        setFiltertotaldisplayNfts([]);
      }
    } catch (err) {
      setloader(false);
      settotaldisplayNfts(0)
      console.log(err);
    }
  };

  const displayskillandcountriesfunc = async () => {
    try {
      let res = await displayfilterparameterAction();
      if (res.success) {
        setdisplaycountries(res.data.country);
        setdisplayskills(res.data.skills);
        setdisplaytournament(res.data.tournament);
      } else {
        setdisplaycountries([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const sortinginputhandlers = (item) => {
    const sortingOptions = {
      1: "Price : Low to High",
      2: "Price : High to Low",
      3: "Age : Low to High",
      4: "Age : High to Low",
      5: "Rating : Low to High",
      6: "Rating : High to Low",
    };
    const selectedSorts = sortingOptions[item];
    setselectedsort(selectedSorts);
    let sortedNfts = [...FiltertotaldisplayNfts];
    if (item == 1) {
      sortedNfts.sort(
        (a, b) => parseFloat(a.nft_price) - parseFloat(b.nft_price)
      );
    }
    if (item == 2) {
      sortedNfts.sort(
        (a, b) => parseFloat(b.nft_price) - parseFloat(a.nft_price)
      );
    }
    if (item == 3) {
      sortedNfts.sort((a, b) => parseFloat(a.age) - parseFloat(b.age));
    }
    if (item == 4) {
      sortedNfts.sort((a, b) => parseFloat(b.age) - parseFloat(a.age));
    }

    if (item == 5) {
      sortedNfts.sort(
        (a, b) =>
          parseFloat(
            parseFloat(a.dynamic_rating) + parseFloat(a.static_rating)
          ) -
          parseFloat(parseFloat(b.static_rating) + parseFloat(b.dynamic_rating))
      );
    }
    if (item == 6) {
      sortedNfts.sort(
        (a, b) =>
          parseFloat(
            parseFloat(b.dynamic_rating) + parseFloat(b.static_rating)
          ) -
          parseFloat(parseFloat(a.dynamic_rating) + parseFloat(a.static_rating))
      );
    }
    setnftListData(sortedNfts);
  };

  const PlayerSelect = (type, id) => {
    let mainArray = selectedPlayer;
    let chailArray = Object(mainArray[id]);
    let count = chailArray?.qty ? chailArray?.qty : 0;
    let index = planData.findIndex((item) => item.id == activePlanType);
    let PLAN = planData[index] || [];
    PLAN.selectQty = !PLAN?.selectQty ? 0 : PLAN?.selectQty;
    if (PLAN.selectQty == PLAN.qty && type) {
      Swal.fire(
        `<h1 class="text-dark">NFT limit reached of ${PLAN.name} category</h1>`,
        "",
        "warning"
      );

      return;
    }

    if (type) {
      count += 1;
      PLAN.selectQty += 1;
    } else if (count > 0) {
      count -= 1;
      PLAN.selectQty -= 1;
    } else {
      count = 0;
    }

    chailArray.planId = activePlanType;
    chailArray.qty = count;
    let planDataArr = planData;
    PLAN.error = false;
    planDataArr[index] = PLAN;

    setSelectedPlayer({ ...mainArray, [id]: chailArray });
    setPlanData([...planDataArr]);
  };

  const inputhandler = (e) => {
    const { value, name } = e.target;
    if (value== "" && selectedcountryfiltername=="" && selectedskillfiltername== "" && selectedsort=="" && selectedtournamentfiltername==""){
      nftList();
    }
    else {

    let filtereditems = FiltertotaldisplayNfts.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.nick_name.toLowerCase().includes(value.toLowerCase()) ||
        item.country.toLowerCase().includes(value.toLowerCase()) ||
        item.skill.toLowerCase().includes(value.toLowerCase())
      //  ||
      // item.nft_type.toLowerCase().includes(value.toLowerCase())
    );
    setnftListData(filtereditems);
    settotaldisplayNfts(filtereditems.length);
  }
  };

  const checkout = async () => {
    let array = planData;
    let index = array.findIndex((item) => item.selectQty != item.qty);
    if (index > -1) {
      array[index].error = true;
      setPlanData([...array]);
      toast.error(
        `Please select remaining player in ${array[index].name} category`
      );
      return;
    }
    let mainArr = {};
    let keys = Object.keys(selectedPlayer);
    keys.filter((item) => {
      for (let i = 0; i < selectedPlayer[item].qty; i++) {
        if (!mainArr[selectedPlayer[item].planId]) {
          mainArr[selectedPlayer[item].planId] = [parseInt(item)];
        } else {
          mainArr[selectedPlayer[item].planId].push(parseInt(item));
        }
      }
    });

    let indexSecond = planData.findIndex((item) => mainArr[item.id].length != item.qty);
    if (indexSecond > -1) {
      Swal.fire({
        title: `<h1 class="text-dark">Checkout Alert!</h1>`,
        html: `<b>We need a quick detail check. Please checkout again</b>`,
        // icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Okay Thanks",
      }).then(async (result) => {
        window.location.reload();
      });
      return;
    }

    try {
      Swal.fire({
        title: `<h1 class="text-dark">Confirm Checkout!</h1>`,
        html: `<b>Please note that once you proceed to checkout, NFTs cannot be changed or replaced within the club.</b>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // console.log( 
          //   mainArr,
          //   {
          //   selectedPlayer: mainArr,
          //   packageId: parseInt(packageId),
          // });
          let res = await createpackagefromselectednftAction(
            loginData.authToken,
            {
              selectedPlayer: mainArr,
              packageId: parseInt(packageId),
            }
          );
          if (res.data.success) {
            Swal.fire(`<h1 class="text-dark">Checkout Successful!</h1>`);
            setTimeout(() => {
              navigate(`${config.baseUrl}myclub`);
            }, 1500);
          } else {
            if (res.data.status == 205 ) {
              Swal.fire({
                title: `<h1 class="text-dark">Checkout Alert!</h1>`,
                html: `<b>We need a quick detail check. Please checkout again</b>`,
                // icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Okay Thanks",
              }).then(async (result) => {
                window.location.reload();
              });
              return;
            }
            Swal.fire(
              `<h1 class="text-dark">Failed to checkout!</h1>`,
              res.data.msg,
              "error"
            );
          }
        } else {
          // alert(1)
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="mt-4">
        <div className="row">
          <div className="col-12 px-1 px-lg-3">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {planData.map((item, index) => (
                <li
                  onClick={() => setActivePlanType(item.id)}
                  className="nav-item mt-3"
                  role="presentation"
                >
                  <button
                    className={`me-2 ${index == 0 ? "active" : ""}`}
                    id={`${index}-home-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${index}-home`}
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    {item.name?.toUpperCase()}{" "}
                    <span>
                      {item.selectQty || 0} / {item.qty}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-12">
            <div className="row mt-3 mb-4">
              <div className="col-lg-4 col-xl-3 px-lg-3 mb-3">
                <div className="fltrnft">
                  <input
                    type="search"
                    className="form-control ps-1 ps-md-5"
                    placeholder="Search"
                    onChange={(e) => inputhandler(e)}
                    id="searchrefsform"
                  />
                  <span className="icnsrch d-none d-md-block">
                    <IoSearchOutline />
                  </span>
                </div>
              </div>

              <div className="col-lg-12 col-xl-9">
                <div className="row justify-content-xl-end justify-content-lg-between">
                  <div className="col-6 col-lg-auto text-end mb-3">
                    <div className="d-flex justify-content-md-end">
                      <div className="dropdown  w-100">
                        <button
                          className="btn mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex justify-content-end align-items-center">
                            <AiOutlineBars />
                            <span className="ms-1">
                              {selectedskillfiltername == ""
                                ? "Select Skills"
                                : selectedskillfiltername}
                            </span>
                          </div>
                        </button>
                        <ul
                          className="dropdown-menu bg-dark"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2"
                              onClick={() =>
                                filterSkillAndCountryFuncs(
                                  0,
                                  selectedcountryfilter,
                                  "",
                                  selectedcountryfiltername,
                                  selectedtournamentfilter,
                                  selectedtournamentfiltername
                                )
                              }
                            >
                              <span>
                                <GiCricketBat />
                              </span>
                              <span className="ms-2">All</span>
                            </a>
                          </li>
                          {displayskills.map((item, index) => {
                            return (
                              <li>
                                <a
                                  className="dropdown-item btn_uni m-2"
                                  onClick={() =>
                                    filterSkillAndCountryFuncs(
                                      item.id,
                                      selectedcountryfilter,
                                      item.name,
                                      selectedcountryfiltername,
                                      selectedtournamentfilter,
                                      selectedtournamentfiltername
                                    )
                                  }
                                >
                                  <span>
                                    <img src={item.logo} alt={item.logo} />
                                  </span>
                                  <span className="ms-2">{item.name}</span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-auto text-end mb-3">
                    <div className="d-flex justify-content-md-end">
                      <div className="dropdown  w-100">
                        <button
                          className="btn mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex justify-content-end align-items-center">
                            <AiOutlineBars />
                            <span className="ms-1">
                              {selectedtournamentfiltername == ""
                                ? "Select Tournaments"
                                : selectedtournamentfiltername}
                            </span>
                          </div>
                        </button>
                        <ul
                          className="dropdown-menu bg-dark"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2"
                              onClick={() =>
                                filterSkillAndCountryFuncs(
                                  selectedskillfilter,
                                  selectedcountryfilter,
                                  selectedskillfiltername,
                                  selectedcountryfiltername,
                                  "",
                                  ""
                                )
                              }
                            >
                              <span>
                                <GiCricketBat />
                              </span>
                              <span className="ms-2">All</span>
                            </a>
                          </li>
                          {displaytournament.map((item, index) => {
                            return (
                              <li>
                                <a
                                  className="dropdown-item btn_uni m-2"
                                  onClick={() =>
                                    filterSkillAndCountryFuncs(
                                      selectedskillfilter,
                                      selectedcountryfilter,
                                      selectedskillfiltername,
                                      selectedcountryfiltername,
                                      item.id,
                                      item.short_name
                                    )
                                  }
                                >
                                  <span className="ms-2">
                                    {item.short_name}
                                  </span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-auto text-end mb-3">
                    <div className="d-flex justify-content-end">
                      <div className="dropdown  w-100">
                        <button
                          className="btn mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex justify-content-end align-items-center">
                            <AiOutlineBars />
                            <span className="ms-1">
                              {selectedcountryfiltername == ""
                                ? "Select Country"
                                : selectedcountryfiltername}
                            </span>
                          </div>
                        </button>
                        <ul
                          className="dropdown-menu bg-dark"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2"
                              onClick={() =>
                                filterSkillAndCountryFuncs(
                                  selectedskillfilter,
                                  0,
                                  selectedskillfiltername,
                                  "",
                                  selectedtournamentfilter,
                                  selectedtournamentfiltername
                                )
                              }
                            >
                              <span>
                                {/* <img src="" className="img-fluid fltr_imgs" alt="" /> */}
                              </span>
                              <span className="ms-2">All</span>
                            </a>
                          </li>
                          {displaycountries.length > 0
                            ? displaycountries.map((item, index) => {
                                return (
                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2"
                                      onClick={() =>
                                        filterSkillAndCountryFuncs(
                                          selectedskillfilter,
                                          item.id,
                                          selectedskillfiltername,
                                          item.name,
                                          selectedtournamentfilter,
                                          selectedtournamentfiltername
                                        )
                                      }
                                    >
                                      <span>
                                        <img
                                          src={item?.flag_logo}
                                          className="img-fluid fltr_imgs"
                                          alt=""
                                        />
                                      </span>
                                      <span className="ms-2">{item.name}</span>
                                    </a>
                                  </li>
                                );
                              })
                            : "Sorry, no results found!"}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-auto text-end mb-3">
                    <div className="d-flex justify-content-end">
                      <div className="dropdown  w-100">
                        <button
                          className="btn mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex justify-content-end align-items-center">
                            <AiOutlineBars />
                            <span className="ms-1">
                              {!selectedsort ? "Sort by" : selectedsort}
                            </span>
                          </div>
                        </button>
                        <ul
                          className="dropdown-menu bg-dark"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandlers(3)}
                            >
                              {/* <span><IoBowlingBaallOutline /></sspan> */}
                              <span className="ms-2">Age : Low to High </span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandlers(4)}
                            >
                              {/* <span><MdSportsCricket /></span> */}
                              <span className="ms-2">Age : High to Low</span>
                            </a>
                          </li>

                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandlers(5)}
                            >
                              {/* <span><IoBowlingBallOutline /></span> */}
                              <span className="ms-2">Rating : Low to High</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandlers(6)}
                            >
                              {/* <span><MdSportsCricket /></span> */}
                              <span className="ms-2">Rating : High to Low</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-auto text-end text-lg-end d-flex align-items-md-start align-items-end">
                    <button
                      className="btn_uni w-100 justify-content-center"
                      onClick={() => handlerreset()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="mt-3 mt-lg-0">
              <p className="m-0">NFTs Count {totaldisplayNfts}</p>
            </div>
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
          <div className="row mx-0">
            {loader == true ? (
              <div className="d-flex justify-content-center">
                <CustomLoader />
              </div>
            ) : nftListData.length > 0 ? (
              nftListData.map((item, index) => {
                return (
                  <div key={index} className="col-xl-3 col-lg-4 col-md-6 mt-3">
                    <div
                      className="position-relative"
                      style={
                        selectedPlayer[item.id]?.planId == activePlanType &&
                        selectedPlayer[item.id]?.qty
                          ? {
                              border: "double 2px transparent",
                              borderRadius: "19px",
                              backgroundImage:
                                "linear-gradient(rgb(29, 193, 220), rgb(206, 43, 245)), linear-gradient(to right, rgb(118 117 233), rgb(224 27 248))",
                              backgroundOrigin: "border-box",
                              backgroundClip: "content-box, border-box",
                            }
                          : {
                              border: "unset",
                            }
                      }
                    >
                      <div className="text-center">
                        <div className="fs-6 t-price">
                          <small className="position-absolute bonus_tag">
                            {item?.category}
                          </small>
                          <span>${item.nft_price}</span>
                        </div>
                        <img
                          src={item.image}
                          alt=""
                          className="cricketcard"
                          onError={(e) => {
                            e.target.src =
                              "https://web3sports.s3.ap-south-1.amazonaws.com/Players/dummy.png";
                          }}
                        />
                        <div className="cardDetail">
                          <div className="cardInnerDetail">
                            <small className="text-primary fw-medium">
                              {Math?.trunc(
                                parseInt(item?.dynamic_rating) +
                                  parseInt(item?.static_rating)
                              )}
                              /100
                            </small>
                            <h5
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={item.name?.length < "15" ? "" : item.name}
                            >
                              {item.name?.length > "15"
                                ? item.name?.slice(0, 14) + ".."
                                : item.name}
                            </h5>
                            <small className="text-primary fw-medium">
                              {item.skill}
                            </small>
                          </div>
                          <div className="d-flex justify-content-between">
                            <small className="text-uppercase">
                              Age {item.age}
                            </small>
                            <small className="text-uppercase">
                              <span className="nft_flag_img">
                                <img
                                  src={item?.flag_logo}
                                  className="img-fluid"
                                />
                                <span>{item.country}</span>
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="counter">
                      <button
                        className="minus"
                        onClick={() => PlayerSelect(false, item.id)}
                      >
                        -
                      </button>
                      <button>
                        {selectedPlayer[item.id]?.planId == activePlanType
                          ? selectedPlayer[item.id]?.qty
                          : 0}
                      </button>
                      <button
                        className="plus"
                        onClick={() => PlayerSelect(true, item.id)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="h-100 mt-5">
                <div className="h-100 mt-5">
                  <div className="h-75">
                    <div className="text-center">
                      <img
                        src="ds/nodatapreview.png"
                        alt=""
                        className="img-fluid"
                        style={{ width: "200px" }}
                      />
                    </div>
                    <h4 className="mt-3 text-center">
                      Sorry, no results found!
                    </h4>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Toaster />
        </div>
      </div>

      <button
        className="btn_fix text-end btn_uni check_out_btn"
        onClick={() => checkout()}
      >
        Checkout
      </button>
    </>
  );
};

export default CustomizeNft;
