import React from 'react';
import '../../assets/style/loader.css'
const CustomLoader = () => {
   return (
    <div className='d-flex justify-content-center'>
    <div class="Customloaderss"></div> 
    </div>
  );
};

export default CustomLoader;
