import React, { useEffect, useState } from "react";
import { getfaqAction } from "../../../Action/user.action";
import { Slide, Bounce } from 'react-awesome-reveal'

export default function Faqs() {
  const [faqs, setFaqs] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);
  const displayFaqsFunc = async () => {
    try {
      let res = await getfaqAction();
      if (res.success) {
        setFaqs(res.data);
      } else {
        setFaqs([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAccordionToggle = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index); // Toggle active index
  };


  useEffect(() => {
    displayFaqsFunc();
  }, []);

  return (
    <section className="faq_banner">
      <div className="container">
        <div className="faq mb-0">
         
            <h2 className="hd">FAQs</h2>
       
          <div className="accordion mt-4" id="accordionExample">
            {faqs.map((item, index) => (
              <div key={index}>
                <div className="accordion-item">
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                      type="button"
                      onClick={() => handleAccordionToggle(index)} // Toggle accordion on click
                      aria-expanded={activeIndex === index ? "true" : "false"}
                      aria-controls={`collapse${index}`}
                    >
                      {item.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-1">
                          <div></div>
                        </div>
                        <div className="col-11">
                          <p>{item.answer}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}