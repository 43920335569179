import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { TbLogout } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDisconnect, useAccount, useBalance, useSignMessage } from "wagmi";
import { readContract } from '@wagmi/core'
import Web3 from "web3";
import { bscTestnet } from 'wagmi/chains';
import { loginAction, registrationAction } from "../../../Action/user.action";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
    Web3Modal,
    Web3Button,
    useWeb3Modal,
    Web3ModalProps,
} from "@web3modal/react";
import {
    configureChains,
    Connector,
    useWalletClient,
    WagmiConfig,
    useConnect,
    useChainId,
    useNetwork,
    useConfig
} from "wagmi";


import Web3ModalProvider from '../../ConnectWallet/Web3ModalProvider';
import { getSignatureByWeb3 } from '../../Web3/Connector'
import { FaBarsStaggered } from "react-icons/fa6";
import config from "../../../config/config";
import * as ACTIONTYPES from "../../../redux/actionTypes";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import BottomNavigation from '../../navigator/BottomNavigation';
export default function DashboardHeader(props) {
    const loginData = useSelector((state) => state.auth.LoginDetails);// (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));
    const AfterConnectAddress = useSelector((state) => state.auth.walletConnnected);
    const profilrPic = localStorage.getItem("ProfilePic")
    const { address, isConnecting, isDisconnected } = useAccount()
    const _useDisconnect = useDisconnect();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // good morning sir 
    useEffect(() => {
        // alert(isDisconnected)
        if (!loginData.id) {
            navigate(`${config.baseUrl}`);
            // setTimeout(() => {
            // }, 1000);
        }
    }, [loginData])

    const navigatetoprofile = () => {
        navigate(`${config.baseUrl}profileUpdation`);
    }

    const disconnectWallet = async () => {
        _useDisconnect.disconnect();
        setTimeout(() => {
            callDispatch()
        }, 2000);

    }

    const callDispatch = () => {
        localStorage.removeItem("signature");
        localStorage.removeItem("ProfilePic")
        // Cookies.remove('loginSuccessWeb3Sports');
        // Cookies.remove('loginSuccess');
        // localStorage.removeItem("walletAddress");
        dispatch({ type: ACTIONTYPES.walletConnnected, payload: "" });
        dispatch({ type: ACTIONTYPES.USERLOGIN, payload: "", token: "" });
        //navigate(`${config.baseUrl}`);

    }

    const { collapsed, setCollapsed } = props

    console.log('collapsed', collapsed);

    // const [scrollPosition, setScrollPosition] = useState(0);

    // useEffect(() => {
    //     const content = document.getElementById("dashboard_home");
    //     const handleScroll = () => {
    //         setScrollPosition(content.scrollTop);
    //     };

    //     content.addEventListener("scroll", handleScroll);
    //     return () => {
    //         content.removeEventListener("scroll", handleScroll);
    //     };
    // }, [scrollPosition]);


    // const [detector, setDetector] = useState(0)
    // const old = document.getElementById("outlet")?.scrollTop

    // useEffect(() => {
    //     const content = document.getElementById("outlet");

    //     const handleScroll = () => {

    //         setDetector(content?.scrollTop)
    //     }

    //     content?.addEventListener("scroll", handleScroll);

    //     return () => {
    //         content?.removeEventListener("scroll", handleScroll);
    //     }

    // }, [detector])

    // console.log('detector', detector);



    return (
        <>
            <section className='header_section mt-10'>
                <div className="container">
                    <div className="header">
                        <Navbar expand="lg" id='navbar' className="bg-body-tertiary ps-0 justify-content-between" bg="dark" data-bs-theme="dark">
                            <div className='text-success d-block d-md-none'>
                                <img
                                    src="Sportsmint_final.svg"
                                    style={{
                                        height: '25px',
                                        width: "auto"
                                    }}
                                    className="img-fluid"
                                />
                            </div>

                            <div className='d-none d-md-block'></div>
                            {/* {
                                collapsed
                                    ?
                                    <div></div>
                                    :
                                    <div className='text-success'>
                                        <img
                                            src="Sportsmint_final.svg"
                                            style={{
                                                height: '25px',
                                                width: "auto"
                                            }}
                                            className="img-fluid"
                                        />
                                    </div>
                            } */}

                            {loginData?.id ?
                                <div style={{ cursor: "pointer" }} className='d-flex align-items-center' data-bs-toggle="dropdown">
                                    <p className='mb-0 me-3'>{AfterConnectAddress ? AfterConnectAddress.toString().slice(0, 4) + "...." + AfterConnectAddress.toString().slice(-4) : ""}</p>
                                    <div className='dropdown login_dtl order-1 order-md-1'>
                                        <div className='dropdown-toggle' aria-expanded="false">
                                            <img src={!loginData.profile_pic || loginData.profile_pic == "null" ? "placeholder.png" : loginData.profile_pic} alt="ProfilePic" />
                                        </div>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Button onClick={navigatetoprofile} className="dropdown-item">
                                                    <span className='me-2 icon'><CgProfile /></span>
                                                    <span>My Profile</span>
                                                </Button>
                                            </li>
                                            <li onClick={() => disconnectWallet()}>
                                                <Link className="dropdown-item">
                                                    <span className='me-2 icon' ><TbLogout /></span>
                                                    <span >Logout</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                : ""}
                        </Navbar>
                    </div>
                </div>
            </section>
            {/* {
                detector > old + detector
                    ? */}
            <BottomNavigation />
            {/* :
                    ''
            } */}

        </>
    )
}