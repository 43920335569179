import { useState, useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import {
  getUserProfile,
  updateUserProfile,
} from "../../Action/user.action";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import config from "../../config/config";
import { Link, useNavigate } from "react-router-dom";
import Header from "../layout/header/Header";
import { useDisconnect, useAccount, useBalance, useSignMessage } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RiVerifiedBadgeLine } from "react-icons/ri";

import Footer from "../layout/footer/Footer";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  Form,
  Button,
  Table,
  InputGroup,
  Modal,
  Card,
} from "react-bootstrap";
import SidebarPro from "../dashboard/sidebar/Sidebar";
import DashboardHeader from "../dashboard/header/Header";
import * as ACTIONTYPES from "../../redux/actionTypes";

export default function ProfileUpdation(props) {
  const loginData = useSelector((state) => state.auth.LoginDetails);
  const [tempEmail, setTempEmail] = useState("");
  const [isFormDisabled, setisFormDisabled] = useState(false);
  const [image_preview, setimage_preview] = useState("");
  const [image_file, setimage_file] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [activeEmail, setEmailActive] = useState(false);
  const { address, isConnecting, isDisconnected } = useAccount();
  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 768){
        setIsMobile(true);
    }
}, [window.innerWidth])

  useEffect(() => {
    getUserProfileFunc();

    // if (loginData?.id != undefined) {

    // }
    // else {
    //     navigate(`${config.baseUrl}`);
    // }
  }, [address]);

  // useEffect(() => {
  //   if (isDisconnected) {
  //     navigate(`${config.baseUrl}`);
  //   }
  // }, [isDisconnected]);

  const getUserProfileFunc = async () => {
    if (loginData) {
      try {

        let res = await getUserProfile(loginData.authToken);
        if (res.data.success) {
          setUserDetails(res.data.data);
          if (res.data.data.email) {
            if (loginData.profile_pic == null) {
              let data = {
                id: loginData.id,
                email: loginData.email,
                wallet_address: loginData.wallet_address,
                authToken: loginData.authToken,
                profile_pic: res.data.data.profile_pic,

              }
              dispatch({
                type: ACTIONTYPES.USERLOGIN,
                payload: data,
                token: data.authToken,
              });
            }
            else if (loginData.profile_pic != res.data.data.profile_pic) {
              let data = {
                id: loginData.id,
                email: loginData.email,
                wallet_address: loginData.wallet_address,
                authToken: loginData.authToken,
                profile_pic: res.data.data.profile_pic,

              }
              dispatch({
                type: ACTIONTYPES.USERLOGIN,
                payload: data,
                token: data.authToken,
              });
            }
            setisFormDisabled(true);
          }
          localStorage.setItem("ProfilePic", res?.data?.data?.profile_pic);
        } else {
          toast.error("Something seems off. Please try again!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const profilePicChange = async (e) => {
    e.preventDefault();
    const allowedTypes = ["image/png", "image/jpeg"]; // Allowed file types
    const selectedFile = e.target.files[0];
    const file = e.target.files[0];
    if (file && file.size > 1048576) { // 1 MB in bytes
      toast.error('Please select an image file smaller than 1 MB.');
      return;
    }
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      let image_as_base64 = URL.createObjectURL(selectedFile);
      setimage_file(selectedFile);
      setimage_preview(image_as_base64);
    } else {
      toast.error("Invalid file type. Please select a PNG or JPEG image.");
    }
  };

  const submitProfile = async (e) => {
    e.preventDefault();
    if (!userDetails.first_name || !userDetails.last_name) {
      toast.error("First name and last name are required.");
      return;
    }
    const formData = new FormData();
    if (loginData.authToken) {
      const formData = new FormData();

      if (!image_file) {
        if (!userDetails?.profile_pic) {
          formData.append("profilepic_url", userDetails?.profile_pic);
        } else {
          formData.append("profilepic_url", userDetails?.profile_pic);
        }
      } else {
        //console.log(userDetails?.profile_pic, "image file empty2 ");
        formData.append("profile_pic", image_file);
        formData.append("profilepic_url", userDetails?.profile_pic);
      }
      if (!userDetails?.email) {
      } else {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(userDetails.email)) {
          toast.error("Invalid email format");
          return;
        } else {
          // formData.append("email", userDetails?.email);
        }
      }
      if (!userDetails?.first_name) {
      } else {
        formData.append("first_name", userDetails?.first_name);
      }

      if (!userDetails?.last_name) {
      } else {
        formData.append("last_name", userDetails?.last_name);
      }
      try {
        if (!formData) {
        } else {
          // userDetails.profilePic = image_file;

          let res = await updateUserProfile(formData, loginData.authToken);

          if (res.data.success) {
            toast.success("Profile has been updated");
            setTimeout(() => {
              navigate(`${config.baseUrl}dashboard`);
            }, 500);
            getUserProfileFunc();
          } else {
            toast.success(res.data.msg);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("please connect your wallet to update profile");
    }
  };

  const { collapsed, setCollapsed } = props;
  return (
    <>
      <div className="dashboard">
        <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
        <Toaster/>
        <div className={collapsed ? "marginL25" : "marginL250"} id="outlet">
          <DashboardHeader
            className={collapsed ? "marginL25" : "marginL250"}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          {/* <Header /> */}
          <div className="container">
            <Toaster />
            <div className="profile_updation">
              <form>
                <div className="row mx-0">
                  <div className="col-md-4">
                    <div className="img_box">
                      <div className="img_wrapper text-center">
                        <div className="">
                          <div className="profile card-view rounded-20">
                            <h4 className="text-white mb-3">Profile image</h4>
                            <div className="profile position-relative">
                              {image_preview ? (<>
                                <img
                                  style={{
                                    height: "190px",
                                    width: "190px",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                  }}
                                  alt=""
                                  src={image_preview}
                                  className="img-fluid"
                                  />{console.log(123)}</>
                              ) : userDetails &&
                                userDetails.profile_pic != null &&
                                userDetails.profile_pic != "null" ? (<>
                                <img
                                  alt=""
                                  style={{
                                    height: "190px",
                                    width: "190px",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                  }}
                                  src={userDetails.profile_pic}
                                  className="img-fluid"
                                />
                                {console.log(userDetails &&
                                userDetails.profile_pic != null,userDetails,"userDetails")}
                                </>
                              ) : (<>
                                <img
                                  alt=""
                                  style={{
                                    height: "190px",
                                    width: "190px",
                                    objectFit: "cover",
                                  }}
                                  src="placeholder.png"
                                  className="img-fluid"
                                /> {console.log(789)}</>
                              )}
                              <input
                                onChange={profilePicChange}
                                type="file"
                                id="upload_profile_img"
                              />
                              <span className="position-absolute">
                                <FaRegEdit />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="btn_wrapper">
                                    <button className="btn_uni2">Save Changes</button>
                                </div>

                                <div className="btn_wrapper">
                                    <button className="btn_uni"><div>Cancel</div></button>
                                </div> */}
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6 mt-0">
                        <div className="field_wrapper">
                          <label>First Name</label>
                          <Form.Control
                            type="text"
                            placeholder="Enter First name"
                            value={userDetails.first_name || ""}
                            onChange={inputHandler}
                            name="first_name"
                          />{" "}
                        </div>
                      </div>

                      <div className="col-md-6 mt-0">
                        <div className="field_wrapper">
                          <label>Last Name</label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Last name"
                            value={userDetails.last_name || ""}
                            onChange={inputHandler}
                            name="last_name"
                          //disabled={isFormDisabled}
                          />{" "}
                        </div>
                      </div>

                      <div className="col-md-6 mt-0">
                        <div className="field_wrapper">
                          <label>Email Address</label>

                          <div className="position-relative">
                            <Form.Control
                              type="text"
                              className="pe-5"
                              placeholder="Email"
                              value={userDetails.email}
                              disabled
                            />
                            <span className="position-absolute fs-4 d-flex justify-content-center align-items-center" style={{
                              top: "7px",
                              right: "9px",
                              color: "green",
                            }}><RiVerifiedBadgeLine /></span>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-6 mt-0">
                        <div className="field_wrapper">
                          <label>Wallet Address</label>

                          <Form.Control
                            type="text"
                            placeholder="Wallet Address"
                            value={loginData.wallet_address}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mt-5 pt-3">
                        <div className="field_wrapper">
                          <button
                            className="btn_uni w-50"
                            onClick={(e) => submitProfile(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
