import { Modal, Row } from "react-bootstrap"


export const BonusNftModal = ({ isModal, handleCloseModal, nftList }) => {
    return (
        <Modal
            show={isModal}
            onHide={handleCloseModal}
            size="lg"
            backdrop="static"
            keyboard={false}
        >
       
        <Modal.Header closeButton>
          <Modal.Title className="ps-4">Bonus NFT</Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0 dtable" style={{overflowY:"scroll", height:"50vh"}}>
                <div className="rw overflow d-flex flex-wrap gap-2 justify-content-evenly">
                    {nftList.length > 0 ? (
                        nftList.map((item, index) => (
                            <div key={index} className="d-flex flex-row flex-wrap gap-2 mt-3">
                                <div className="position-relative">
                                    <div className="text-center">
                                        <div className="fs-6 t-price d-flex flex-row">
                                           <div>
                                           <small className="position-absolute bonus_tag_2 ">
                                                {item?.category}
                                            </small>
                                           </div>

                                           <div style={{position:"absolute", right:"6px", top:"0px", fontSize:16}}>
                                           <span>${item.price}</span>
                                           </div>
                                        </div>
                                        <img src={item.image} style={{width:"auto", height:"300px"}} alt="" className="cricketcard" onError={(e) => {
                                            e.target.src =
                                                "https://web3sports.s3.ap-south-1.amazonaws.com/Players/dummy.png";
                                        }} />
                                        <div className="cardDetail">
                                            <div className="cardInnerDetail2 " style={{marginBottom:10}}>
                                                <small className="text-primary fw-medium">
                                                    {/* {item.static_rating.split(".")[0]}/100 */}
                                                    {Math?.trunc(parseInt(item?.dynamic_rating) + parseInt(item?.static_rating))}/100
                                                </small>
                                                {/* <h5 className="mb-0">{item.name}</h5> */}
                                                <h5 className="mb-0" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title={item.name?.length < '15' ? "" : item.name}>
                                                    {item.name?.length > '15' ? item.name?.slice(0, 14) + ".." : item.name}
                                                </h5>
                                                {/* <h5 className="mb-0">{item.name?.length > '15' ? item.name?.slice(0, 14) + ".." : item.name}</h5> */}
                                                <small className="text-primary fw-medium">
                                                    {item.skill}
                                                </small>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <small className="text-uppercase">
                                                    Age {item.age}
                                                </small>
                                                <small className="text-uppercase">
                                                    <span className="nft_flag_img">
                                                        <img src={item.flag_logo} style={{width:5, height:5}} className="img-fluid" />
                                                        <span>{item.country}</span>
                                                    </span>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="h-100 mt-5">
                            <div className="h-75">
                                <div className="text-center">
                                    <img src="ds/nodatapreview.png" alt="" className="img-fluid" style={{ width: '200px' }} />
                                </div>
                                <h4 className="mt-3 text-center">Sorry, no results found!</h4>
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>

     
          
      
        </Modal>
    )
}