import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { AiOutlineBars } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { GiCricketBat } from "react-icons/gi";

import {
  displayfilterparameterAction,
  displayUserAllNfts,
} from "../../Action/user.action";
import CustomLoader from "../dashboard/CustomLoader";
import BonusNfts from "./BonusNfts";
// as
export default function TotalNfts() {
  const [displayNfts, setdisplayNfts] = useState([]);
  const [totaldisplayNfts, settotaldisplayNfts] = useState(0);
  const [FiltertotaldisplayNfts, setFiltertotaldisplayNfts] = useState([]);
  const [limit, setlimit] = useState(28);
  const [emptyloader, setemptyloader] = useState(true);
  const [displaycountries, setdisplaycountries] = useState([]);
  const [displayskills, setdisplayskills] = useState([]);
  const [displayCount, setDisplayCount] = useState(16);
  const searchref = useRef("");
  const [selectedskillfilter, setselectedskillfilter] = useState("");
  const [selectedcountryfilter, setselectedcountryfilter] = useState("");
  const [selectedsort, setselectedsort] = useState("");
  const [postfilterdata, setpostfilterdata] = useState([]);
  const [loader, setloader] = useState(false);
  const [filterdata, setfilterdata] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const observer = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [displayNftsUser, setdisplayNftsUser] = useState([]);
  const [filters, setFilters] = useState({
    skillId: null,
    countryId: null,
    tournamentId: "",
    sortBy: "",
    searchQuery: "",
  });
  const [selectedtournamentfilter, setselectedtournamentfilter] = useState("");
  const [displaytournament, setdisplaytournament] = useState([]);

  const loginData = useSelector((state) => state.auth.LoginDetails);

  useEffect(() => {
    setdisplayNftsUser(displayNfts.slice(0, displayCount));
  }, [displayNfts, displayCount]);

  useEffect(() => {
    displaynftsfunc();
    displaycountriesfunc();

    return () => {};
  }, []);

  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.scrollY + 400 >=
      document.body.scrollHeight
    ) {
      setTimeout(() => {
        loadmore();
      }, 1000);
    }
  };

  const loadmore = async (e) => {
    if (totaldisplayNfts <= limit) {
      return;
    } else {
      setemptyloader(false);
      setTimeout(() => {
        setlimit(limit + 28);
        setemptyloader(true);
      }, 1000);
    }
  };

  const loadMoreNfts = () => {
    setIsFetching(true);
    setTimeout(() => {
      setDisplayCount((prevCount) => prevCount + 16);
      setIsFetching(false);
    }, 1000); // Simulating network request delay
  };

  const lastNftElementRef = useCallback(
    (node) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreNfts();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching]
  );

  useEffect(() => {
    const content = document.getElementById("content");
    const handleScroll = () => {
      setScrollPosition(content.scrollTop);
    };

    content.addEventListener("scroll", handleScroll);
    return () => {
      content.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const handleselect = async (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    let newFilters = { ...filters };

    if (name == "country") {
      newFilters.countryId = value;
      setselectedcountryfilter(value);
      // setFilters({ ...filters, countryId: value });
    }
    if (name == "tournament") {
      newFilters.tournamentId = value;
      setselectedtournamentfilter(value);
    }
    if (name == "skills") {
      newFilters.skillId = value;
      setselectedskillfilter(value);
    }
    if (name == "tournament") {
      newFilters.tournamentId = value;
    }
    if (name == "sortby") {
      const sortingOptions = {
        1: "Price : Low to High",
        2: "Price : High to Low",
        3: "Age : Low to High",
        4: "Age : High to Low",
        5: "Rating : Low to High",
        6: "Rating : High to Low",
      };
      newFilters.sortBy = value;
      setselectedsort(sortingOptions[value]);
      console.log(sortingOptions[value], sortingOptions);
    }
    if (name == "searchData") {
      newFilters.searchQuery = value;
    }
    setFilters(newFilters);
    filterfunction(newFilters);
  };

  const filterfunction = async (newFilters) => {
    let tempfilterdata = [...filterdata];
    // console.log(newFilters,"newFilters");
    console.log(newFilters);
    if (newFilters.skillId) {
      tempfilterdata = tempfilterdata.filter(
        (item) =>
          item.skill.replace(/\s/g, "").toLowerCase() ==
          newFilters.skillId.replace(/\s/g, "").toLowerCase()
      );
    }
    if (newFilters.countryId) {
      tempfilterdata = tempfilterdata.filter(
        (item) =>
          item.country.replace(/\s/g, "").toLowerCase() ==
          newFilters.countryId.replace(/\s/g, "").toLowerCase()
      );
    }
    if (newFilters.tournamentId) {
      // tempfilterdata = tempfilterdata.filter(
      //   (item1) =>
      //     // console.log(item1.tournaments!=null)
      //     item1.tournaments!=null
      // )?.filter(
      //   (item2) =>(
      //     (item2.tournaments).some(
      //       (item3,key) =>((
      //         item3.tournament_name.replace(/\s/g, "").replace(/'/g, "").toLowerCase() ==
      //         newFilters.tournamentId.replace(/\s/g, "").replace(/'/g, "").toLowerCase()
      //     ))
      //   ))
      // )
      tempfilterdata = tempfilterdata.filter((item) => {
        return (
          item.tournaments &&
          item.tournaments.some(
            (tournament) =>
              tournament.tournament_short_name
                .replace(/\s/g, "")
                .replace(/'/g, "")
                .toLowerCase() ==
              newFilters.tournamentId
                .replace(/\s/g, "")
                .replace(/'/g, "")
                .toLowerCase()
          )
        );
      });
      console.log(tempfilterdata, "tempfilterdata123");
    }
    if (newFilters.sortBy) {
      if (newFilters.sortBy == 1) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) => parseFloat(a.price) - parseFloat(b.price)
        );
      }
      if (newFilters.sortBy == 2) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) => parseFloat(b.price) - parseFloat(a.price)
        );
      }
      if (newFilters.sortBy == 3) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) => parseFloat(a.age) - parseFloat(b.age)
        );
      }
      if (newFilters.sortBy == 4) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) => parseFloat(b.age) - parseFloat(a.age)
        );
      }

      if (newFilters.sortBy == 5) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) =>
            parseFloat(
              parseFloat(a.static_rating) + parseFloat(a.dynamic_rating)
            ) -
            parseFloat(
              parseFloat(b.static_rating) + parseFloat(b.dynamic_rating)
            )
        );
      }
      if (newFilters.sortBy == 6) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) =>
            parseFloat(
              parseFloat(b.static_rating) + parseFloat(b.dynamic_rating)
            ) -
            parseFloat(
              parseFloat(a.static_rating) + parseFloat(a.dynamic_rating)
            )
        );
      }
    }
    if (newFilters.searchQuery) {
      tempfilterdata = tempfilterdata.filter(
        (item) =>
          item.name
            .toLowerCase()
            .includes(newFilters.searchQuery.toLowerCase()) ||
          item.country
            .toLowerCase()
            .includes(newFilters.searchQuery.toLowerCase()) ||
          item.skill
            .toLowerCase()
            .includes(newFilters.searchQuery.toLowerCase())
      );
    }
    // console.log(tempfilterdata);
    settotaldisplayNfts(tempfilterdata.length);
    setdisplayNfts(tempfilterdata);
  };

  const displaynftsfunc = async () => {
    try {
      setloader(true);
      setDisplayCount(16);

      setselectedskillfilter("");
      setselectedcountryfilter("");
      setselectedskillfilter("");
      setselectedcountryfilter("");
      setselectedsort("");
      setselectedskillfilter("");
      setselectedcountryfilter("");
      setselectedtournamentfilter("");
      setselectedtournamentfilter("");
      document.getElementById("searchrefs").value = "";
      let res = await displayUserAllNfts(loginData.authToken);
      if (res.data.success) {
        setselectedcountryfilter("");
        setselectedskillfilter("");
        setselectedsort("");
        setdisplayNfts(res?.data?.data);
        settotaldisplayNfts(res.data.data.length);
        setFiltertotaldisplayNfts(res.data.data);
        setpostfilterdata(res.data.data);
        setfilterdata(res.data.data);
        setloader(false);
      } else {
        setloader(false);

        // toast.error(res.msg);
        //setdisplayNfts([]);
      }
    } catch (err) {
      setloader(false);

      console.log(err);
    }
  };

  const displaycountriesfunc = async () => {
    try {
      let res = await displayfilterparameterAction();
      if (res.success) {
        setdisplaycountries(res.data.country);
        setdisplayskills(res.data.skills);
        setdisplaytournament(res.data.tournament);
      } else {
        // toast.error(res.msg);
        setdisplaycountries([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <div id="content">
        <div className="container">
          <div className="total_nfts nfts my-5">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active me-3"
                  id="MyNfts-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#MyNfts"
                  type="button"
                  role="tab"
                  aria-controls="MyNfts"
                  aria-selected="true"
                >
                  My NFTs
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="BonusNfts-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#BonusNfts"
                  type="button"
                  role="tab"
                  aria-controls="BonusNfts"
                  aria-selected="false"
                >
                  Bonus NFTs
                </button>
              </li>
            </ul>

            <div className="tab-content mt-3" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="MyNfts"
                role="tabpanel"
                aria-labelledby="MyNfts-tab"
              >
                {/* ================ Tab frist start ======================= */}
                <div>
                  <div className="t_nftsHeader">
                    <div className="row">
                      <div className="col-lg-4 col-xl-3 mb-3">
                        <div className="fltrnft">
                          <input
                            type="search"
                            className="form-control nft-search"
                            placeholder="Search"
                            onChange={handleselect}
                            name="searchData"
                            // ref={searchref}
                            id="searchrefs"
                          />
                          <span className="icnsrch">
                            <IoSearchOutline />
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-9">
                        <div className="row justify-content-xl-end justify-content-lg-between">
                          <div className="col-6 col-lg-auto text-end mb-3">
                            <div className="d-flex justify-content-md-end">
                              <div className="dropdown  w-100">
                                <button
                                  className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <div className="d-flex justify-content-end align-items-center">
                                    <AiOutlineBars />
                                    <span className="ms-1">
                                      {selectedskillfilter == ""
                                        ? "Select Skill"
                                        : selectedskillfilter}
                                    </span>
                                  </div>
                                </button>
                                <ul
                                  className="dropdown-menu bg-dark"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2 text-capitalize"
                                      // onClick={() =>
                                      //   filterSkillAndCountryFunc(0, selectedcountryfilter)
                                      // }
                                      onClick={(e) =>
                                        handleselect(
                                          (e = {
                                            target: {
                                              name: "skills",
                                              value: "",
                                            },
                                          })
                                        )
                                      }
                                    >
                                      <span>
                                        <GiCricketBat />
                                      </span>
                                      <span className="ms-2">All</span>
                                    </a>
                                  </li>
                                  {displayskills.map((item, index) => {
                                    return (
                                      <li>
                                        <a
                                          className="dropdown-item btn_uni m-2 text-capitalize"
                                          onClick={(e) =>
                                            handleselect(
                                              (e = {
                                                target: {
                                                  name: "skills",
                                                  value: item.name,
                                                },
                                              })
                                            )
                                          }
                                        >
                                          <span>
                                            <img
                                              src={item.logo}
                                              alt={item.logo}
                                            />
                                          </span>
                                          <span className="ms-2">
                                            {item.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="col-6 col-lg-auto text-end mb-3">
                            <div className="d-flex justify-content-md-end">
                              <div className="dropdown  w-100">
                                <button
                                  className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <div className="d-flex justify-content-end align-items-center">
                                    <AiOutlineBars />
                                    <span className="ms-1">
                                      {selectedcountryfilter == ""
                                        ? "Select Country"
                                        : selectedcountryfilter}
                                    </span>
                                  </div>
                                </button>
                                <ul
                                  className="dropdown-menu bg-dark"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2 text-capitalize"
                                      onClick={(e) =>
                                        handleselect(
                                          (e = {
                                            target: {
                                              name: "country",
                                              value: "",
                                            },
                                          })
                                        )
                                      }
                                    >
                                      <span>
                                        {/* <img src="" className="img-fluid fltr_imgs" alt="" /> */}
                                      </span>
                                      <span className="ms-2">All</span>
                                    </a>
                                  </li>
                                  {displaycountries.map((item, index) => {
                                    return (
                                      <li>
                                        <a
                                          className="dropdown-item btn_uni m-2 text-capitalize"
                                          onClick={(e) =>
                                            handleselect(
                                              (e = {
                                                target: {
                                                  name: "country",
                                                  value: item.name,
                                                },
                                              })
                                            )
                                          }
                                        >
                                          <span>
                                            <img
                                              src={item?.flag_logo}
                                              className="img-fluid fltr_imgs"
                                              alt=""
                                            />
                                          </span>
                                          <span className="ms-2">
                                            {item.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="col-6 col-lg-auto text-end mb-3">
                            <div className="d-flex justify-content-md-end">
                              <div className="dropdown  w-100">
                                <button
                                  className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <div className="d-flex justify-content-end align-items-center">
                                    <AiOutlineBars />
                                    <span className="ms-1">
                                      {selectedtournamentfilter == ""
                                        ? "Select Tournament"
                                        : selectedtournamentfilter}
                                    </span>
                                  </div>
                                </button>
                                <ul
                                  className="dropdown-menu bg-dark"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2 text-capitalize"
                                      onClick={(e) =>
                                        handleselect(
                                          (e = {
                                            target: {
                                              name: "tournament",
                                              value: "",
                                            },
                                          })
                                        )
                                      }
                                    >
                                      <span className="ms-2">All</span>
                                    </a>
                                  </li>
                                  {displaytournament.map((item, index) => {
                                    return (
                                      <li>
                                        <a
                                          className="dropdown-item btn_uni m-2 text-capitalize"
                                          onClick={(e) =>
                                            handleselect(
                                              (e = {
                                                target: {
                                                  name: "tournament",
                                                  value: item.short_name,
                                                },
                                              })
                                            )
                                          }
                                        >
                                          <span className="ms-2">
                                            {item.short_name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="col-6 col-lg-auto text-end mb-3">
                            <div className="d-flex justify-content-md-end">
                              <div className="dropdown  w-100">
                                <button
                                  className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <div className="d-flex justify-content-end align-items-center">
                                    <AiOutlineBars />
                                    <span className="ms-1">
                                      {!selectedsort ? "Sort by" : selectedsort}
                                    </span>
                                  </div>
                                </button>
                                <ul
                                  className="dropdown-menu bg-dark"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2 text-capitalize"
                                      onClick={(e) =>
                                        handleselect(
                                          (e = {
                                            target: {
                                              name: "sortby",
                                              value: 1,
                                            },
                                          })
                                        )
                                      }
                                    >
                                      <span className="ms-2 text-light">
                                        Price : Low to High
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2 text-capitalize"
                                      onClick={(e) =>
                                        handleselect(
                                          (e = {
                                            target: {
                                              name: "sortby",
                                              value: 2,
                                            },
                                          })
                                        )
                                      }
                                    >
                                      <span className="ms-2">
                                        Price : High to Low
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2 text-capitalize"
                                      onClick={(e) =>
                                        handleselect(
                                          (e = {
                                            target: {
                                              name: "sortby",
                                              value: 3,
                                            },
                                          })
                                        )
                                      }
                                    >
                                      {/* <span><IoBowlingBaallOutline /></sspan> */}
                                      <span className="ms-2">
                                        Age : Low to High{" "}
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2 text-capitalize"
                                      onClick={(e) =>
                                        handleselect(
                                          (e = {
                                            target: {
                                              name: "sortby",
                                              value: 4,
                                            },
                                          })
                                        )
                                      }
                                    >
                                      {/* <span><MdSportsCricket /></span> */}
                                      <span className="ms-2">
                                        Age : High to Low
                                      </span>
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2 text-capitalize"
                                      onClick={(e) =>
                                        handleselect(
                                          (e = {
                                            target: {
                                              name: "sortby",
                                              value: 5,
                                            },
                                          })
                                        )
                                      }
                                    >
                                      {/* <span><IoBowlingBallOutline /></span> */}
                                      <span className="ms-2">
                                        Rating : Low to High
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item btn_uni m-2 text-capitalize"
                                      onClick={(e) =>
                                        handleselect(
                                          (e = {
                                            target: {
                                              name: "sortby",
                                              value: 6,
                                            },
                                          })
                                        )
                                      }
                                    >
                                      {/* <span><MdSportsCricket /></span> */}
                                      <span className="ms-2">
                                        Rating : High to Low
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="col-6 col-lg-auto text-end text-lg-end mb-3 d-flex align-items-md-start align-items-end">
                            <button
                              className="btn_uni  w-100"
                              onClick={() => displaynftsfunc()}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 mt-lg-0">
                      <p className="m-0">Total NFTs {totaldisplayNfts} </p>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show active text-white"
                    id="autopick"
                    role="tabpanel"
                    aria-labelledby="autopick-tab"
                  >
                    {/* Devendra */}
                    <div className="row">
                      {loader == true ? (
                        <div className="d-flex justify-content-center align-items-center h-100 mt-5 pt-5">
                          <div className="my-5">
                            <CustomLoader />
                          </div>
                        </div>
                      ) : displayNftsUser.length > 0 ? (
                        displayNftsUser?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="col-xl-3 col-lg-4 col-md-6 mt-3"
                              ref={lastNftElementRef}
                            >
                              <div className="position-relative">
                                <div className="text-center">
                                  <div className="fs-6 t-price">
                                    <small className="position-absolute bonus_tag">
                                      {item?.category}
                                    </small>
                                    <span>${item.price}</span>

                                    <div
                                      className="bonus_tag mt-2 small"
                                      style={{ width: "fit-content" }}
                                    >
                                      NFT IDs {item?.user_nft_id}
                                    </div>
                                  </div>
                                  <img
                                    src={item.image}
                                    alt=""
                                    className="cricketcard"
                                    onError={(e) => {
                                      e.target.src =
                                        "https://web3sports.s3.ap-south-1.amazonaws.com/Players/dummy.png";
                                    }}
                                  />

                                  <div className="cardDetail">
                                    <div className="cardInnerDetail">
                                      <small className="text-primary fw-medium">
                                        {Math?.trunc(
                                          parseInt(item?.dynamic_rating) +
                                            parseInt(item?.static_rating)
                                        )}
                                        /100
                                      </small>
                                      <h5
                                        className="mb-0"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title={
                                          item.name?.length < "15"
                                            ? ""
                                            : item.name
                                        }
                                      >
                                        {item.name?.length > "15"
                                          ? item.name?.slice(0, 14) + ".."
                                          : item.name}
                                      </h5>
                                      <small className="text-primary fw-medium">
                                        {item.skill}
                                      </small>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <small className="text-uppercase">
                                        Age {item.age}
                                      </small>
                                      <small className="text-uppercase">
                                        <span className="nft_flag_img">
                                          <img src={item?.flag_logo} />
                                          <span>{item.country}</span>
                                        </span>
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="h-100 mt-5">
                          <div className="h-75">
                            <div className="text-center">
                              <img
                                src="ds/nodatapreview.png"
                                alt=""
                                className="img-fluid"
                                style={{ width: "200px" }}
                              />
                            </div>
                            <h4 className="mt-3 text-center">
                              {" "}
                              Oops! no NFT found <br />
                              Please Purchase a Club!
                            </h4>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {(isFetching && displayCount < displayNftsUser.length) ||
                  (displayCount > 16 &&
                    displayNftsUser.length != displayCount) ? (
                      displayCount< displayNfts.length ?
                    <CustomLoader />:""
                  ) : (
                    ""
                  )}
                  {console.log(isFetching,displayCount,displayNfts.length , (isFetching && displayCount < displayNftsUser.length) ||
                  (displayCount > 16 &&
                    displayNftsUser.length != displayCount),(isFetching && displayCount < displayNftsUser.length) )}

                  {emptyloader == true ? (
                    ""
                  ) : (
                    <div className="d-flex justify-content-center align-items-center h-100 mt-5 pt-5">
                      <div className="my-5">
                        <CustomLoader />
                      </div>
                    </div>
                  )}
                </div>
                {/* ================ Tab frist end ======================= */}
              </div>
              <div
                className="tab-pane fade"
                id="BonusNfts"
                role="tabpanel"
                aria-labelledby="BonusNfts-tab"
              >
                {/* ====================Tab second start=================== */}
                <BonusNfts />
                {/* ===================Tab second start==================== */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
