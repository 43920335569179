const { useWalletClient, useAccount } = require("wagmi");

const Web3ModalProvider = async () => {
  // const _useWeb3ModalProvider = useWalletClient()
  // return _useWeb3ModalProvider.data;

  const _useAccount = useAccount();
  const data = await _useAccount?.connector?.getProvider();
  console.log({ kkk: data })
  return data;
};
export default Web3ModalProvider;
