import React, { useEffect, useState } from "react";
import { IoSettings } from "react-icons/io5";
import { TbColorPicker } from "react-icons/tb";
import { FaFilter } from "react-icons/fa";
import { TbCricket } from "react-icons/tb";
import { IoBowlingBallOutline } from "react-icons/io5";
import { MdSportsCricket } from "react-icons/md";
import { CiFlag1 } from "react-icons/ci";
import { GiVibratingShield } from "react-icons/gi";
import Header from "../layout/header/Header";
import { AiOutlineBars } from "react-icons/ai";
import { IoSearchOutline } from "react-icons/io5";
import SidebarPro from "../dashboard/sidebar/Sidebar";
import DashboardHeader from "../dashboard/header/Header";
import CustomizeNft from "./CustomizeNft";
import AutoPickNft from "./AutoPickNft";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config/config";

export default function Nfts(props) {
  // const [collapsed, setCollapsed] = useState(false)
  const { collapsed, setCollapsed } = props;

  const [AutoPickPlayers, setAutoPickPlayers] = useState([]);
  const [autopick, setAutopick] = useState("d-block");
  const navigate = useNavigate();

  const { id } = useParams();
  console.log(id, "id");
  useEffect(() => {
    if (!id) {
      navigate(`${config.baseUrl}myclub`);
      return;
    }
    return () => {};
  }, []);

  return (
    <>
      <div className="dashboard">
        <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className={collapsed ? "marginL25" : "marginL250"} id="outlet">
          <div className="container">
            <DashboardHeader
              className={collapsed ? "marginL25" : "marginL250"}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />

            <div className="nfts mt-5">
              <ul className="nav nav-tabs" id="myTab_perent" role="tablist">
                <li className="nav-item perent" role="presentation">
                  <button
                    className="me-3 active"
                    id="autopick-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#autopick"
                    type="button"
                    role="tab"
                    aria-controls="autopick"
                    aria-selected="false"
                  >
                    <div className="nfts_btn">
                      <span className="me-2 icon">
                        <TbColorPicker />
                      </span>
                      Auto Pick
                    </div>
                  </button>
                </li>

                <li className="nav-item perent" role="presentation">
                  <button
                    className="me-3"
                    id="customize-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#customize"
                    type="button"
                    role="tab"
                    aria-controls="customize"
                    aria-selected="true"
                  >
                    <div className="nfts_btn">
                      <span className="me-2 icon">
                        <IoSettings />
                      </span>
                      Customize
                    </div>
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade text-white"
                  id="customize"
                  role="tabpanel"
                  aria-labelledby="customize-tab"
                >
                  <CustomizeNft AutoPickPlayers={AutoPickPlayers} />
                </div>

                <div
                  className="tab-pane fade show active text-white"
                  id="autopick"
                  role="tabpanel"
                  aria-labelledby="autopick-tab"
                >
                  <AutoPickNft setAutoPickPlayers={setAutoPickPlayers} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
