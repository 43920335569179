
import { useEffect, useState } from "react"
import { Modal, Row, Button } from "react-bootstrap"
import { Link } from "react-router-dom"

export const TermsconditionModal = ({ isModal, handleCloseModal, termsConditionAccept }) => {
    const [isChecked, setIsChecked] = useState(false);
    return (
        <Modal
            show={isModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={false}

        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="p-0">
                <h3> <img src="terms.png" style={{ width: 25, height: 25 }} alt="" /> Terms & Conditions</h3>

                <p className="mt-3" style={{ fontSize: 14 }}>Select an NFT of your choice, and as a bonus, you'll receive all legendary players included within the same package.</p>

                <p style={{ fontSize: 14 }}>After purchasing the plan, completing the checkout process is required to finalize your club. Once the checkout is complete, all your NFTs will be available in the 'My NFT' and 'Bonus NFT' section.</p>

                <p style={{ fontSize: 14 }}>Please note, agreeing to the terms & conditions is mandatory to proceed.</p>

                <p style={{ fontSize: 14 }}><input type="checkbox" onClick={() => setIsChecked(!isChecked)} /> I have read and agree to the Terms & Conditions.</p>
                <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                        <Button
                            className="btn_uni px-5 m-0"
                            disabled={!isChecked}
                            onClick={() => termsConditionAccept()}
                        >
                            Accept & Proceed
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}