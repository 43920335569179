import React from "react";
import { GiReceiveMoney } from "react-icons/gi";
import { RiNftLine } from "react-icons/ri";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { getDashboardHistory } from "../../Action/user.action";
import config from "../../config/config";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function Home() {
  const loginData = useSelector((state) => state.auth.LoginDetails);
  const [dashboardStatics, setDashboardStatics] = useState([]);

  useEffect(() => {
    getStatics();
  }, []);

  const getStatics = async () => {
    try {
      let res = await getDashboardHistory({
        token: loginData.authToken,
      });
      if (res.data.success) {
        setDashboardStatics([res.data.data]);
      } else {
        setDashboardStatics([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="dashboard_home">
        {dashboardStatics.length > 0 &&
          dashboardStatics.map((item, index) => (
            <div className="row">
              <div className="col-lg-4 mt-3">
                <div className="box">
                  <div className="d-flex justify-content-center align-items-center">
                    {/* <div className="icon"><GiReceiveMoney /></div> */}
                    <div>
                      <img
                        src="dashboard/package.png"
                        alt=""
                        className="img-fluid dashboard_icn"
                      />
                    </div>
                  </div>
                  <div className="ms-3">
                    <h4 className="text-start m-0 fs-5">
                      {item.total_package_purchase}
                    </h4>
                    <p className="text-start m-0">My Clubs</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="box">
                  <div className="">
                    {/* <div className="icon"><GiReceiveMoney /></div> */}
                    <div>
                      <img
                        src="dashboard/nft.png"
                        alt=""
                        className="img-fluid dashboard_icn"
                      />
                    </div>
                  </div>
                  <div className="ms-3">
                    <h4 className="text-start m-0 fs-5">{item.total_user_nft}</h4>
                    <p className="text-start m-0">Total Nfts</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mt-3">
                <div className="box">
                  <div className="d-flex justify-content-center align-items-center">
                    {/* <div className="icon"><MdOutlineAccountBalanceWallet /></div> */}
                    <div>
                      <img
                        src="dashboard/bonus.png"
                        alt=""
                        className="img-fluid dashboard_icn"
                      />
                    </div>
                  </div>
                  <div className="ms-3">
                    <h4 className="text-start m-0 fs-5">{item.total_bonus_nft}</h4>
                    <p className="text-start m-0">Bonus Nft</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mt-3">
                <div className="box">
                  <div className="d-flex justify-content-center align-items-center">
                    {/* <div className="icon"><RiNftLine /></div> */}
                    <div>
                      <img
                        src="dashboard/balance.png"
                        alt=""
                        className="img-fluid dashboard_icn"
                      />
                    </div>
                  </div>
                  <div className="ms-3">
                    <h4 className="text-start m-0 fs-5">
                      {item.refferal_earning} MNT
                    </h4>
                    <p className="text-start m-0">My Referral Balance</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mt-3">
                <div className="box">
                  <div className="d-flex justify-content-center align-items-center">
                    {/* <div className="icon"><GiReceiveMoney /></div> */}
                    <div>
                      <img
                        src="dashboard/earning.png"
                        alt=""
                        className="img-fluid dashboard_icn"
                      />
                    </div>
                  </div>
                  <div className="ms-3">
                    <h4 className="text-start m-0 fs-5">
                      {item.friends_earning} MNT
                    </h4>
                    <p className="text-start m-0">Friends Referral Earning</p>
                  </div>
                </div>
              </div>



              <div className="col-lg-4 mt-3">
                <div className="box">
                  <div className="d-flex justify-content-center align-items-center">
                    {/* <div className="icon"><GiReceiveMoney /></div> */}
                    <div>
                      <img
                        src="dashboard/earning.png"
                        alt=""
                        className="img-fluid dashboard_icn"
                      />
                    </div>
                  </div>
                  <div className="ms-3">
                    <h4 className="text-start m-0 fs-5">
                      {item.total_royalty} MNT
                    </h4>
                    <p className="text-start m-0">Contest Royalty Earning</p>
                  </div>
                </div>
              </div>


              {/* <div className="col-lg-4 mt-3">
     <div className="box">
         <div className="d-flex justify-content-center align-items-center">
             <div className="icon"><MdOutlineAccountBalanceWallet /></div>
         </div>
         <div className="ms-3">
             <h4 className="text-start m-0">$1025 MNT</h4>
             <p className="text-start m-0">Sports Balance</p>
         </div>
     </div>
 </div> */}
            </div>
          ))}
      </div>
    </>
  );
}
