import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  autopickplanforuserAction,
  displayfilterparameterAction,
  displayNftsFilterCountryAction,
  getnftofanyuserpackageAction,
  getplanpurchaseconfirmationstatusAction,
} from "../../Action/user.action";
import { useSelector } from "react-redux";
import config from "../../config/config";
import { IoSearchOutline } from "react-icons/io5";
import { AiOutlineBars } from "react-icons/ai";
import { TbCricket } from "react-icons/tb";
import { IoBowlingBallOutline } from "react-icons/io5";
import { MdSportsCricket } from "react-icons/md";
import CustomLoader from "./CustomLoader";
import { GiCricketBat } from "react-icons/gi";
import { FaListUl } from "react-icons/fa";

const PackageNfts = () => {
  const selectedskillfilterRef = useRef("");
  const selectedcountryfilterRef = useRef("");
  const [displaycountries, setdisplaycountries] = useState([]);
  const [displayskills, setdisplayskills] = useState([]);
  const [displaytournament, setdisplaytournament] = useState([]);

  const searchref = useRef("");
  const [selectedskillfilter, setselectedskillfilter] = useState("");
  const [selectedcountryfilter, setselectedcountryfilter] = useState("");
  const [selectedtournamentfilter, setselectedtournamentfilter] = useState("");

  const [selectedsort, setselectedsort] = useState("");
  const [postfilterdata, setpostfilterdata] = useState([]);
  const [loader, setloader] = useState(false);
  const [filterdata, setfilterdata] = useState([]);

  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [totaldisplayNfts, settotaldisplayNfts] = useState(0);

  const [filters, setFilters] = useState({
    skillId: null,
    countryId: null,
    tournamentId: "",
    sortBy: "",
    searchQuery: "",
  });

  useEffect(() => {
    setTimeout(() => {
      if (
        !location.state?.packagePurchasedId ||
        location.state?.packagePurchasedId == "" ||
        !location.state ||
        location.state == ""
      ) {
        // window.location.href = `${config.baseUrl}nfts`
        navigate(`${config.baseUrl}myclub`);
      } else {
        getnftdetails();
        displaycountriesfunc();
      }
    }, 500);
    return () => {};
  }, []);

  const loginData = useSelector((state) => state.auth.LoginDetails); // (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));

  const handlereset = () =>{
    if (selectedcountryfilter=="" && selectedskillfilter== "" && selectedtournamentfilter== "" && selectedsort=="" && document.getElementById("searchrefs").value == ""){
      return;
    }
    else {
      getnftdetails();
    }
  }

  const getnftdetails = async () => {
    try {
      setloader(true);
      let res = await getnftofanyuserpackageAction({
        authToken: loginData.authToken,
        package_id: location.state?.packagePurchasedId,
      });
      if (res.data.success) {
        setloader(false);
        setdata(res.data.data);
        setfilterdata(res.data.data);
        settotaldisplayNfts(res.data.data.length);
        setselectedcountryfilter("");
        setselectedskillfilter("");
        setselectedtournamentfilter("");
        setselectedsort("");
        filters.searchQuery=""
        document.getElementById("searchrefs").value = "";
        setFilters({
          skillId: null,
          countryId: null,
          tournamentId: "",
          sortBy: "",
          searchQuery: "",
        });
      } else {
        setloader(false);
        setdata([]);
        setfilterdata([]);
      }
    } catch (error) {
      setloader(false);
      console.log(error);
    }
  };

  const displaycountriesfunc = async () => {
    try {
      let res = await displayfilterparameterAction();
      if (res.success) {
        setdisplaycountries(res.data.country);
        setdisplaytournament(res.data.tournament);
        setdisplayskills(res.data.skills);
      } else {
        setdisplaycountries([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.state?.packagePurchasedId) {
    }
    return () => {};
  }, []);

  const handleselect = async (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    let newFilters = { ...filters };

    if (name == "country") {
      newFilters.countryId = value;
      setselectedcountryfilter(value);
      // setFilters({ ...filters, countryId: value });
    }
    if (name == "tournament") {
      newFilters.tournamentId = value;
      setselectedtournamentfilter(value);
    }
    if (name == "skills") {
      newFilters.skillId = value;
      setselectedskillfilter(value);
    }
    if (name == "tournament") {
      newFilters.tournamentId = value;
    }
    if (name == "sortby") {
      const sortingOptions = {
        1: "Price : Low to High",
        2: "Price : High to Low",
        3: "Age : Low to High",
        4: "Age : High to Low",
        5: "Rating : Low to High",
        6: "Rating : High to Low",
      };
      newFilters.sortBy = value;
      setselectedsort(sortingOptions[value]);
      console.log(sortingOptions[value], sortingOptions);
    }
    if (name == "searchData") {
      newFilters.searchQuery = value;
    }
    setFilters(newFilters);
    filterfunction(newFilters);
  };

  const filterfunction = async (newFilters) => {
    let tempfilterdata = [...filterdata];
    // console.log(newFilters,"newFilters");
    if (newFilters.skillId) {
      tempfilterdata = tempfilterdata.filter(
        (item) =>
          item.skill.replace(/\s/g, "").toLowerCase() ==
          newFilters.skillId.replace(/\s/g, "").toLowerCase()
      );
    }
    if (newFilters.countryId) {
      tempfilterdata = tempfilterdata.filter(
        (item) =>
          item.country.replace(/\s/g, "").toLowerCase() ==
          newFilters.countryId.replace(/\s/g, "").toLowerCase()
      );
    }
    if (newFilters.tournamentId) {
      // tempfilterdata = tempfilterdata.filter(
      //   (item1) =>
      //     // console.log(item1.tournaments!=null)
      //     item1.tournaments!=null
      // )?.filter(
      //   (item2) =>(
      //     (item2.tournaments).some(
      //       (item3,key) =>((
      //         item3.tournament_name.replace(/\s/g, "").replace(/'/g, "").toLowerCase() ==
      //         newFilters.tournamentId.replace(/\s/g, "").replace(/'/g, "").toLowerCase()
      //     ))
      //   ))
      // )
      tempfilterdata = tempfilterdata.filter((item) => {
        return (
          item.tournaments &&
          item.tournaments.some(
            (tournament) =>
              tournament.tournament_short_name
                .replace(/\s/g, "")
                .replace(/'/g, "")
                .toLowerCase() ==
              newFilters.tournamentId
                .replace(/\s/g, "")
                .replace(/'/g, "")
                .toLowerCase()
          )
        );
      });
      console.log(tempfilterdata, "tempfilterdata123");
    }
    if (newFilters.sortBy) {
      if (newFilters.sortBy == 1) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) => parseFloat(a.price) - parseFloat(b.price)
        );
      }
      if (newFilters.sortBy == 2) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) => parseFloat(b.price) - parseFloat(a.price)
        );
      }
      if (newFilters.sortBy == 3) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) => parseFloat(a.age) - parseFloat(b.age)
        );
      }
      if (newFilters.sortBy == 4) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) => parseFloat(b.age) - parseFloat(a.age)
        );
      }

      if (newFilters.sortBy == 5) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) =>
            parseFloat(
              parseFloat(a.static_rating) + parseFloat(a.dynamic_rating)
            ) -
            parseFloat(
              parseFloat(b.static_rating) + parseFloat(b.dynamic_rating)
            )
        );
      }
      if (newFilters.sortBy == 6) {
        tempfilterdata = tempfilterdata.sort(
          (a, b) =>
            parseFloat(
              parseFloat(b.static_rating) + parseFloat(b.dynamic_rating)
            ) -
            parseFloat(
              parseFloat(a.static_rating) + parseFloat(a.dynamic_rating)
            )
        );
      }
    }
    if (newFilters.searchQuery) {
      tempfilterdata = tempfilterdata.filter(
        (item) =>
          item.name
            .toLowerCase()
            .includes(newFilters.searchQuery.toLowerCase()) ||
          item.country
            .toLowerCase()
            .includes(newFilters.searchQuery.toLowerCase()) ||
          item.skill
            .toLowerCase()
            .includes(newFilters.searchQuery.toLowerCase())
      );
    }
    // console.log(tempfilterdata);
    settotaldisplayNfts(tempfilterdata.length);
    setdata(tempfilterdata);
  };

  return (
    <div className="row mt-4">
      <div className="t_nftsHeader">
        <div className="row">
          <div className="col-lg-2 px-1">
            <div className="fltrnft">
              <input
                type="search"
                className="form-control nft-search"
                placeholder="Search"
                onChange={handleselect}
                name="searchData"
                ref={searchref}
                id="searchrefs"
              />
              <span className="icnsrch">
                <IoSearchOutline />
              </span>
            </div>
          </div>
          <div className="col-lg-10 mt-2 mt-md-3 mt-lg-0">
            <div className="row justify-content-end">
              <div className="col-6 col-lg-3 text-end mb-3 px-2">
                <div className="d-flex justify-content-md-end">
                  <div className="dropdown  w-100">
                    <button
                      className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="d-flex justify-content-end align-items-center">
                        <AiOutlineBars />
                        <span className="ms-1">
                          {selectedskillfilter == ""
                            ? "Select Skills"
                            : selectedskillfilter}
                        </span>
                      </div>
                    </button>
                    <ul
                      className="dropdown-menu bg-dark"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className="dropdown-item btn_uni m-2 text-capitalize"
                          // onClick={() =>
                          //   filterSkillAndCountryFunc(0, selectedcountryfilter)
                          // }
                          onClick={(e) =>
                            handleselect(
                              (e = {
                                target: {
                                  name: "skills",
                                  value: "",
                                },
                              })
                            )
                          }
                        >
                          <span>
                            <GiCricketBat />
                          </span>
                          <span className="ms-2">All</span>
                        </a>
                      </li>
                      {displayskills.map((item, index) => {
                        return (
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={(e) =>
                                handleselect(
                                  (e = {
                                    target: {
                                      name: "skills",
                                      value: item.name,
                                    },
                                  })
                                )
                              }
                            >
                              <span>
                                <img src={item.logo} alt={item.logo} />
                              </span>
                              <span className="ms-2">{item.name}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-6 col-lg-3 text-end mb-3 px-2">
                <div className="d-flex justify-content-md-end">
                  <div className="dropdown  w-100">
                    <button
                      className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="d-flex justify-content-end align-items-center">
                        <AiOutlineBars />
                        <span className="ms-1">
                          {selectedcountryfilter == ""
                            ? "Select Country"
                            : selectedcountryfilter}
                        </span>
                      </div>
                    </button>
                    <ul
                      className="dropdown-menu bg-dark"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className="dropdown-item btn_uni m-2 text-capitalize"
                          onClick={(e) =>
                            handleselect(
                              (e = {
                                target: {
                                  name: "country",
                                  value: "",
                                },
                              })
                            )
                          }
                        >
                          <span>
                            {/* <img src="" className="img-fluid fltr_imgs" alt="" /> */}
                          </span>
                          <span className="ms-2">All</span>
                        </a>
                      </li>
                      {displaycountries.map((item, index) => {
                        return (
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={(e) =>
                                handleselect(
                                  (e = {
                                    target: {
                                      name: "country",
                                      value: item.name,
                                    },
                                  })
                                )
                              }
                            >
                              <span>
                                <img
                                  src={item?.flag_logo}
                                  className="img-fluid fltr_imgs"
                                  alt=""
                                />
                              </span>
                              <span className="ms-2">{item.name}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-6 col-lg-3 text-end mb-3 px-2">
                <div className="d-flex justify-content-md-end">
                  <div className="dropdown  w-100">
                    <button
                      className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="d-flex justify-content-end align-items-center">
                        <AiOutlineBars />
                        <span className="ms-1">
                          {selectedtournamentfilter == ""
                            ? "Select Tournament"
                            : selectedtournamentfilter}
                        </span>
                      </div>
                    </button>
                    <ul
                      className="dropdown-menu bg-dark"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className="dropdown-item btn_uni m-2 text-capitalize"
                          onClick={(e) =>
                            handleselect(
                              (e = {
                                target: {
                                  name: "tournament",
                                  value: "",
                                },
                              })
                            )
                          }
                        >
                          <span>
                            {/* <img src="" className="img-fluid fltr_imgs" alt="" /> */}
                          </span>
                          <span className="ms-2">All</span>
                        </a>
                      </li>
                      {displaytournament.map((item, index) => {
                        return (
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={(e) =>
                                handleselect(
                                  (e = {
                                    target: {
                                      name: "tournament",
                                      value: item.short_name,
                                    },
                                  })
                                )
                              }
                            >
                              <span className="ms-2">{item.short_name}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-6 col-lg-2 text-end mb-3 px-2">
                <div className="d-flex justify-content-md-end">
                  <div className="dropdown  w-100">
                    <button
                      className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="d-flex justify-content-end align-items-center">
                        <AiOutlineBars />
                        <span className="ms-1">
                          {!selectedsort ? "Sort by" : selectedsort}
                        </span>
                      </div>
                    </button>
                    <ul
                      className="dropdown-menu bg-dark"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className="dropdown-item btn_uni m-2 text-capitalize"
                          onClick={(e) =>
                            handleselect(
                              (e = {
                                target: {
                                  name: "sortby",
                                  value: 1,
                                },
                              })
                            )
                          }
                        >
                          <span className="ms-2 text-light">
                            Price : Low to High
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item btn_uni m-2 text-capitalize"
                          onClick={(e) =>
                            handleselect(
                              (e = {
                                target: {
                                  name: "sortby",
                                  value: 2,
                                },
                              })
                            )
                          }
                        >
                          <span className="ms-2">Price : High to Low</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item btn_uni m-2 text-capitalize"
                          onClick={(e) =>
                            handleselect(
                              (e = {
                                target: {
                                  name: "sortby",
                                  value: 3,
                                },
                              })
                            )
                          }
                        >
                          {/* <span><IoBowlingBaallOutline /></sspan> */}
                          <span className="ms-2">Age : Low to High </span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item btn_uni m-2 text-capitalize"
                          onClick={(e) =>
                            handleselect(
                              (e = {
                                target: {
                                  name: "sortby",
                                  value: 4,
                                },
                              })
                            )
                          }
                        >
                          {/* <span><MdSportsCricket /></span> */}
                          <span className="ms-2">Age : High to Low</span>
                        </a>
                      </li>

                      <li>
                        <a
                          className="dropdown-item btn_uni m-2 text-capitalize"
                          onClick={(e) =>
                            handleselect(
                              (e = {
                                target: {
                                  name: "sortby",
                                  value: 5,
                                },
                              })
                            )
                          }
                        >
                          {/* <span><IoBowlingBallOutline /></span> */}
                          <span className="ms-2">Rating : Low to High</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item btn_uni m-2 text-capitalize"
                          onClick={(e) =>
                            handleselect(
                              (e = {
                                target: {
                                  name: "sortby",
                                  value: 6,
                                },
                              })
                            )
                          }
                        >
                          {/* <span><MdSportsCricket /></span> */}
                          <span className="ms-2">Rating : High to Low</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-6 col-lg-1 text-end text-lg-end mb-3 d-flex align-items-md-start align-items-end px-2">
                <button
                  className="btn_uni  w-100"
                  onClick={() => handlereset()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 mt-lg-0">
          <p className="m-0">Total NFTs {totaldisplayNfts} </p>
        </div>
      </div>
      {loader == true ? (
        <div className="d-flex justify-content-center">
          <CustomLoader />
        </div>
      ) : (
        <>
          {" "}
          {data.length > 0 ? (
            data.map((item, index) => {
              return (
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 mt-3">
                  <div className="position-relative">
                    <div className="text-center">
                      {/* {item.bonus_nft && (
                        <small className="position-absolute bonus_tag">
                          BONUS
                        </small>
                      )} */}
                      <div className="fs-6 t-price">
                        <small className="position-absolute bonus_tag">
                          {item?.category}
                        </small>

                        <span>${item.price}</span>
                        <div
                        className=" bonus_tag  mt-2 small"
                        style={{ width: "fit-content" }}
                      >
                        NFT IDs {item?.user_nft_id}
                      </div>
                      </div>
                      
                      <img
                        src={item.image}
                        alt=""
                        className="cricketcard"
                        onError={(e) => {
                          e.target.src =
                            "https://web3sports.s3.ap-south-1.amazonaws.com/Players/dummy.png";
                        }}
                      />
                      
                      <div className="cardDetail">
                        <div className="cardInnerDetail">
                          <small className="text-primary fw-medium">
                            {/* {item.static_rating}/100 */}
                            {Math?.trunc(
                              parseInt(item?.dynamic_rating) +
                                parseInt(item?.static_rating)
                            )}
                            /100
                          </small>
                          <h5
                            className="mb-0"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={item.name?.length < "15" ? "" : item.name}
                          >
                            {item.name?.length > "15"
                              ? item.name?.slice(0, 14) + ".."
                              : item.name}
                          </h5>
                          <small className="text-primary fw-medium">
                            {item.skill}
                          </small>
                        </div>
                        <div className="d-flex justify-content-between">
                          <small className="text-uppercase">
                            Age {item.age}
                          </small>
                          <small className="text-uppercase">
                            <span className="nft_flag_img">
                              <img
                                src={item?.flag_logo}
                                className="img-fluid"
                              />
                              <span>{item.country}</span>
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="container h-100 mt-5">
              <div className="container h-100 mt-5">
                <div className="h-75">
                  <div className="text-center">
                    <img
                      src="ds/nodatapreview.png"
                      alt=""
                      className="img-fluid"
                      style={{ width: "200px" }}
                    />
                  </div>
                  <h4 className="mt-3 text-center">Sorry, no results found!</h4>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PackageNfts;
