import React from "react";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";

export default function CookiePolicy() {
    return (
        <>
            <section className="py-5">
                <div className="container">

                    <h2 className="mb-5">Cookie Policy</h2>

                    <p>This Cookies Policy explains what Cookies are and how We use them. You should read this policy to understand what type of cookies We use, or the information We collect using Cookies and how that information is used.</p>

                    <p>Cookies do not typically contain any information that personally identifies a user. Still, personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.</p>

                    <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.</p>

                    <h4>Interpretation and Definitions</h4>

                    <h6 className="nt-4">Interpretation</h6>

                    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                    <h6>Definitions</h6>

                    <h5>For this Cookies Policy:</h5>

                    <p><b className="fw-bold">Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to Mr Mint, Office No. 601, 602, 6th Floor, Princes Business Skyline, Block No. 32 & 33, PU-3, Commercial Scheme No. 54, AB Road, Indore, M.P.</p>

                    <p><b className="fw-bold">Cookies</b> means small files placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.</p>

                    <p><b className="fw-bold">Website</b> refers to SportsMint, accessible from https://sportsmint.io/</p>

                    <p><b className="fw-bold">You</b> means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.</p>

                    <h4>The use of the Cookies</h4>

                    <h6>Type of Cookies We Use</h6>

                    <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.</p>

                    <p>We use both session and persistent Cookies for the purposes set out below:</p>

                    <h6>Necessary / Essential Cookies</h6>

                    <p>Type: Session Cookies</p>

                    <p>Administered by: Us</p>

                    <p>Purpose: These Cookies are essential to providing you with services available through the Website and enabling you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that you have asked for cannot be provided, and we only use these Cookies to provide you with those services.</p>

                    <h6>Functionality Cookies</h6>

                    <p>Type: Persistent Cookies</p>

                    <p>Administered by: Us</p>

                    <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>

                    <h6>Your Choices Regarding Cookies</h6>

                    <p>If you prefer to avoid using Cookies on the Website, you must disable the Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option to prevent using Cookies at any time.
                        If you do not accept Our Cookies, you may experience some inconvenience when using the Website, and some features may not function properly.</p>

                    <p>If you'd like to delete Cookies or instruct your web browser to delete or refuse cookies, please visit your web browser's help pages.</p>

                    <p>If you prefer to avoid using Cookies on the Website, you must disable the Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option to prevent using Cookies at any time.</p>

                    <p>If you do not accept Our Cookies, you may experience some inconvenience when using the Website, and some features may not function properly.</p>

                    <p>If you'd like to delete Cookies or instruct your web browser to delete or refuse cookies, please visit your web browser's help pages.</p>

                    <h6>Contact Us</h6>

                    <p>If you have any questions about this Cookies Policy, You can contact us:</p>
                    <span className="text-white">By email : <a href="info@sportsmint.io">info@sportsmint.io</a></span>
                </div>
            </section>
        </>
    )
}