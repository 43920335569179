import React, { useEffect } from "react";
import { Bounce, Zoom } from 'react-awesome-reveal'

export default function OurFeatures() {

    // match height code start

    const HeightMatch = () => {
        const hMtch = document.getElementsByClassName('h_match_ourF')
        const ary = []
        for (let i = 0; i < hMtch.length; i++) {
            ary.push(hMtch[i].offsetHeight)
        }
        let max = Math.max(...ary);

        for (let i = 0; i < hMtch.length; i++) {
            hMtch[i].style.height = max + "px"
        }
    }

    useEffect(() => {
        return HeightMatch()
    }, [])

    // match height code start

    return (
        <>
            <section className="our_features_banner">
                <div className="container">
                    <div className="our_features mt-4 mt-md-0">
                       
                            <h2 className="hd">Our Offerings</h2>
                            <p className="text-center">Our offerings to enhance your journey of fantasy sports gaming</p>
                    

                        <div className="row">
                            <div className="col-md-6 col-lg-3 mt-3 mt-lg-0">
                               
                                    <div className="box" >
                                        <img src="cardImg/Rectangle.png" className="img-fluid" alt="" />
                                        <h4 className="text-center">Contest</h4>
                                        <p className="h_match_ourF">Participate in thrilling fantasy sports contests.</p>
                                    </div>
                               
                            </div>
                            <div className="col-md-6 col-lg-3 mt-3 mt-lg-0">
                               
                                    <div className="box" >
                                        <img src="cardImg/Rectangle2.png" className="img-fluid" alt="" />
                                        <h4 className="text-center">Challenger Ground</h4>
                                        <p className="h_match_ourF">Be a part of one-on-one battles and group vs group showdowns.</p>
                                    </div>
                               
                            </div>
                            <div className="col-md-6 col-lg-3 mt-3 mt-lg-0">
                               
                                    <div className="box">
                                        <img src="marketplaceNft.png" className="img-fluid" alt="" />
                                        <h4 className="text-center">NFT Marketplace</h4>
                                        <p className="h_match_ourF">Explore NFT Marketplace to buy, sell, and rent out players' NFTs.</p>
                                    </div>
                               
                            </div>
                            <div className="col-md-6 col-lg-3 mt-3 mt-lg-0">
                               
                                    <div className="box">
                                        <img src="new_imgs/marketplace1.png" className="img-fluid" alt="" />
                                        <h4 className="text-center">AI Implementation</h4>
                                        <p className="h_match_ourF">Quick assistance by AI and create a team as per the player's past performance.
                                        </p>
                                    </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}