import React, { useEffect, useState } from "react";
import '../../../assets/style/AboutUs.css'
import Footer from "../../layout/footer/Footer";
import Header from "../../layout/header/Header";
import Faqs from "../faq's/Faq's";
import { Zoom, Bounce } from 'react-awesome-reveal'

import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";



// import required modules
import { Pagination } from 'swiper/modules';

const AboutUs = () => {
    const [listPackage, setListPackage] = useState([]);
    // match height code start

    const HeightMatch = () => {
        const hMtch = document.getElementsByClassName('h_match')
        const ary = []
        for (let i = 0; i < hMtch.length; i++) {
            ary.push(hMtch[i].offsetHeight)
        }
        let max = Math.max(...ary);

        for (let i = 0; i < hMtch.length; i++) {
            hMtch[i].style.height = max + "px"
        }
    }

    useEffect(() => {
        return HeightMatch()
    }, [listPackage.length ? true : false])

    // match height code start
    return (
        <>
            <Header />

            <section>
                <div className="ExploreOurFeatures_bg">
                    <div className="position-relative">
                        <img src="aboutUs.png" className="img-fluid w-100" alt="" />
                        <div className="container">
                            <div className="poster-contain">
                                
                                    <h1 className="text-center">About Us</h1>
                                    <p className="text-center text-white">SportsMint brings the thrill of fantasy sports to the world of Blockchain and NFTs.</p>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section>
                <div className="aboutUs_bg">
                    <div className="poster">
                        <div className="container">
                            <h1 className="text-center">About Us</h1>
                            <p className="text-center text-white">We, SportMint team, are committed to do the things different and of course in most interesting way.</p>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="aboutUs_bg">
                <div className="container">
                    <div className="">
                        {/* <div className="poster">
                            <div>
                                <h1 className="text-center">About Us</h1>
                                <p className="text-center text-white">We, SportMint team, are committed to do the things different and of course in most interesting way.</p>
                            </div>
                        </div> */}

                        <div className="aboute_us mb-0">
                            <div>
                               
                                    <h2 className="text-center mt-4">Level Up Your Fantasy Sports Journey with SportsMint NFTs!</h2>
                         

                                <div className="mt-5">
                                    <div className="row mx-0">
                                        <div className="col-md-6 mt-3">
                                            <div>
                                           
                                                    <div className="card_box">
                                                        <div className="mb-4">
                                                            <img src='svg/Icon.png' alt="" />
                                                        </div>
                                                        <h5>Decentralized</h5>
                                                        <p className="h_match">SportsMint is bringing the thrill of fantasy sports to the world of Blockchain and NFTs. As the first decentralized fantasy gaming platform, SportsMint aligns with Web 3.0 and AI technology to provide sports fans with an innovative and engaging experience. </p>
                                                    </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div>
                                              
                                                    <div className="card_box">
                                                        <div className="mb-4">
                                                            <img src='svg/Iconprofile.png' alt="" />
                                                        </div>
                                                        <h5>Expert Team</h5>
                                                        <p className="h_match">Powered by a dedicated team of professionals, SportsMint leverages blockchain technology to ensure security and transparency for its users. The platform utilizes NFT player cards as collectible assets and building blocks to create the ultimate fantasy teams. </p>
                                                    </div>
                                              
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div>
                                               
                                                    <div className="card_box">
                                                        <div className="mb-4">
                                                            <img src='svg/Icon1.png' alt="" />
                                                        </div>
                                                        <h5>NFT-powered Player Cards</h5>
                                                        <p className="h_match">Powered by a dedicated team of professionals, SportsMint leverages blockchain technology to ensure security and transparency for its users. The platform utilizes NFT player cards as collectible assets and building blocks to create the ultimate fantasy teams.</p>
                                                    </div>
                                              
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div>
                                               
                                                    <div className="card_box">
                                                        <div className="mb-4">
                                                            <img src='svg/vector1.png' alt="" />
                                                        </div>
                                                        <h5>Blockchain and AI</h5>
                                                        <p className="h_match">By integrating advanced AI capabilities, SportsMint's platform helps users create dream teams based on players' past performances. This empowers users to make informed decisions and optimize their fantasy gaming experience</p>
                                                    </div>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="mt-5">
                                
                                    <h2 className="text-center mb-4">Our Listing Partners</h2>
                            
                                <div className="our_partner m-0">
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        loop={true}
                                        autoplay={{
                                            delay: 1000,
                                            disableOnInteraction: false
                                        }}
                                        breakpoints={{
                                            440: {
                                                slidesPerView: 2,
                                                spaceBetween: 20,
                                            },
                                            768: {
                                                slidesPerView: 4,
                                                spaceBetween: 40,
                                            },
                                            1024: {
                                                slidesPerView: 5,
                                                spaceBetween: 50,
                                            },
                                        }}
                                        modules={[Autoplay]}
                                    >
                                        <SwiperSlide>
                                            <a href="https://www.coinstore.com/#/home?ts=1714134645842" target="_blank">
                                                <div className="text-center">
                                                    <img src="ourpartner/coinstore.png" alt="" />
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://pancakeswap.finance/" target="_blank">
                                                <div className="text-center">
                                                    <img src="ourpartner/pancakeswap.png" alt="" />
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://www.coingecko.com/" target="_blank">
                                                <div className="text-center">
                                                    <img src="ourpartner/coingenko.png" alt="" />
                                                </div>
                                            </a>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <a href="https://www.binance.com/en" target="_blank">
                                                <div className="text-center">
                                                    <img src="ourpartner/Binance.png" alt="" />
                                                </div>
                                            </a>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <a href="https://www.certik.com/" target="_blank">
                                                <div className="text-center">
                                                    <img src="ourpartner/certhik.png" alt="" />
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://coinmarketcap.com/" target="_blank">
                                                <div className="text-center">
                                                    <img src="ourpartner/marketplace2.png" alt="" />
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                    </Swiper>

                                    {/* <div className="row mx-0">
                                        <div className="col text-center">
                                            <div>
                                                <img src="logos/mrmint.png" className="mt-4 mt-md-4 mt-lg-0" alt="" />
                                            </div>
                                        </div>
                                        <div className="col text-center">
                                            <div>
                                                <img src="logos/stepmint.png" className="mt-4 mt-md-4 mt-lg-0" alt="" />
                                            </div>
                                        </div>
                                        <div className="col text-center">
                                            <div>
                                                <img src="logos/esp.png" className="mt-4 mt-md-4 mt-lg-0" alt="" />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <Faqs />
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}

export default AboutUs;