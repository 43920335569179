import React, { useEffect, useState } from "react";
import "../../../assets/style/AboutUs.css";
import "../../../assets/style/Leaderboard.css";
import Footer from "../../layout/footer/Footer";
import Header from "../../layout/header/Header";
import { Zoom, Bounce } from "react-awesome-reveal";
import DataTable from "react-data-table-component";
import { getRoyaltyLeaderboardListAction } from "../../../Action/user.action";
import toast from "react-hot-toast";
import { Col, Row } from "react-bootstrap";

const Leaderboard = () => {
  const [leaderboarddata, setleaderboarddata] = useState([]);
  const [leaderboarddatalength, setleaderboarddatalength] = useState([]);
  const [leaderboarddatafilter, setleaderboarddatafilter] = useState([]);

  useEffect(() => {
    displayroyaltyloaderboardlistfunc();
  }, []);

  const columns = [
    // {
    //   name: "S.No",
    //   selector: (row, index) => index + 1,
    //   width: "100px",
    //   center: true,
    // },
    {
      name: "Username",
      selector: (row) => row.email2.split('@')[0],
      // center:'true'
    },
    // {
    //   name: "Private wallet",
    //   selector: (row) => row.private_wallet.slice(0,5)+"..."+row.private_wallet.slice(-5),
    //   width: "auto",
    //   // center:'true'

    // },
    {
      name: "Package Count ",
      // selector: row => row.clubbought,
      cell: (row) => (
        <div className="d-flex align-items-center clubbought px-4 py-2 rounded ">
          {row.package_count}
        </div>
      ),
      // center:'true'
      width: "auto",
    },
    {
      name: "Total Nfts",
      selector: (row) => row.total_nft,
      // center:'true'
    },
    {
      name: "Current Nft Holdings",
      selector: (row) => row.current_holding,
      width: "auto",
      // center:'true'
    },
    {
      name: "Total Event",
      selector: (row) => row.total_event,
      // center:'true'
      // width: "10%"

    },
    {
      name: "Total Earnings(MNT)",
      selector: (row) => row.total_earning,
      // center:'true',
      width: "auto"

    },



    {
      name: "Estimated Earning(MNT)",
      selector: (row) => row.estimated_earning,
      width: "auto",
      // center:'true'
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
        backgroundColor: "#17191C",
        color: "white",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        color: "white",
        fontSize: "16px",
        background:'#17191C',
        display: "flex",
        justifyContent: "center",
        borderRight: "1px solid #ffffff21",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "14px",
        borderBottom: "1px solid #ffffff21",
        textAlign: "center",
        display: "flex",
        // justifyContent: "center",
      },
    },
  };

  const displayroyaltyloaderboardlistfunc = async () => {
    try {
      let res = await getRoyaltyLeaderboardListAction();
      if (res.success) {
        setleaderboarddata(res.data);
        setleaderboarddatalength(res.data.length);
        setleaderboarddatafilter(res.data)
      } else {
        toast.error(res.msg);
        setleaderboarddata([]);
        setleaderboarddatalength([]);
        setleaderboarddatafilter([])
      }
    } catch (err) {
      setleaderboarddata([]);
      setleaderboarddatalength([]);
      setleaderboarddatafilter([])
      console.log(err);
    }
  };

  const inputhandler = (e) => {
    const { value, name } = e.target;
    let filtereditems = leaderboarddatafilter.filter(
      (item) =>
        item.email2.toLowerCase().includes(value.toLowerCase())
    );
    setleaderboarddata(filtereditems);
    setleaderboarddatalength(filtereditems.length);
  };

  const data = [
    {
      id: 1,
      rank: "1",
      walletaddress: "XYZ123456789",
      totlanftholding: "20",
      clubbought: "Staters",
      totalevents: "5",
      totalearnings: "$500.00",
      estimatedearnings: "$500.00",
    },
    {
      id: 2,
      rank: "2",
      walletaddress: "XYZ123456789",
      totlanftholding: "20",
      clubbought: "Staters",
      totalevents: "5",
      totalearnings: "$500.00",
      estimatedearnings: "$500.00",
    },
    {
      id: 3,
      rank: "3",
      walletaddress: "XYZ123456789",
      totlanftholding: "20",
      clubbought: "Staters",
      totalevents: "5",
      totalearnings: "$500.00",
      estimatedearnings: "$500.00",
    },
  ];
  return (
    <>
      <Header />

      <section>
        <div className="ExploreOurFeatures_bg">
          <div className="position-relative">
            <img src="leaderboard.png" className="img-fluid w-100 " alt="" />
            <div className="container">
              <div className="poster-contain ">
                <h1 className="text-center">Leaderboard</h1>
                <p className="text-center text-white">
                  Check out the SportsMint Leaderboard! Our top earners are dominating the game <br /> and raking in huge rewards. Witness the success of early adopters and see how their investments are performing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
      <section className="leaderboard_bg rounded">
        <div className="container ">
          <div className="rounded">
            <h3>Leaderboard</h3>
            <br />
            <div className="p-3 rounded" style={{ backgroundColor: "#17191C" }}>

              <Row className="mt-3">
                <Col md={3}>
                  <input
                    type="search"
                    className="form-control nft-search"
                    placeholder="Search"
                    onChange={(e) => inputhandler(e)}
                    // ref={searchref}
                    id="searchrefs"
                  />
                </Col>
              </Row>

              <div className="mt-4">
                <DataTable
                  columns={columns}
                  data={leaderboarddata}
                  customStyles={customStyles}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <Footer />
      </section>
    </>
  );
};

export default Leaderboard;
