import React from "react";
import TotalNfts from "./TotalNfts";
import SidebarPro from "../dashboard/sidebar/Sidebar";
import DashboardHeader from "../dashboard/header/Header";
import MyNfts from '../nfts/MyNfts'
export default function DashBoardNfts(props) {
    const { collapsed, setCollapsed } = props
    return (
        <>
            <div className="dashboard">
                <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
                <div className={(collapsed ? "marginL25" : "marginL250")} id="outlet">
                    <DashboardHeader className={(collapsed ? "marginL25" : "marginL250")} collapsed={collapsed} setCollapsed={setCollapsed} />
                    
                        <MyNfts />
                    
                </div>

            </div>
        </>
    )
}