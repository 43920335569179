import React from "react";
import '../../assets/style/ExploreOurFeatures.css'
import Footer from "../layout/footer/Footer";
import Header from "../layout/header/Header";
import { JackInTheBox, Bounce } from 'react-awesome-reveal'

export default function ExploreOurFeatures() {
    return (
        <>
            <Header />
            <section>
                <div className="ExploreOurFeatures_bg">
                    <div className="position-relative">
                        <img src="sports-stadium.png" className="img-fluid w-100" alt="" />
                        <div className="container">
                            <div className="poster-contain">
                                
                                    <h1 className="text-center">Our Offerings</h1>
                                    <p className="text-center text-white">Bringing The Thrill To Blockchain, Excitement Starts Now!</p>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section>
                <div className="ExploreOurFeatures_bg">
                    <div className="poster">
                        <div className="container">
                            <div>
                                <h1 className="text-center">Explore our features</h1>
                                <p className="text-center text-white">We, Sportiverse team, are committed to do the things different and of course in most interesting way.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="ExploreOurFeatures_bg">
                <div className="container">
                    <div className="ExploreOurFeatures_childs py-0">
                        <div className="my-5">
                            <div className="row mx-0">
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                       
                                            <div>
                                                <h2>Fantasy Cricket</h2>
                                                <p className="sub_heading">Fantasy Cricket is not merely a game of chance, it's a strategic battle.</p>
                                                <p>Fantasy Cricket has emerged as a revolutionary way for fans to engage with the sport on a deeper level. It's not just about watching the game; it's about creating your dream team, making strategic decisions, and experiencing the exhilaration of seeing your chosen players perform on the field.</p>

                                                <p>Users are allotted a virtual budget to select their squad of players, each assigned a specific value based on their real-life performance.</p>
                                            </div>
                                       
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-end">
                                        <div>
                                           
                                                <img src="1391 1.png" alt="" />
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="my-5">
                            <div className="row mx-0">
                                <div className="col-md-6 order-2 order-md-1">
                                    <div className="">
                                        <div>
                                           
                                                <img src="context.png" alt="" />
                                         
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 order-1 order-md-2">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div>
                                           
                                                <h2>Contests</h2>
                                                <p className="sub_heading">Take part in contests and mature your NFTs.</p>
                                                <p>Our platform offers public and private contests catering to diverse user preferences. Public contests, accessible to all users for a nominal entry fee, provide a seamless and inclusive experience with a unique 90% return on the entry fee.</p>

                                                <p>Private contests empower users, including early investors, to curate personalized engagements, whether public or private, with active involvement from the creator. Additionally, private contest creators earn a 5% bonus upon successfully filling all spots, adding an innovative incentive to their involvement.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="my-5">
                            <div className="row mx-0">
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div>
                                           
                                                <h2>Challenger Ground</h2>
                                                <p className="sub_heading">Make a group and challenge another group</p>

                                                <p>The “Challenger Ground” is the only place where you can feel the excitement of competition and have the chance to determine your path to success. Take on thrilling one-on-one duels with fellow competitors, or form a strong team of 2 to 5 similar champions. </p>

                                                <p>If you play together, you can overcome challenges that suit your combined strength, creating an environment for cooperative gaming involving strategy.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-end">
                                        <div>
                                           
                                                <img src="ground.png" alt="" />
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="my-5">
                            <div className="row mx-0">
                                <div className="col-md-6 order-2 order-md-1">
                                    <div className="">
                                        <div>
                                           
                                                <img src="marketplaceNft.png" alt="" />
                                         
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 order-1 order-md-2">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div>
                                           
                                                <h2>NFT Marketplace</h2>
                                                <p className="sub_heading">A Dynamic ecosystem to buy, sell, and trade NFTs.</p>
                                                <p> NFT marketplace enables users to buy, sell, trade, rent in, and rent out Player NFTs, allowing users to generate profits from smart investments, take ownership of valuable NFT collections, and perform actions with these digital assets and different kinds of interactions.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="my-5">
                            <div className="row mx-0">
                                <div className="col-md-6 order-2 order-md-1">
                                    <div className="">
                                        <div>
                                           
                                                <img src="new_imgs/marketplace1.png" alt="" />
                                         
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div>
                                           
                                                <h2>AI Implementation</h2>
                                                <p className="sub_heading">Create a Dream Team with the help of AI</p>
                                                <p>Don't know how to create a good team? No worries. Our advanced AI mechanism will help you create a dream team based on their past performance. Our system will work to figure out players' insights and will help you create a team that stands out.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}