import React, { useEffect, useState } from "react";
import '../../src/assets/style/Header.css'
import '../../src/assets/style/Slider.css'
import '../../src/assets/style/OurFeatures.css'
import '../../src/assets/style/WhyPresale.css'
import '../../src/assets/style/HowItWorks.css'
import '../../src/assets/style/OurPackages.css'
import '../../src/assets/style/Faqs.css'
import '../../src/assets/style/Footer.css'
import '../../src/assets/style/BuyNow.css'
import '../../src/assets/style/Nfts.css'
import '../../src/assets/style/Sidebar.css'
import SliderSlick from '../components/landing_page/sliders/Slider.jsx'
import HowItWorks from "../components/landing_page/how_it_works/HowItWorks.jsx";
import Faqs from "../components/landing_page/faq's/Faq's";
import Footer from "../components/layout/footer/Footer.jsx";
import OurPackages from "../components/landing_page/our_packages/OurPackages.jsx";
import WhyPresale from "../components/landing_page/why_presale/WhyPresale.jsx";
import OurFeature from "../components/landing_page/our_features/OurFeatures.jsx";
import Header from "../components/layout/header/Header.jsx";
import Claim from '../components/landing_page/Claim/Claim.jsx'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Bounce, Flip } from "react-awesome-reveal";
import { useSelector } from "react-redux";
import HomeBanner from "../components/landing_page/sliders/HomeBanner.jsx";

export default function Main() {

    // const [smShow, setSmShow] = useState(false);
    const loginData = useSelector((state) => state.auth.LoginDetails); // (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));
    // alert(loginData)
    // useEffect(() => {
    //     setSmShow(true)
    // }, [])

    return (
        <>
            <section className="main">
                {/* <div className='mask'></div> */}
                <Header />
                <HomeBanner />

                {/* ================================ */}
                {/* <Modal
                        size="xl"
                        show={smShow}
                        onHide={() => setSmShow(false)}
                        aria-labelledby="example-modal-sizes-title-sm"
                        style={{ background: "#000000a1" }}
                        className="home_pop"
                    >
                        <div className="main_pp">
                            <div className="mask"></div>
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-sm">
                                  
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text_pop nft_coming py-5">
                                    <div className="py-2 pb-4 text-center">
                                        <img src="/Sportsmint_final.svg" alt="" className="img-fluid" width={400} />
                                    </div>

                                   

                                    <h1 className="text-center pb-4" style={{ fontSize: "50px" }}>Presale will be Live On 28th April</h1>

                                    
                                </div>
                            </Modal.Body>
                        </div>
                    </Modal > */}
                {/* ================================ */}

                {/* <SliderSlick /> */}


                <OurFeature />
                <WhyPresale />
                <HowItWorks />
                <OurPackages />


                {
                    loginData?.id ?
                        <Claim /> : ""
                }
                <Faqs />
                <Footer />
            </section>
        </>
    )
}