import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { TbPackages } from "react-icons/tb";
import { GiReceiveMoney } from "react-icons/gi";
import { FaHome, FaWallet } from "react-icons/fa";
import { RiNftLine } from "react-icons/ri";

export default function SidebarPro(props) {
  const { collapsed, setCollapsed } = props;

  console.log("props==>", props);
  return (
    <>
      <Sidebar className="sidebar" collapsed={collapsed}>
        <div className={collapsed ? "sidebar_header2" : "sidebar_header"}>
          {/* {
                        collapsed
                            ?
                            <Link to='/'>
                                <img src={collapsed ? "favicon/sidebarfavicon.png" : "Sportsmint_final.svg"} alt="" className="logo_img" />
                            </Link>
                            :
                            <div className="text-white" onClick={() => { setCollapsed(true) }}><FaBars /></div>
                    } */}
          <Link to="/">
            <img
              src={
                collapsed
                  ?
                  "favicon/sidebarfavicon.png"
                  :
                  "Sportsmint_final.svg"
              }
              alt=""
              className="logo_img"
            />
          </Link>
        </div>

        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0)
                return {
                  color: disabled ? "#f5d9ff" : "#d359ff",
                  backgroundColor: active ? "#eecef9" : undefined,
                };
            },
          }}
        >
          <MenuItem
            active={window.location.pathname === "/dashboard"}
            component={<Link to="/dashboard" />}
            icon={<MdDashboard />}
          >
            Dashboard
            {/* <SubMenu label="Dashboard" icon={<MdDashboard />} component={<Link to="/home" />}>
                            <MenuItem> Pie charts </MenuItem>
                            <MenuItem> Line charts </MenuItem>
                        </SubMenu> */}
          </MenuItem>
          <MenuItem
            active={window.location.pathname === "/myClub"}
            icon={<TbPackages />}
            component={<Link to="/myClub" />}
          >
            My Clubs
          </MenuItem>
          <MenuItem
            active={window.location.pathname === "/dashboardNft"}
            icon={<RiNftLine />}
            component={<Link to="/dashboardNft" />}
          >
            My NFTs
          </MenuItem>

          <MenuItem
            active={window.location.pathname === "/myReferral"}
            icon={<GiReceiveMoney />}
            component={<Link to="/myReferral" />}
          >
            Referral
          </MenuItem>

          {/* <MenuItem
            active={window.location.pathname === "/wallet"}
            icon={<FaWallet />}
            component={<Link to="/wallet" />}
          >
            Wallet
          </MenuItem> */}

          {/* <MenuItem
            active={window.location.pathname === "/"}
            icon={<FaHome />}
            component={<Link to="/" />}
          >
            Home
          </MenuItem> */}
        </Menu>

        {/* <div className="logOut"><span className="fs-4"><IoLogOutOutline /></span><span className="ms-2">
                    <span className={collapsed ? "d-none" : ""}>logout</span>
                </span></div> */}
      </Sidebar>
    </>
  );
}
