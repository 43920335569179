import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDisconnect, useAccount, useBalance, useSignMessage } from "wagmi";
import { readContract } from "@wagmi/core";
import Web3 from "web3";
import CryptoJS from "crypto-js";

import { bscTestnet } from "wagmi/chains";
import {
  generateotpforemailAction,
  loginAction,
  registrationAction,
  loginActionWithHash,
} from "../../../Action/user.action";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { TbLogout } from "react-icons/tb";
import config from "../../../config/config";
import * as ACTIONTYPES from "../../../redux/actionTypes";

import {
  Web3Modal,
  Web3Button,
  Web3ModalProps,
} from "@web3modal/react";
import { useWeb3Modal } from '@web3modal/wagmi/react'

import {
  configureChains,
  Connector,
  useWalletClient,
  WagmiConfig,
  useConnect,
  useChainId,
  useConfig,
} from "wagmi";

import Web3ModalProvider from "../../ConnectWallet/Web3ModalProvider";
import { getSignatureByWeb3 } from "../../Web3/Connector";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import HomeBottomNavigation from "../../navigator/HomeBottomNavigation";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function Header() {
  const loginData = useSelector((state) => state.auth.LoginDetails); // (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));
  const { address, isConnecting, isDisconnected } = useAccount();
  const [agree, setagree] = useState(true);
  // const { data, isError, isLoading, isSuccess } = useSignMessage({
  //     message: 'You are sigining with web3 sports',
  //     address: address
  //     signature: "sadasd",
  // })
  const dispatch = useDispatch();
  const {
    data: signMessageData,
    error,
    isLoading,
    signMessage,
    variables,
  } = useSignMessage();
  const AfterConnectAddress = useSelector(
    (state) => state.auth.walletConnnected
  );
  const [OpenWalletModel, setOpenWalletModel] = useState(false);
  const [show, setShow] = useState(false);
  const [WalletAddress, setWalletAddress] = useState("");
  const [referal, setReferal] = useState("");

  const [walletConnnected, setwalletConnnected] = useState([]);
  const [isProviderLoaded, setisProviderLoaded] = useState(false);
  const [signature, setSignature] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const _useDisconnect = useDisconnect();
  const { data1, isError1, isLoading1 } = useBalance({
    address: address,
  });
  const _useWeb3Modal = useWeb3Modal();
  // const _useNetwork = useNetwork();

  const _useConnect = useConnect();
  const _useChainId = useChainId();
  const _useAccount = useAccount();
  const _useWalletClient = useWalletClient();
  const _useConfig = useConfig();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    referral_address: "",
    // WalletAddress: address,
    email: "",
    otp: "",
  });


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isMobile, setisMobile] = useState(false);
  const [error1, setError] = useState("");
  const [login, setlogin] = useState(false);
  const modalClose = (checkIsConnect) => {
    setModalShow(false);
    if (!checkIsConnect) {
      disconnectWallet();
    }
    setForm({
      email: "",
      otp: "",
    });
  };
  const _useWeb3ModalProvider = Web3ModalProvider();
  useEffect(() => {
    setisMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    if (window.ethereum) {
      setisMobile(false)
    }
    // alert(loginData.id)
    setTimeout(() => {
      if (window.ethereum) {
        setisMobile(false)
      }
    }, 500);
  }, [])


  useEffect(() => {
    if (!isProviderLoaded) {
      _useWeb3ModalProvider.then((data) => {
        if (data) {
          setisProviderLoaded(true);
        }
      });
    }
  }, [_useWeb3ModalProvider]);

  useEffect(() => {
    if (_useAccount.isConnected) {
      _useWeb3Modal.close()
    }
  }, [_useAccount]);

  useEffect(() => {
    if (!_useWeb3Modal.isOpen) {
      handleClose();
    }
  }, [_useWeb3Modal]);

  const inputhandler = (e) => {
    setagree(e.target.checked);
  };

  // asdf
  useEffect(() => {
    if (_useAccount.isConnected) {
      const storedSignature = localStorage.getItem("signature");
      if (storedSignature != "undefined" && storedSignature != null) {
      } else {
        connectWallet();
      }
    }
    getConnectedWallet()
    setTimeout(() => {
      getConnectedWallet()
    }, 2000)

  }, [_useAccount.isConnected]);

  const getConnectedWallet = async () => {
    if (window.ethereum && AfterConnectAddress) {
      var web3 = new Web3(window.ethereum);
      let getWallet = await web3.eth.getAccounts();
      if (!getWallet[0]) {
        disconnectWallet()
      }
    }
  }
  // const connectWallet = async () => {
  //   handleShow();
  //   setlogin(false);
  //   try {
  //     const signMsg = await signMessage({
  //       message: "Login Sportsmint",
  //     });
  //     setSignature(signMsg);
  //     return signMsg;
  //   } catch (err) {
  //     console.log("Error:", err);
  //     if (err && err.message === "User rejected the request.") {
  //       toast.error("User rejected the request.");
  //       // If user rejected the signature, disconnect the wallet
  //       disconnectWallet();
  //     } else {
  //       // Handle other errors gracefully
  //       // e.g., display a message to the user
  //     }
  //   }
  // };
  const encryptAES = (text, key) => {
    return CryptoJS.AES.encrypt(text, key).toString();
  };

  const conenctMetamask = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        var web3 = new Web3(window.ethereum);

        let currentNetwork = web3.currentProvider.chainId;
        if (!currentNetwork) {
          currentNetwork = web3.currentProvider.getChainId();
        }
        console.log({ currentNetwork })
        // alert(config.chainId)
        if (currentNetwork != config.chainId && currentNetwork != web3.utils.toHex(parseInt(config.chainId))) {
          toast.error(`Please select BNB smartchain!!`);
          return false;
        } else {
          if (accounts[0]) {
            let publicAddress = web3.utils.toChecksumAddress(accounts[0]);
            const encryptedMessage = encryptAES(
              publicAddress,
              process.env.REACT_APP_SECRET_KEY
            );
            setWalletAddress(publicAddress)
            setSignature(encryptedMessage);
            loginWithSignature(encryptedMessage, publicAddress)
          }
        }
      } else {

        toast.error("Please use Dapp browser!!");
      }
    } catch (error) {
      console.log(error)
      switch (error.code) {
        case 4001:
          toast.error("User Rejected");
          break;
        case -32002:
          toast.error(
            "Request Already Processing, Please check your Meta Mask wallet"
          );
          break;
        case "ERR_NETWORK":
          toast.error("We're experiencing technical difficulties.");
          break;
        default:
          break;
      }
    }
  }

  const connectWallet = async () => {
    conenctMetamask(); // After remove wagmi system by kamlesh 28/06/2024
    return;
    handleShow();
    setlogin(false);
    try {
      const messageToEncrypt = "Login Sportsmint";
      const encryptedMessage = encryptAES(
        address,
        process.env.REACT_APP_SECRET_KEY
      ); // Replace yourEncryptionKey with your actual encryption key
      // const signMsg = await signMessage({
      //   message: encryptedMessage,
      // });
      setSignature(encryptedMessage);
      return encryptedMessage;
    } catch (err) {
      if (err && err.message === "User rejected the request.") {
        toast.error("User rejected the request.");
        // If user rejected the signature, disconnect the wallet
        disconnectWallet();
      } else {
        // Handle other errors gracefully
        // e.g., display a message to the user
      }
    }
  };

  // useEffect(() => {
  //   if (signature) {
  //     _useWeb3Modal.close();
  //     loginWithSignature(signature);
  //   }
  // }, [signature]);

  useEffect(() => {
    if (error && error.message.includes("User rejected the request")) {
      toast.error("User rejected the request");
      disconnectWallet();
    }
  }, [error]);

  const generateOtp = async (e) => {
    e.preventDefault();
    try {
      form.otp = ""
      if (!form.email) {
        toast.error("Please enter your email address");
        return;
      }
      if (agree == false) {
        toast.error("Please accept our Terms and Conditions!");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(form.email)) {
        toast.error("Please enter a valid email address");
        return;
      }
      if (form.referral_address && form.referral_address.length < 42) {
        toast.error("Referral address must be Valid");
        return;
      }
      let referral =
        form.referral_address != undefined && form.referral_address !== ""
          ? form.referral_address
          : null;
      if (form.isValidAddress != "" || form.isValidAddress != undefined) {
        let res = await generateotpforemailAction({
          email: form.email,
        });

        if (res.status == 200) {
          setlogin(true);
          toast.success(res.msg);
        } else if (res.status == 203) {
          toast.error(res.msg);
        }

        else {
          if (res.msg) {

            toast.error(res.msg);
          }
          toast.error(res.message);

          // setModalShow(false);
          // setForm("");
          disconnectWallet();
          setError("");
        }
      } else {
        toast.error("Something went wrong please try again.");
      }
    } catch (error) { }
  };

  const loginWithSignature = async (signature, newAddress) => {
    try {
      if (signature) {
        // if (!signature.startsWith("0x")) {
        //   signature = "0x" + signature;
        // }
        let reqData = { "hash": signature }

        // let res = await loginAction({ address: address, signature: signature });
        let res = await loginActionWithHash(reqData);
        if (res.status == 202) {
          dispatch({ type: ACTIONTYPES.walletConnnected, payload: address || WalletAddress || newAddress });
          dispatch({
            type: ACTIONTYPES.USERLOGIN,
            payload: res.data,
            token: res.data.authToken,
          });
          toast.success("You're now logged in");
          localStorage.setItem("signature", signature);
          // localStorage.setItem("walletAddress", address);
          // Cookies.set('loginSuccessWeb3Sports', JSON.stringify(res.data));
          modalClose(true);
        } else if (res.status == 406) {
          setWalletAddress(address || newAddress);
          setModalShow(true);
        }
      }
    } catch (error) {
      console.error("Error logging in with signature:", error);
      disconnectWallet();
    }
  };

  const params = useParams();

  const acceptAndSign = async (e) => {
    e.preventDefault();
    try {
      // alert(form.otp.length)
      if (agree == false) {
        toast.error("Please accept our Terms and Conditions!");
        return;
      }

      if (!form.email) {
        toast.error("Please enter your email address");
        return;
      }
      if (!form.otp) {
        toast.error("Please enter OTP");
        return;
      }
      if (!form.otp) {
        toast.error("Please enter OTP");
        return;
      }
      if (form.otp.length > 6 || form.otp.length < 6) {
        toast.error("Please enter the correct OTP");
        return;
      }
      if (!/^\d+$/.test(form.otp)) {
        toast.error("OTP must contain only numbers");
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(form.email)) {
        toast.error("Please enter a valid email address");
        return;
      }
      if (form.referral_address && form.referral_address.length != 42) {
        toast.error("Referral address must be Valid");
        return;
      }

      if (form.referral_address == WalletAddress) {
        toast.error("Both addres should not be same ");
        return;
      }
      let referral =
        form.referral_address !== undefined && form.referral_address != ""
          ? form.referral_address
          : null;
      if (form.isValidAddress != "" || form.isValidAddress != undefined) {
        let res = await registrationAction({
          address: WalletAddress,
          email: form.email,
          otp: form.otp,
          referral:
            params.referal_id == undefined || params.referal_id == ""
              ? referral
              : params.referal_id,
        });
        if (res.status == 200) {
          toast.success("Congratulations! You're successfully registered");
          setTimeout(() => {
            loginWithSignature(signature, WalletAddress);
          }, 2000)
        } else if (res.msg.includes("Otp")) {
          toast.error("Otp does not match.");
        } else {
          toast.error(res.msg);
          // setModalShow(false);
          // setForm("");
          disconnectWallet();
          setError("");
        }
      } else {
        toast.error("Something went wrong please try again.");
      }
    } catch (error) {
      console.log("error13");
      toast.error(error.toString());
      // console.log(error.response.data[0].message);
    }
  };

  useEffect(() => {
    toast.dismiss();
    if (show) {

      _useWeb3Modal.open();
    }
  }, [show]);

  const navigatehome = useNavigate();

  const disconnectWallet = async () => {
    _useDisconnect.disconnect();
    // setForm("");
    localStorage.removeItem("signature");
    // Cookies.remove('loginSuccessWeb3Sports');
    // Cookies.remove('loginSuccess');
    // localStorage.removeItem("walletAddress");
    dispatch({ type: ACTIONTYPES.walletConnnected, payload: "" });
    dispatch({ type: ACTIONTYPES.USERLOGIN, payload: "", token: "" });

    //navigatehome("/")
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    if (name === "referral_address") {
      if (!addressRegex.test(value)) {
        setError("Invalid wallet address");
        setForm((old) => ({
          ...old,
          referral_address: value,
          isValidAddress: false,
        }));
      } else {
        setForm((old) => ({
          ...old,
          referral_address: value,
          isValidAddress: true,
        }));
        setError("");
      }
    } else if (name == "otp") {
      // Only allow numeric characters for OTP field
      const numericRegex = /^[0-9]*$/;
      if (!numericRegex.test(value)) {
        // If non-numeric characters are entered, prevent updating the state
        e.preventDefault();
      } else {
        // If numeric characters are entered, update the form state
        setForm((old) => ({
          ...old,
          [name]: value,
        }));
      }
    }
    else {
      // If input field is not for wallet address, update the form state as usual
      setForm((old) => ({ ...old, [name]: value }));
    }
  };

  const width = window && window.innerWidth;

  // useEffect(() => {
  //   setModalShow(true)
  // }, [])

  const handleLinkClick = (event, url) => {
    event.preventDefault();
    // Your custom logic here
    console.log("Link clicked!");

    // Open the URL in a new tab
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <section className="header_section">
        <div className="container">
          <Toaster />
          <div className="header">
            <Navbar
              expand="lg"
              id="navbar"
              className="bg-body-tertiary"
              bg="dark"
              data-bs-theme="dark"
            >
              <Navbar.Brand className={width < "410" ? "me-0" : ""}>
                <Link to="/" className="d-flex">
                  <img
                    src="Sportsmint_final.svg"
                    alt=""
                    className="img-fluid"
                  />
                  {/* <img src={(width < "410") ? "favicon/sidebarfavicon.png" : "/Sportsmint_final.svg"} alt="" className={width < "410" ? "h32" : "img-fluid"} /> */}
                </Link>
              </Navbar.Brand>

              {/* devendra */}
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className=" order-2 order-md-3 order-md-1"
              />
              {loginData?.id != undefined
                ?
                (
                  <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="order-3 order-lg-1"
                  >
                    <Nav className="me-auto justify-content-center">
                      {/* <Nav.Link href="#0">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "activeNew" : "inactive"
                          }
                          to="/"
                        >
                          Home
                        </NavLink>{" "}
                      </Nav.Link> */}
                      <Nav.Link href="/dashboard">
                        <Link to="/dashboard">Dashboard</Link>
                      </Nav.Link>
                      <Nav.Link href="#1">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "activeNew" : "inactive"
                          }
                          to="/exploreOurFeatures"
                        >
                          Features
                        </NavLink>{" "}
                      </Nav.Link>
                      <Nav.Link href="#2">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "activeNew" : "inactive"
                          }
                          to="/ourClub"
                        >
                          Clubs
                        </NavLink>{" "}
                      </Nav.Link>
                      <Nav.Link href="#3">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "activeNew" : "inactive"
                          }
                          to="/allplayersnftlist"
                        >
                          NFT's
                        </NavLink>{" "}
                      </Nav.Link>
                      <Nav.Link href="#4">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "activeNew" : "inactive"
                          }
                          to="/leaderboard"
                        >
                          Leaderboard
                        </NavLink>{" "}
                      </Nav.Link>
                      {/* <Nav.Link href="">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "inactive" : "inactive"
                        }
                        onClick={(event) => handleLinkClick(event, "https://presale.sportsmint.io/media/Investor.pdf")}
                      >
                        Investor
                      </NavLink>{" "}
                    </Nav.Link> */}
                    </Nav>
                  </Navbar.Collapse>
                )
                :
                (
                  <>
                    {/* <NavLink
                    className={({ isActive }) =>
                      isActive ? "activeNew" : "inactive"
                    }
                    to="/aboutUs"
                  >
                    About Us
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "activeNew" : "inactive"
                    }
                    to="/exploreOurFeatures"
                  >
                    Features
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "activeNew" : "inactive"
                    }
                    to="/ourClub"
                  >
                    Clubs
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "activeNew" : "inactive"
                    }
                    to="/totalNfts"
                  >
                  
                    NFT's
                  </NavLink> */}

                    <Navbar.Collapse
                      id="basic-navbar-nav"
                      className="order-3 order-lg-1"
                    >
                      <Nav className="me-auto justify-content-center">
                        {/* <Nav.Link href="#0">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "activeNew" : "inactive"
                            }
                            to="/"
                          >
                            Home
                          </NavLink>{" "}
                        </Nav.Link> */}
                        <Nav.Link href="#0">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "activeNew" : "inactive"
                            }
                            to="/aboutUs"
                          >
                            About Us
                          </NavLink>{" "}
                        </Nav.Link>
                        <Nav.Link href="#1">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "activeNew" : "inactive"
                            }
                            to="/exploreOurFeatures"
                          >
                            Features
                          </NavLink>{" "}
                        </Nav.Link>
                        <Nav.Link href="#2">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "activeNew" : "inactive"
                            }
                            to="/ourClub"
                          >
                            Clubs
                          </NavLink>{" "}
                        </Nav.Link>
                        <Nav.Link href="#3">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "activeNew" : "inactive"
                            }
                            to="/allplayersnftlist"
                          >
                            NFT's
                          </NavLink>{" "}

                        </Nav.Link>
                        <Nav.Link href="#4">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "activeNew" : "inactive"
                            }
                            to="/leaderboard"
                          >
                            Leaderboard
                          </NavLink>{" "}
                        </Nav.Link>
                        {/* <Nav.Link href="">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "inactive" : "inactive"
                        }
                        onClick={(event) => handleLinkClick(event, "https://presale.sportsmint.io/media/Investor.pdf")}
                      >
                        Investor
                      </NavLink>{" "}
                    </Nav.Link> */}
                      </Nav>
                    </Navbar.Collapse>
                  </>
                )}

              {!AfterConnectAddress && (
                isMobile ? <button
                  className="btn_uni order-1 order-lg-3"
                  variant="primary"
                  onClick={() => setOpenWalletModel(true)}
                // onClick={() => conenctMetamask()}
                >
                  <span> Connect wallet</span>
                </button>
                  :
                  <button
                    className="btn_uni order-1 order-lg-3"
                    variant="primary"
                    onClick={() => connectWallet()}
                  >
                    <span> Connect wallet</span>
                  </button>
              )}

              {/* devendra */}
              <div className="order-1 order-lg-3 d-flex align-items-center">
                {AfterConnectAddress && (
                  <button
                    className="btn_uni"
                    variant="primary"
                    onClick={() => disconnectWallet()}
                  >
                    Disconnect Wallet
                  </button>
                )}
                <div className="w_add" style={{ color: "white" }}>
                  {" "}
                  {AfterConnectAddress
                    ? AfterConnectAddress.toString().slice(0, 4) +
                    "...." +
                    AfterConnectAddress.toString().slice(-4)
                    : ""}
                </div>
              </div>
            </Navbar>
          </div>
          {/* ====== */}
          <Modal
            show={modalShow}
            onHide={modalClose}
            // backdrop="static"
            className="modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <h4 className="text-center">Welcome to</h4>
            <h2 className="text-center">SportsMint</h2>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Referral Address"
                  id=""
                  name="referral_address"
                  onChange={inputHandler}
                  value={
                    params.referal_id == "" || params.referal_id?.length != 42
                      ? form.referral_address
                      : params.referal_id
                  }
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  // placeholder={WalletAddress}
                  id=""
                  name="WalletAddress"
                  value={WalletAddress}
                />
              </div>

              <div className="d-flex justify-content-start alighn-ites-center my-2">
                <div className="me-2">
                  <input
                    type="checkbox"
                    checked={agree}
                    onClick={inputhandler}
                    value={agree}
                  />
                </div>

                <p className="mb-0" style={{ fontSize: "14px" }}>
                  I agree to the{" "}
                  <a href={`${config.baseUrl}cookiePolicy`} target="_blank">
                    <b className="text-info">terms and condition </b>
                  </a>{" "}
                  and
                  <a href={`${config.baseUrl}privacyPolicy`} target="_blank">
                    <b className="text-info"> privacy policy </b>
                  </a>{" "}
                </p>
              </div>

              {login ? (
                <>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email address"
                      id=""
                      name="email"
                      value={form.email}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter OTP"
                      id=""
                      name="otp"
                      onChange={inputHandler}
                      value={form.otp}
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn_uni nav-link w-100 mt-3"
                    onClick={(e) => acceptAndSign(e)}
                  >
                    SIGN IN
                  </button>

                  <div className="row">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn_uni nav-link w-100 mt-3"
                        onClick={(e) => generateOtp(e)}
                      >
                        Resend Otp
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn_uni nav-link w-100 mt-3"
                        onClick={(e) => setlogin(false)}
                      >
                        Change email
                      </button>
                    </div>
                  </div>
                </>
              )
                :
                (
                  <>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your email address"
                        id=""
                        name="email"
                        onChange={inputHandler}
                        value={form.email}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn_uni nav-link w-100"
                      onClick={(e) => generateOtp(e)}
                    >
                      Generate Otp
                    </button>
                  </>
                )}
            </form>
          </Modal>
          <Modal
            show={OpenWalletModel}
            onHide={() => setOpenWalletModel(false)}
            // backdrop="static"
            className="modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <h6 className="text-center">To access presale sportsmint please open web3 wallet Dapp browser</h6>
            {/* <button
              className="btn_uni order-1 order-lg-3 mt-3"
              variant="primary"
              onClick={() => {
                setOpenWalletModel(false)
                connectWallet()
              }}
            >
              <img
                src="Icons/wallet.png"
                alt=""
                style={{ height: '25px' }}
                className="img-fluid"
              />
              <span> Wallet Connect</span>
            </button> */}


            <button
              className="btn_uni order-1 order-lg-3 mt-3"
              variant="primary"
              onClick={() => window.location.href = `https://metamask.app.link/dapp/${window.location.href}`}
            >
              <img
                src="Icons/metamask.png"
                alt=""
                style={{ height: '30px' }}
                className="img-fluid"
              />
              <span> Metamask </span>
            </button>

            <button
              className="btn_uni order-1 order-lg-3 mt-3"
              variant="primary"
              onClick={() => window.location.href = `https://link.trustwallet.com/wc?uri=${window.location.href}`}
            >
              <img
                src="https://seeklogo.com/images/T/trust-wallet-token-twt-logo-5312F3331F-seeklogo.com.png"
                alt=""
                style={{ height: '30px' }}
                className="img-fluid"
              />
              <span> Trust wallet</span>
            </button>

          </Modal>
        </div>
        {/* <HomeBottomNavigation />  */}
      </section>
    </>
  );
}
