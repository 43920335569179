import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa6";
import { BsTelegram } from "react-icons/bs";


export default function Footer() {
    return (
        <>
            <section className="footer_banner">
                <div className="container">
                    <div className="footer py-4">
                        {/* <div className="logo_wrapper">
                            <img src="SportMintLogo.svg" alt="" />
                        </div> */}
                        <div className="row">
                            <div className="col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                                <ul className="d-block d-md-flex mb-0">
                                    <li className="text-center mt-3 mt-md-0 me-md-4"><Link to="/aboutUs">About Us</Link></li>

                                    <li className="text-center mt-3 mt-md-0 me-md-4"><Link to="/contact">Contact </Link></li>

                                    <li className="text-center mt-3 mt-md-0 me-md-4"><Link to="/privacyPolicy">Privacy Policy</Link></li>

                                    <li className="text-center mt-3 mt-md-0 me-md-4"><Link to="/cookiePolicy">Cookie Policy</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-4 mt-3">
                                <ul className="icons d-flex">
                                    <li className="facebook_icon">
                                        <a href="https://www.facebook.com/profile.php?id=61557738151728" target="_blank" rel="noopener noreferrer">
                                            <FaFacebook />
                                        </a>
                                    </li>
                                    <li className="instagram_icon">
                                        <a href="https://www.instagram.com/sportsmint_" target="_blank" rel="noopener noreferrer">
                                            <FaInstagram />
                                        </a>
                                    </li>
                                    <li className="twitter_icon">
                                        <a href="https://twitter.com/sportsmint_" target="_blank" rel="noopener noreferrer">
                                            <FaXTwitter />
                                        </a>
                                    </li>
                                    <li className="twitter_icon">
                                        <a href="https://www.linkedin.com/company/sports-mint" target="_blank" rel="noopener noreferrer">
                                            <FaLinkedin />
                                        </a>
                                    </li>
                                    <li className="twitter_icon">
                                        <a href="https://t.me/sportsmint" target="_blank" rel="noopener noreferrer">
                                            <BsTelegram />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <a href="mailto:info@sportsmint.io">Connect with us on: info@sportsmint.io </a>
                            </div>
                            <div className="col-md-6">
                                <div className="d-block d-md-flex justify-content-end align-items-end h-100">© 2024 Sportsmint. All rights reserved.</div>
                            </div>
                            <div className="col-md-12 mt-3">
                                <div className="partner-logo">
                                    <div className="d-md-flex align-items-center text-white">
                                        <div className="me-3">
                                            <span className="me-1">Product by : </span><a href="https://mrmint.io/" target="_blank">
                                                <img src="video/logo.png" width={`100px`} style={{ marginTop: "-8px" }} className="ftr_logo" />
                                            </a>
                                        </div>
                                        <div>
                                            <span className="me-1">Tech Partner :</span><a href="https://www.espsofttech.com/" target="_blank">
                                                <img src="LogoESP.png" width={`90px`} className="ftr_logo" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}