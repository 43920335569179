import React from "react";
import { Slide, Bounce, JackInTheBox } from 'react-awesome-reveal'

export default function WhyPresale() {
    return (
        <>
            <section className="why_presale_banner">
                <div className="container">
                    <div className="why_presale">
                        <img className="rocket" src="rocket.png" alt="" />
                        <h2 className="hd">Stay Engaged for Our Exciting Presale Opportunities</h2>
                        <p className="text-center">Take part in presale and become a part of the exciting journey</p>
                        <div className="bg_wrapper">
                            <div className="row mx-0">
                                <h2 className="mt-4 mb-3">Presale Benefits Awaits!</h2>
                                <div className="col-lg-5">
                                    <div className="h-100">
                                        <div className="how_it_works pt-0 h-auto">
                                            <div className="v_box h-auto">
                                                <div className="h-auto">
                                                    {/* <iframe width="500" height="415" src="https://www.youtube.com/embed/YYgQD2WT3vg?si=uP_sNj6pfRP4b99o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                                                    <iframe width="490" height="415" src="https://www.youtube.com/embed/-Kzp0bj4kD0?si=A7JiiJtlTB-gL9Me" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                                    {/* <div className="h-100">
                                                                <video style={{ width: "100%", borderRadius: '25px' }} controls>
                                                                    <source src="video/Presale.mp4" type="video/mp4" />
                                                                </video>
                                                            </div> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-7 mt-5 mt-md-0">
                                    <div>
                                        <p className="heading">
                                            <b>● Get Early Access</b> : First NFT holders would get an advantage of exclusive rewards in the contest pool price.
                                        </p>
                                        <p className="heading">
                                            <b>● Earn Royalty Rewards</b> : Ongoing royalty rewards on each NFT trade. This incentivizes participation and ensures continuous benefits.
                                        </p>
                                        <p className="heading">
                                            <b>● Retain Price Control</b> : Creators retain control over the pricing of their NFTs, allowing them to sell at demand price, thus maximizing potential profits.
                                        </p>
                                        <p className="heading">
                                            <b>● Rent-out NFTs</b> : NFT creators can rent out their NFTs at the marketplace.
                                        </p>
                                        <p className="heading">
                                            <b>● Customize NFT Clubs</b> : NFT creators can customize Clubs to their preference.
                                        </p>
                                        <p className="heading">
                                            <b>● Explore Special Bonuses</b> : NFT creators gain access to various exclusive bonuses throughout the process.
                                        </p>
                                    </div>
                                </div>
                                <hr />
                                <div className="mt-2">
                                    <p className="heading_b">Don't miss out on this exclusive opportunity to join the fantasy sports revolution from the beginning! Reserve your spot today.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}