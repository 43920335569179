import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  autopickplanforuserAction,
  createpackagefromselectednftAction,
  displayfilterparameterAction,
  displayNftsFilterCountryAction,
  getplanpurchaseconfirmationstatusAction,
  getpurchasedpurchasedstatisticsAction,
} from "../../Action/user.action";
import { useSelector } from "react-redux";
import config from "../../config/config";
import { IoSearchOutline } from "react-icons/io5";
import { AiOutlineBars } from "react-icons/ai";
import { TbCricket } from "react-icons/tb";
import { IoBowlingBallOutline } from "react-icons/io5";
import { MdSportsCricket } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import CustomLoader from "../dashboard/CustomLoader";
import { GiCricketBat } from "react-icons/gi";
import { MdShoppingCartCheckout } from "react-icons/md";


const AutoPickNft = (props) => {
  const [displaycountries, setdisplaycountries] = useState([]);
  const [displayakills, setdisplayakills] = useState([]);
  const [checkout, setcheckout] = useState({
    packageId: "",
    selectedPlayer: {
      5: [],
      4: [],
      3: [],
      2: [],
      1: [],
    },
  });
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [MainNFTdata, setMainNFTdata] = useState([]);
  const [checkoutdata, setcheckoutdata] = useState([]);
  const searchref = useRef("");
  const [filterdata, setfilterdata] = useState([]);
  const [totaldisplayNfts, settotaldisplayNfts] = useState(0);

  const [selectedskillfilter, setselectedskillfilter] = useState("");
  const [selectedcountryfilter, setselectedcountryfilter] = useState("");
  const [selectedsort, setselectedsort] = useState("");
  const [postfilterdata, setpostfilterdata] = useState([]);
  const [loader, setloader] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [activePlanType, setActivePlanType] = useState(5);


  const { id } = useParams();
    console.log(id,"id");
    let packageId = id;

  useEffect(() => {
    setTimeout(() => {
 
      // if (
      //   !location.state?.packagePurchasedId ||
      //   location.state?.packagePurchasedId == "" ||
      //   !location.state ||
      //   location.state == ""
      // ) {
      //   // window.location.href = `${config.baseUrl}nfts`
      //   navigate(`${config.baseUrl}myclub`);
      // } else {
        planList()
        getnftdetails();
        displayskillandcountriesfunc();
      // }
    }, 500);
    return () => { };
  }, []);

  const loginData = useSelector((state) => state.auth.LoginDetails); // (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));

  const handlereset = () => {
    if (selectedcountryfilter=="" && selectedskillfilter== "" && selectedsort=="" && document.getElementById("searchrefs").value == ""){
      return;
    }
    else {
      getnftdetails();
    }
  };

  const getnftdetails = async () => {
    try {
      setloader(true);
      let res = await autopickplanforuserAction({
        token: loginData.authToken,
        // data: location.state?.packagePurchasedId,
        data:packageId
      });
      if (res.data.success) {
        setloader(false);
        setselectedcountryfilter("");
        setselectedskillfilter("");
        setselectedsort("");
        document.getElementById("searchrefs").value = "";
        props.setAutoPickPlayers(res.data.data);
        nftDataSetByPlanFunction(res.data.data);
        setcheckoutdata(res.data.data);
        setMainNFTdata(res.data.data);
      } else {
        setloader(false);

        setdata([]);
        setfilterdata([]);
      }
    } catch (error) {
      setloader(false);

      console.log(error);
    }
  };

  const displayskillandcountriesfunc = async () => {
    try {
      let res = await displayfilterparameterAction();
      if (res.success) {
        setdisplaycountries(res.data.country);
        setdisplayakills(res.data.skills);
      } else {
        setdisplaycountries([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkoutfunc = async () => {
    try {
      const updatedCheckout = { ...checkout };
      checkoutdata.forEach((item) => {
        const { player_id, nft_type, player_count } = item;
        if (!updatedCheckout.selectedPlayer[nft_type]) {
          updatedCheckout.selectedPlayer[nft_type] = [];
        }
        for (let i = 0; i < player_count; i++) {
          updatedCheckout.selectedPlayer[nft_type].push(player_id);
        }
      });
      // updatedCheckout.packageId = location.state?.packagePurchasedId;
      updatedCheckout.packageId = parseInt(packageId);

      // updatedCheckout.packageId=""
      // updatedCheckout.selectedPlayer={}
      Swal.fire({
        title: `<h1 class="text-dark">Confirm Checkout!</h1>`,
        html: `<b>Please note that once you proceed to checkout, NFTs cannot be changed or replaced within the club.</b>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await createpackagefromselectednftAction(
            loginData.authToken,
            updatedCheckout
          );
          if (res.data.success) {
            Swal.fire(
              `<h1 class="text-dark">Checkout Successful!</h1>`
            );
            setTimeout(() => {
              navigate(`${config.baseUrl}myclub`);
            }, 1500);
          } else {
            setcheckout({
              packageId: "",
              selectedPlayer: {
                5: [],
                4: [],
                3: [],
                2: [],
                1: [],
              },
            });
            Swal.fire(
              `<h1 class="text-dark">Failed to checkout!</h1>`,
              res.data.msg,
              "error"
            );
          }
        } else {
          // alert(1)
          setcheckout({
            packageId: "",
            selectedPlayer: {
              5: [],
              4: [],
              3: [],
              2: [],
              1: [],
            },
          });
        }
      });
    } catch (err) {
      console.log(err);
      setcheckout({
        packageId: "",
        selectedPlayer: {
          5: [],
          4: [],
          3: [],
          2: [],
          1: [],
        },
      });
    }
  };

  const filterSkillAndCountryFunc = async (
    filterSkillValue,
    filterCountryValue
  ) => {
    setloader(true);
    document.getElementById("searchrefs").value = "";
    setselectedsort("");
    let totalPlayers = 0;
    if (filterSkillValue == 0) {
      setselectedskillfilter("");
    }
    if (filterCountryValue == 0) {
      setselectedcountryfilter("");
    }
    if (filterSkillValue != "" && filterCountryValue != "") {
      setselectedskillfilter(filterSkillValue);
      setselectedcountryfilter(filterCountryValue);
      let tempfilterdata = filterdata.filter(
        (item) =>
          item.skill.replace(/\s/g, "").toLowerCase() ==
          filterSkillValue.replace(/\s/g, "").toLowerCase() &&
          item.country.replace(/\s/g, "").toLowerCase() ==
          filterCountryValue.replace(/\s/g, "").toLowerCase()
      );
      setdata(tempfilterdata);
      for (let i = 0; i < tempfilterdata.length; i++) {
        totalPlayers += parseInt(tempfilterdata[i].player_count);
      }
      settotaldisplayNfts(totalPlayers);
      setpostfilterdata(tempfilterdata);
    } else if (filterSkillValue == "" && filterCountryValue == "") {
      setselectedskillfilter(filterSkillValue);
      setselectedcountryfilter(filterCountryValue);
      setdata(filterdata);
      for (let i = 0; i < filterdata.length; i++) {
        totalPlayers += parseInt(filterdata[i].player_count);
      }
      settotaldisplayNfts(totalPlayers);
      setpostfilterdata(filterdata);
    } else if (filterSkillValue != "") {
      setselectedskillfilter(filterSkillValue);
      let tempfilterdata = filterdata.filter(
        (item) =>
          item.skill.replace(/\s/g, "").toLowerCase() ==
          filterSkillValue.replace(/\s/g, "").toLowerCase()
      );
      setdata(tempfilterdata);
      for (let i = 0; i < tempfilterdata.length; i++) {
        totalPlayers += parseInt(tempfilterdata[i].player_count);
      }
      settotaldisplayNfts(totalPlayers);
      setpostfilterdata(tempfilterdata);
    } else if (filterCountryValue != "") {
      setselectedcountryfilter(filterCountryValue);
      let tempfilterdata = filterdata.filter(
        (item) =>
          item.country.replace(/\s/g, "").toLowerCase() ==
          filterCountryValue.replace(/\s/g, "").toLowerCase()
      );
      setdata(tempfilterdata);
      for (let i = 0; i < tempfilterdata.length; i++) {
        totalPlayers += parseInt(tempfilterdata[i].player_count);
      }
      settotaldisplayNfts(totalPlayers);
      setpostfilterdata(tempfilterdata);
    }
    setTimeout(() => {
      setloader(false);
    }, 500);
  };

  const inputhandler = (e) => {
    const { value, name } = e.target;
    let sortedNfts = [];

    if (selectedskillfilter == "" && selectedcountryfilter == "") {
      sortedNfts = filterdata;
    } else {
      sortedNfts = postfilterdata;
    }
    let filtereditems = sortedNfts.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.country.toLowerCase().includes(value.toLowerCase()) ||
        item.skill.toLowerCase().includes(value.toLowerCase())
    );
    setdata(filtereditems);
    let totalPlayers = 0;
    for (let i = 0; i < filtereditems.length; i++) {
      totalPlayers += parseInt(filtereditems[i].player_count);
    }
    settotaldisplayNfts(totalPlayers);
  };

  const sortinginputhandler = (item) => {
    let sortedNfts = [];
    const sortingOptions = {
      1: "Price : Low to High",
      2: "Price : High to Low",
      3: "Age : Low to High",
      4: "Age : High to Low",
      5: "Rating : Low to High",
      6: "Rating : High to Low",
    };

    const selectedSorts = sortingOptions[item];
    setselectedsort(selectedSorts);
    if (selectedskillfilter == "" && selectedcountryfilter == "") {
      sortedNfts = [...filterdata];
    } else {
      sortedNfts = [...postfilterdata];
    }
    if (item == 1) {
      sortedNfts.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    }
    if (item == 2) {
      sortedNfts.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    }
    if (item == 3) {
      sortedNfts.sort((a, b) => parseFloat(a.age) - parseFloat(b.age));
    }
    if (item == 4) {
      sortedNfts.sort((a, b) => parseFloat(b.age) - parseFloat(a.age));
    }

    if (item == 5) {
      sortedNfts.sort(
        (a, b) => parseFloat(a.static_rating) - parseFloat(b.static_rating)
      );
    }
    if (item == 6) {
      sortedNfts.sort(
        (a, b) => parseFloat(b.static_rating) - parseFloat(a.static_rating)
      );
    }
    setdata(sortedNfts);
  };

  const location = useLocation();
  // useEffect(() => {
  //   if (location.state?.packagePurchasedId) {
  //   }
  //   return () => { };
  // }, []);


  const planList = async () => {
    try {
      let res = await getpurchasedpurchasedstatisticsAction(
        loginData.authToken,
        {
          packageId: location.state?.packagePurchasedId,
          packageId:packageId
        }
      );
      if (res.data.success) {
        setPlanData(res.data.data);
        setActivePlanType(res.data.data[0].id);
      } else {
        setPlanData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    nftDataSetByPlanFunction(MainNFTdata, activePlanType)
  }, [activePlanType])

  const nftDataSetByPlanFunction = async (_TotalPlayerByPlan = MainNFTdata) => {
    let TotalPlayerByPlan = _TotalPlayerByPlan.filter(item =>
      activePlanType == item.nft_type
    )
    console.log("TotalPlayerByPlan", activePlanType, TotalPlayerByPlan.length, _TotalPlayerByPlan.length)
    setdata(TotalPlayerByPlan);
    setfilterdata(TotalPlayerByPlan);
    settotaldisplayNfts(TotalPlayerByPlan.length);
    let totalPlayers = 0;
    for (let i = 0; i < TotalPlayerByPlan.length; i++) {
      totalPlayers += parseInt(TotalPlayerByPlan[i].player_count);
    }
    settotaldisplayNfts(totalPlayers);
  }

  return (
    <>

      <div className="row mt-4">
        <div className="t_nftsHeader">
          <div className="row">

            <div className="col-12">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {planData.map((item, index) => (
                  <li
                    onClick={() => setActivePlanType(item.id)}
                    className="nav-item mt-3"
                    role="presentation"
                  >
                    <button
                      className={`me-2 ${index == 0 ? "active" : ""}`}
                      id={`${index}-home-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${index}-home`}
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      {item.name?.toUpperCase()}{" "}
                      <span>
                        ({item.qty})
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-12">
              <div className="row mt-3">
                <div className="col-lg-4 col-xl-3 mb-3">
                  <div className="fltrnft">
                    <div className="position-relative w-100">
                      <input
                        type="text"
                        className="form-control nft-search"
                        placeholder="Search"
                        onChange={(e) => inputhandler(e)}
                        id="searchrefs"
                      />
                      <span className="icnsrch d-none d-md-block">
                        <IoSearchOutline />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-9">
                  <div className="row justify-content-end">
                    <div className="col-6 col-lg-3 text-end mb-md-3 px-2">
                      <div className="d-flex justify-content-md-end">
                        <div className="dropdown  w-100">
                          <button
                            className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div className="d-flex justify-content-end align-items-center">
                              <AiOutlineBars />
                              <span className="ms-1">
                                {selectedskillfilter == ""
                                  ? "Select Skill"
                                  : selectedskillfilter}
                              </span>
                            </div>
                          </button>
                          <ul
                            className="dropdown-menu bg-dark"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item btn_uni m-2"
                                onClick={() =>
                                  filterSkillAndCountryFunc(
                                    0,
                                    selectedcountryfilter
                                  )
                                }
                              >
                                <span>
                                  <GiCricketBat />
                                </span>
                                <span className="ms-2">All</span>
                              </a>
                            </li>
                            {displayakills.map((item, index) => {
                              return (
                                <li>
                                  <a
                                    className="dropdown-item btn_uni m-2"
                                    onClick={() =>
                                      filterSkillAndCountryFunc(
                                        item.name,
                                        selectedcountryfilter
                                      )
                                    }
                                  >
                                    <span>
                                      <img src={item.logo} alt={item.logo} />
                                    </span>
                                    <span className="ms-2">{item.name}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-4 text-end mb-lg-3 mb-md-3 px-2">
                      <div className="d-flex justify-content-md-end">
                        <div className="dropdown  w-100">
                          <button
                            className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div className="d-flex justify-content-end align-items-center">
                              <AiOutlineBars />
                              <span className="ms-1">
                                {selectedcountryfilter == ""
                                  ? "Select Country"
                                  : selectedcountryfilter}
                              </span>
                            </div>
                          </button>
                          <ul
                            className="dropdown-menu bg-dark"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item btn_uni m-2"
                                onClick={() =>
                                  filterSkillAndCountryFunc(selectedskillfilter, 0)
                                }
                              >
                                <span>
                                  {/* <img src="" className="img-fluid fltr_imgs" alt="" /> */}
                                </span>
                                <span className="ms-2">All</span>
                              </a>
                            </li>
                            {displaycountries.map((item, index) => {
                              return (
                                <li>
                                  <a
                                    className="dropdown-item btn_uni m-2"
                                    onClick={() =>
                                      filterSkillAndCountryFunc(
                                        selectedskillfilter,
                                        item.name
                                      )
                                    }
                                  >
                                    <span>
                                      <img src={item?.flag_logo} className="img-fluid fltr_imgs" alt="" />
                                    </span>
                                    <span className="ms-2">{item.name}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-3 text-end mt-sm-3 mt-md-0 mb-lg-0 px-2">
                      <div className="d-flex justify-content-md-end">
                        <div className="dropdown  w-100">
                          <button
                            className="btn mt-2 mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div className="d-flex justify-content-end align-items-center">
                              <AiOutlineBars />
                              <span className="ms-1">
                                {!selectedsort ? "Sort by" : selectedsort}
                              </span>
                            </div>
                          </button>
                          <ul
                            className="dropdown-menu bg-dark"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item btn_uni m-2 text-capitalize"
                                onClick={() => sortinginputhandler(1)}
                              >
                                <span className="ms-2 text-light">
                                  Price : Low to High
                                </span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item btn_uni m-2 text-capitalize"
                                onClick={() => sortinginputhandler(2)}
                              >
                                <span className="ms-2">Price : High to Low</span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item btn_uni m-2 text-capitalize"
                                onClick={() => sortinginputhandler(3)}
                              >
                                {/* <span><IoBowlingBaallOutline /></sspan> */}
                                <span className="ms-2">Age : Low to High </span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item btn_uni m-2 text-capitalize"
                                onClick={() => sortinginputhandler(4)}
                              >
                                {/* <span><MdSportsCricket /></span> */}
                                <span className="ms-2">Age : High to Low</span>
                              </a>
                            </li>

                            <li>
                              <a
                                className="dropdown-item btn_uni m-2 text-capitalize"
                                onClick={() => sortinginputhandler(5)}
                              >
                                {/* <span><IoBowlingBallOutline /></span> */}
                                <span className="ms-2">Rating : Low to High</span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item btn_uni m-2 text-capitalize"
                                onClick={() => sortinginputhandler(6)}
                              >
                                {/* <span><MdSportsCricket /></span> */}
                                <span className="ms-2">Rating : High to Low</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-2 text-end text-lg-end mt-xl-0 mt-lg-0 mt-md-0 d-flex align-items-md-start align-items-end px-2">
                      <button
                        className="btn_uni w-100 justify-content-center"
                        onClick={() => handlereset()}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="mt-3 mt-lg-0">
            <p className="m-0">Total NFTs {totaldisplayNfts}</p>
          </div>
        </div>
        {loader == true ? (
          <div className="d-flex justify-content-center">
            <CustomLoader />
          </div>
        ) : data.length > 0
          ?
          (
            data.map((item, index) => {
              return (
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 mt-3">
                  <div className="position-relative">
                    <div className="text-center">
                      <div className="fs-6 t-price">
                        {/* {item?.bonus_nft && (
                        <small className="position-absolute bonus_tag">
                          BONUS
                        </small>
                      )} */}

                        <small className="position-absolute bonus_tag">
                          {item?.category}
                        </small>
                        <span>${item.price}</span>
                      </div>
                      {/* <div className="fs-6 t-price">${item.price}</div> */}
                      <img src={item.image} alt="" className="cricketcard" onError={(e) => {
                        e.target.src =
                          "https://web3sports.s3.ap-south-1.amazonaws.com/Players/dummy.png";
                      }} />
                      <div className="cardDetail">
                        <div className="cardInnerDetail">
                          <small className="text-primary fw-medium">
                            {/* {item.static_rating}/100 */}
                            {Math?.trunc(parseInt(item?.dynamic_rating) + parseInt(item?.static_rating))}/100
                          </small>
                          <h5 className="mb-0" data-bs-toggle="tooltip" data-bs-placement="top"
                            title={item.name?.length < '15' ? "" : item.name}>
                            {item.name?.length > '15' ? item.name?.slice(0, 14) + ".." : item.name}
                          </h5>
                          <small className="text-primary fw-medium">
                            {item.skill}
                          </small>
                        </div>
                        <div className="d-flex justify-content-between">
                          <small className="text-uppercase">Age {item.age}</small>
                          <small className="text-uppercase">
                            <span className="nft_flag_img">
                              <img src={item?.flag_logo} className="img-fluid" />
                              <span>{item.country}</span>
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="counter">
                    {/* <button className="minus">-</button> */}
                    <button>{item.player_count}</button>
                    {/* <button className="plus">+</button> */}
                  </div>
                </div>
              );
            })
          )
          :
          (
            <div className="h-100 mt-5">
              <div className="h-100 mt-5">
                <div className="h-75">
                  <div className="text-center">
                    <img src="ds/nodatapreview.png" alt="" className="img-fluid" style={{ width: '200px' }} />
                  </div>
                  <h4 className="mt-3 text-center">Sorry, no results found!</h4>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <button
        className="btn_fix text-end btn_uni check_out_btn d-flex align-items-center"
        onClick={() => checkoutfunc()}
      >
        {/* <button
          className="btn_uni text-center check_out_btn text-center"
          onClick={() => checkout()}
          variant="primary"
        > */}
        <span className="fs-5 d-flex align-items-center me-1"><MdShoppingCartCheckout /></span>
        <span>Checkout</span>
        {/* </button> */}
      </button>

      {/* <div className="btn_fix">
        <button
          className="btn_uni"
          onClick={() => checkoutfunc()}
          variant="primary"
        >
          Checkout
        </button>
      </div> */}
    </>
  );
};

export default AutoPickNft;
