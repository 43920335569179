import React from "react";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";

export default function Contact() {
    return (
        <>
            <Header />
            <section className="py-5 contact">
                <div className="container">
                    <div>
                        <h2>Contact</h2>

                        <p>Thank you for your interest in SportsMint! If you have any questions about our privacy practices, our services, or anything else related to SportsMint, please feel free to reach out to us using the contact details below:</p>

                        <a href="Email:info@sportsmint.io">Email: info@sportsmint.io</a>

                        <div className="mt-4">
                            <h6>Address:</h6>

                            <ul className="text-white">
                                <li>Office No. 601, 602,</li>
                                <li>6th Floor,</li>
                                <li>Princes Business Skyline,</li>
                                <li>Block No. 32 & 33, PU-3,</li>
                                <li>Commercial Scheme No. 54, AB Road,</li>
                                <li>Indore, MP, 452010, India</li>
                            </ul>
                        </div>

                        <p>We're here to assist you and address any inquiries you may have. Your feedback and questions are important to us, and we aim to respond promptly to all inquiries.</p>

                        <p>Thank you for choosing SportsMint!</p>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}