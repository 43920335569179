import React from "react";
import Header from "../../layout/header/Header";
import OurPackages from "./OurPackages";

export default function OurClub() {
    return (
        <>
            <Header />
            <div className="container">
                <OurPackages />
            </div>
        </>
    )
}
