module.exports = {
    
    mntContract:'0xfEF391d898Cd6526685d4FA48337255153135a31',
    projectId: 'f5977011bdf8f1a31a1ea68aa2d4a606',
    depositContractAdress:
    "0xf82b6dad2d7fdc6e8e5fa1d8f12be977262e8138",
    NETWORK:"BSC Testnet",
    CHAIN:"97",
    adminAddress:"0x575fBccf7966347C1e299921979c707Dd5cd5941",
    explorerurl:"https://testnet.bscscan.com/tx/",
}