import React, { useEffect, useState } from "react";
import SidebarPro from "./sidebar/Sidebar";
import DashboardHeader from "./header/Header";
import '../../../src/assets/style/Sidebar.css'
import MyReferral from "./MyReferral";
import DataTable from "react-data-table-component";
import Home from "./Home";
import * as ACTIONTYPES from "../../redux/actionTypes";

import '../../../src/assets/style/Dashboard.css'
import ProfileUpdation from "../forms/ProfileUpdation";
import Nfts from "../nfts/Nfts";
import MyClub from "./MyClub";
import { BrowserRouter, Route, Router, Routes, useNavigate } from "react-router-dom";
import TotalNfts from "../nfts/TotalNfts";
import { useDisconnect, useAccount, useBalance, useSignMessage } from "wagmi";
import { readContract } from '@wagmi/core'
import Web3 from "web3";
import { bscTestnet } from 'wagmi/chains';
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import config from "../../config/config";

import {
    Web3Modal,
    Web3Button,
    useWeb3Modal,
    Web3ModalProps,
} from "@web3modal/react";
import {
    configureChains,
    Connector,
    useWalletClient,
    WagmiConfig,
    useConnect,
    useChainId,
    useNetwork,
    useConfig
} from "wagmi";

import { FaBarsStaggered } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


export default function Dashboard(props) {
    const { address, isConnecting, isDisconnected } = useAccount()
    const [isMobile, setIsMobile] = useState(false);

    const { collapsed, setCollapsed } = props

    const loginData = useSelector((state) => state.auth.LoginDetails);// (!Cookies.get('loginSuccessWeb3Sports')) ? [] : JSON.parse(Cookies.get('loginSuccessWeb3Sports'));
    const _useDisconnect = useDisconnect();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (isDisconnected) {
    //         dispatch({ type: ACTIONTYPES.walletConnnected, payload: "" });
    //         dispatch({ type: ACTIONTYPES.USERLOGIN, payload: "", token: "" });
    //         navigate(`${config.baseUrl}`);
    //     }
    //     console.log(isDisconnected);
    // }, [isDisconnected])

    useEffect(() => {
        console.log(window.innerWidth);
        if (window.innerWidth <= 768){
            setIsMobile(true);
            console.log('enableMobile');
        }
    }, [window.innerWidth])
   

    return (
        <>
            <div className="dashboard" id="dashboard">
                <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
                
                <div id="outlet"   className={(collapsed ? "marginL25" : "marginL250")}>
                    <DashboardHeader className={(collapsed ? "marginL25" : "marginL250")} collapsed={collapsed} setCollapsed={setCollapsed} />
                    <div className="container" >
                        <Home />
                    </div>
                </div>
            </div>
        </>
    )
}