import React, { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "../../../src/assets/style/nftdetail.css"
import Header from "../layout/header/Header";


export default function NftDetails() {
    // const [icon, setIcon] = useState("MNT");

    // const hadleSubmit = (e) => {
    //     e.preventDefault()
    //     alert(icon)
    // }
    return (
        <>
        <Header/>
            <div className="nfts_details">

                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="our_packages up">

                            <div className="row align-items-center">
                                <div className="col-lg-3 col-md-4 pl-0">
                                   
                                        {/* <img src="NFT.png" alt="" /> */}

                                        <div className="">
                                            <div className="cric-card position-relative">
                                                <img src="card/cric-card.png" />
                                            </div>
                                            {/* <img src="NFT.png" alt="" /> */}
                                        </div>

                                   
                                </div>
                                <div className="col-lg-9 col-md-8 mt-4 mt-lg-0">
                                    <div className="text-end mb-3">
                                        <button className="me-2 btn custombtn text-white text-uppercase">Basic 2024</button>
                                        <button className="me-2 btn custombtn text-white text-uppercase">R546TH76</button>
                                    </div>
                                    <h3 className="text-start">Virat Kohli</h3>

                                    <div className="my-3">
                                        <div className="d-flex ">
                                            <div className="">
                                                <span><img src="india.png" width={`30px`} className="flagIcon" />&nbsp;India</span>
                                            </div>
                                            {/* <div className="col-md-auto mt-3 mt-md-0 p-md-0">
                                            <span className="dtl">Batter</span>
                                        </div>
                                        <div className="col-md-auto mt-3 mt-md-0">
                                            <span className="dtl">
                                                72/100
                                            </span>
                                        </div>
                                        <div className="col-md-auto mt-3 mt-md-0">
                                            <span className="dtl">Age 35</span>
                                        </div>
                                        <div className="col-md-auto mt-3 mt-md-0">
                                            <span className="dtl">Nationally India</span>
                                        </div> */}
                                        </div>
                                    </div>

                                    {/* <form onSubmit={hadleSubmit}> */}
                                    <div className="my-3">
                                        <div className="d-flex align-items-center mx-0">
                                            <div className="col-4 ps-0">
                                                {/* <h6 className="text-info fw-light">Type</h6> */}
                                                <h5 className="fs-5 mb-0">72/100</h5>
                                            </div>
                                            <div className="col-4">
                                                {/* <h6 className="text-info fw-light">Current Price</h6> */}
                                                <h3 className="mb-0">$600</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nftdetail mt-5">
                                        <ul className="list-unstyled pl-0 mb-0">
                                            <li>
                                                <div>
                                                    <small className="text-grey">Date Of Birth:</small>
                                                    <h5 className="fs-5">05/11/1988</h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <small className="text-grey">Role:</small>
                                                    <h5 className="fs-5">Batsman</h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <small className="text-grey">Batting Style:</small>
                                                    <h5 className="fs-5">Right Handed</h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <small className="text-grey">Bowling Style:</small>
                                                    <h5 className="fs-5">Right Arm Medium</h5>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>

                        </div>


                        {/* <div className="description">
                        <div>
                            <h5>About Package :</h5>
                            <p>Unlock a spectrum of fantasy sports excitement with our tailored subscription packages. Whether you're a Rookie Roster, aiming for the Pro Playbook, or seeking the All-Star Advantage, we have a plan to match every level of play. Dive into strategic drafting, varied league access, and exclusive rewards as you ascend the ranks. Choose your fantasy experience today and elevate your gaming journey with Sports Mint Fantasy.</p>
                        </div>

                        <div>
                            <h5>Details :</h5>
                            <p>Unlock a spectrum of fantasy sports excitement with our tailored subscription packages. Whether you're a Rookie Roster, aiming for the Pro Playbook, or seeking the All-Star Advantage, we have a plan to match every level of play. Dive into strategic drafting, varied league access, and exclusive rewards as you ascend the ranks. Choose your fantasy experience today and elevate your gaming journey with Sports Mint Fantasy.</p>
                        </div>

                        <div>
                            <h5>Description :</h5>
                            <p>Unlock a spectrum of fantasy sports excitement with our tailored subscription packages. Whether you're a Rookie Roster, aiming for the Pro Playbook, or seeking the All-Star Advantage, we have a plan to match every level of play. Dive into strategic drafting, varied league access, and exclusive rewards as you ascend the ranks. Choose your fantasy experience today and elevate your gaming journey with Sports Mint Fantasy.</p>
                        </div>
                    </div> */}
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}