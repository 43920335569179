import { useState } from "react";
import Web3 from "web3";
import web3Config from "./web3Config";
import Erc20Abi from "./Erc20Abi.json";
import depositAbi from "./depositAbi.json";
import config from "../../config/config";
// import BigNumber from 'bignumber.js';
const convert = require("ether-converter");

const MNTContractAddress = config.MNTContractAddress;
const USDTContractAddress = config.USDTContractAddress;
const MNTABI = config.MNTABI;
const USDTABI = config.USDTABI;
const SportsMintContractAddress = config.SportsMintContractAddress;
const SportsMintContractABI = config.SportsMintContractABI;

////New Runing Code
const validateProvider = async (walletProvider) => {
  if (!window.ethereum) {
    return {
      status: false,
      message: `Please connect your web3 wallet`,
    }
  }

  let accounts123 = await window.ethereum.request({
    method: "eth_requestAccounts",
  })
  walletProvider = await window.ethereum // walletProvider;


  const web3 = new Web3(walletProvider)

  // const accounts = await web3.eth.getAccounts()
  const currentProvider = await web3.currentProvider;
  let chainId = await web3.currentProvider.chainId;
  if (!chainId) {
    chainId = web3.currentProvider.getChainId();
  }

  // let ccc = await currentProvider.getChainId()
  console.log({ currentProvider, chainId })

  if (web3.utils.toHex(parseInt(chainId)) != parseInt(config.chainId) && parseInt(config.chainId) != parseInt(chainId)) {
    return {
      status: false,
      message: `Please select ${config.NETWORK} network`,
    }
  }
  return {
    status: true,
    web3,
  };
};

export const getChainId = async (walletProvider) => {
  const validate = await validateProvider(walletProvider);

  if (!validate.status) {
    return validate;
  }
  let web3 = validate.web3;

  let ccc = await web3.eth.getChainId();

  return parseInt(ccc);
};

export const getAddressValidation = async (walletAddress, walletProvider) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    let isAddress = await web3.utils.isAddress(walletAddress);
    return isAddress;
  } catch (error) {
    console.error(error);
  }
};

export const purchasePlanMNT = async (
  walletAddress,
  walletProvider,
  MNTContractAddress,
  transactionObject
) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;

    let bnbBalance = await web3.eth.getBalance(walletAddress);

    if (bnbBalance <= 0) {
      return { status: false, message: "Insufficient BNB balance" };
    }

    let mntContract = new web3.eth.Contract(MNTABI, MNTContractAddress);

    let sportsContract = new web3.eth.Contract(
      SportsMintContractABI,
      SportsMintContractAddress
    );

    let balance = await mntContract.methods.balanceOf(walletAddress).call();
    const balanceFormatted = web3.utils.fromWei(balance.toString(), "ether");

    if (parseInt(balanceFormatted) <= transactionObject.amount_pending) {
      return { status: false, message: "Insufficient MNT balance" };
    }

    let decimals = await mntContract.methods.decimals().call();

    let allowance = await mntContract.methods
      .allowance(walletAddress, SportsMintContractAddress)
      .call();
    const allowanceFormatted = web3.utils.fromWei(
      allowance.toString(),
      "ether"
    );

    if (
      parseFloat(allowanceFormatted) <
      parseFloat(transactionObject.amount_pending) + 0.1
    ) {
      let amount =
        (parseFloat(transactionObject.amount_pending) + 1) *
        10 ** parseInt(decimals).toLocaleString("fullwide", { useGrouping: false });
      let approvalResult = await mntContract.methods.approve(
        SportsMintContractAddress,
        amount
      );

      let encoded_tx = approvalResult.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();

      const _feeCheckRes = await feeCalculate(gasPrice, 48000, bnbBalance);
      if (!_feeCheckRes.status) {
        return _feeCheckRes;
      }

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: MNTContractAddress,
        from: walletAddress,
        data: encoded_tx,
      });

      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: MNTContractAddress,
        from: walletAddress,
        data: encoded_tx,
      });

      if (!trx.transactionHash) {
        return { status: false, message: "Approval failed." };
      }
    }

    let allowance1 = await mntContract.methods
      .allowance(walletAddress, SportsMintContractAddress)
      .call();
    const allowanceFormatted1 = web3.utils.fromWei(
      allowance1.toString(),
      "ether"
    );

    if (
      parseFloat(allowanceFormatted1) <
      parseFloat(transactionObject.amount_pending)
    ) {
      return { status: false, message: "Insufficient allowance." };
    }

    let activatePlanResult = await activatePlanMNT(
      web3,
      transactionObject,
      walletAddress,
      sportsContract
    );

    if (activatePlanResult.status) {
      return {
        status: true,
        message: `Transaction Successful`,
      };
    }
    return activatePlanResult;
  } catch (error) {
    console.error(error, "asdf");
    return {
      status: false,
      code: error.code,
      message: "Pocessing transaction: " + (!error.message ? 'User rejected the transaction' : error.message),
    };
  }
};

export const purchasePlanUSDT = async (
  walletAddress,
  walletProvider,
  USDTContractAddress,
  transactionObject
) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    let bnbBalance = await web3.eth.getBalance(walletAddress);

    let usdtContract = new web3.eth.Contract(USDTABI, USDTContractAddress);

    let sportsContract = new web3.eth.Contract(
      SportsMintContractABI,
      SportsMintContractAddress
    );

    let balance = await usdtContract.methods.balanceOf(walletAddress).call();
    const balanceFormatted = web3.utils.fromWei(balance.toString(), "mwei"); // Adjust decimals for USDT
    let decimals = await usdtContract.methods.decimals().call();

    let allowance = await usdtContract.methods
      .allowance(walletAddress, SportsMintContractAddress)
      .call();
    const allowanceFormatted = web3.utils.fromWei(
      allowance.toString(),
      "ether"
    );

    if (
      parseFloat(allowanceFormatted) <
      parseFloat(transactionObject.amount_pending)
    ) {
      let amount =
        (parseFloat(transactionObject.amount_pending)) *
        10 ** parseInt(decimals).toLocaleString("fullwide", { useGrouping: false });
      let approvalResult = await usdtContract.methods.approve(
        SportsMintContractAddress,
        amount
      );

      let encoded_tx = approvalResult.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();

      const _feeCheckRes = await feeCalculate(gasPrice, 48000, bnbBalance);
      if (!_feeCheckRes.status) {
        return _feeCheckRes;
      }


      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: USDTContractAddress,
        from: walletAddress,
        data: encoded_tx,
      });


      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: USDTContractAddress,
        from: walletAddress,
        data: encoded_tx,
      });

      if (!trx.transactionHash) {
        return { status: false, message: "Approval failed." };
      }
    }

    if (bnbBalance <= 0) {
      return { status: false, message: "Insufficient BNB balance" };
    }

    if (balanceFormatted <= 0) {
      return { status: false, message: "Insufficient USDT balance" };
    }

    if (
      parseInt(balanceFormatted) <= parseInt(transactionObject.amount_pending)
    ) {
      return { status: false, message: "Insufficient USDT balance" };
    }
    let allowance1 = await usdtContract.methods
      .allowance(walletAddress, SportsMintContractAddress)
      .call();
    const allowanceFormatted1 = web3.utils.fromWei(
      allowance1.toString(),
      "ether"
    );

    if (
      parseFloat(allowanceFormatted1) <
      parseFloat(transactionObject.amount_pending)
    ) {
      return { status: false, message: "Insufficient allowance." };
    }

    let activatePlanResult = await activatePlanUSDT(
      web3,
      transactionObject,
      walletAddress,
      sportsContract
    );

    if (activatePlanResult.status) {
      return {
        status: true,
        message: `Transaction Successful`,
      };
    }
    return activatePlanResult;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      code: error.code,
      message: "Processing transaction: " + (!error.message ? 'User rejected the transaction' : error.message),
    };
  }
};

const activatePlanMNT = async (
  web3,
  transactionObject,
  walletAddress,
  sportsContract
) => {
  try {


    let bnbBalance = await web3.eth.getBalance(walletAddress);

    const result = convert(transactionObject.amount_pending, "ether");

    let finalAmount = JSON.stringify(result.wei, null, 2);

    let pendingAmount = JSON.parse(finalAmount);

    const result1 = convert(transactionObject.digital_amount, "ether");

    let finalAmount1 = JSON.stringify(result1.wei, null, 2);

    let VirtualAmount = JSON.parse(finalAmount1);

    let activePlan = await sportsContract.methods.purchasePlan(
      transactionObject.package_id,
      pendingAmount,
      VirtualAmount,
      transactionObject.activation_key,
      MNTContractAddress
    );
    let encoded_tx = activePlan.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();


    const _feeCheckRes = await feeCalculate(gasPrice, 81000, bnbBalance);
    if (!_feeCheckRes.status) {
      return _feeCheckRes;
    }

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: SportsMintContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: SportsMintContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
      return {
        status: true,
        msg:
          trx.transactionHash.slice(0, 5) +
          "..." +
          trx.transactionHash.slice(-5),
      };
    }
    return { status: false, message: "Processing transaction: " };
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: "Processing transaction: " + (!error.message ? 'User rejected the transaction' : error.message),
    };
  }
};

const activatePlanUSDT = async (
  web3,
  transactionObject,
  walletAddress,
  sportsContract
) => {
  try {
    let bnbBalance = await web3.eth.getBalance(walletAddress);

    const result = convert(transactionObject.amount_pending, "ether");

    let finalAmount = JSON.stringify(result.wei, null, 2);

    let pendingAmount = JSON.parse(finalAmount);

    const result1 = convert(transactionObject.digital_amount, "ether");

    let finalAmount1 = JSON.stringify(result1.wei, null, 2);

    let VirtualAmount = JSON.parse(finalAmount1);

    let activePlan = await sportsContract.methods.purchasePlan(
      transactionObject.package_id,
      pendingAmount,
      VirtualAmount,
      transactionObject.activation_key,
      USDTContractAddress
    );
    let encoded_tx = activePlan.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();


    const _feeCheckRes = await feeCalculate(gasPrice, 81000, bnbBalance);
    if (!_feeCheckRes.status) {
      return _feeCheckRes;
    }


    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: SportsMintContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: SportsMintContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
      return {
        status: true,
        msg:
          trx.transactionHash.slice(0, 5) +
          "..." +
          trx.transactionHash.slice(-5),
      };
    }

    return { status: false, message: "Processing transaction: " };
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: "Processing transaction: " + (!error.message ? 'User rejected the transaction' : error.message),
    };
  }
};

export const createSignatureBlockchain = async (
  walletAddress,
  walletProvider,
  sigMsg
) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    const getAccounts = await web3.eth.getAccounts();
    console.log({ getAccounts, walletAddress });
    const signature = await web3.eth.personal.sign(
      web3.utils.fromUtf8(sigMsg),
      walletAddress,
      sigMsg
    );

    return { status: true, signature: signature };
  } catch (error) {
    console.error({ error123: error });
    return {
      status: false,
      code: error.code,
      message: "Processing transaction: " + (!error.message ? 'User rejected the transaction' : error.message),
    };
  }
};



export const getTokenBalanceBlockchain = async (walletAddress, tokenContract, walletProvider) => {
  try {
    const validate = await validateProvider(walletProvider);
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;


    let usdtContract = new web3.eth.Contract(Erc20Abi, tokenContract);

    let balance = await usdtContract.methods.balanceOf(walletAddress).call();

    let decimals = await usdtContract.methods.decimals().call();

    return {
      status: true,
      balance: parseInt(balance) / 10 ** parseInt(decimals)
    }
  } catch (error) {
    console.error(error);
  }
};

export const getBNBBalanceBlockchain = async (walletAddress, walletProvider) => {
  try {
    const validate = await validateProvider(walletProvider);
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let balance = await web3.eth.getBalance(walletAddress);

    return {
      status: true,
      balance: parseInt(balance) / 10 ** parseInt(18)
    }
  } catch (error) {
    console.error(error);
  }


};

function feeCalculate(gasPrice, gasLimit, bnbBalance) {
  let approvalFee = parseInt(gasPrice) * 3.1 * parseInt(gasLimit) / 10 ** 18;
  if (approvalFee > parseInt(bnbBalance) / 10 ** 18) {
    return { status: false, message: "Insufficient BNB balance for gas fees" };
  } else {
    return { status: true }
  }
}